import { Injectable } from '@angular/core';
import { Constants } from '@entrayn/core/services/config';
import { timestamp } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Config {
  drupalHome = this._constants.DRUPAL_URL.HOME;
  internalUserHome = '/internal/home';
  studentProfileForm = environment.AC_PROFILE_FORM + '?emailAddress=';
  apiEndpoints = {
    allUniversities: this._constants.SITE_URL + '/api/universities?limit=1000',
    universities: this._constants.SITE_URL + '/api/users/',
    users: this._constants.SITE_URL + '/api/users?product=AC&limit=50',
    userProfile: this._constants.SITE_URL + '/api/users/',
    documentation: '/main-documents',
    documentInteractionCreate: this._constants.SITE_URL + '/api/document-interactions',
    getDocumentInteractions: (uuid, type) => {
      let url = this.apiEndpoints.documentInteractionCreate + '?interaction_type=' + type;
      if (type === 'MD') {
        url = url + '&main_document=' + uuid;
      } else if (type === 'AD') {
        url = url + '&application_document=' + uuid;
      }
      return url + '&limit=200';
    },
    servicingUniversities: '/serviced-applications',
    visaInformation: this._constants.SITE_URL + '/api/visa-information',
    onBoardingInformation: this._constants.SITE_URL + '/api/on-boarding',
    documentationTA: uuid =>
      this._constants.SITE_URL + '/api' + this.apiEndpoints.documentation + '/' + uuid,
    applicationDocuments: (userUuid, applicationId) =>
      this.apiEndpoints.userProfile +
      userUuid +
      '/applications/' +
      applicationId +
      '/application-documents',
    documentationUpdate: uuid =>
      this._constants.SITE_URL +
      '/api' +
      this.apiEndpoints.documentation +
      '/' +
      uuid +
      '/update-status',
    applicationDocumentsUpdate: (userUuid, applicationId, documentId) =>
      this.apiEndpoints.userProfile +
      userUuid +
      '/applications/' +
      applicationId +
      '/application-documents' +
      '/' +
      documentId +
      '/update-status',
    applicationSpecificDocumentUpdate: (userUuid, applicationId, documentId) =>
      this.apiEndpoints.applicationDocuments(userUuid, applicationId) + '/' + documentId,
    sessions: '/sessions',
    shortlistedUniversitiesPatch: this._constants.SITE_URL + '/api/applications/',
    shortlistedUniversitiesGet: '/applications-dashboard-view',
    notes: '/notes',
    applications: (userUuid, applicationId) =>
      this.apiEndpoints.userProfile + userUuid + '/applications/' + applicationId,
    currentUser: this._constants.SITE_URL + '/api/users/current',
    overviewTab: '/materials?limit=1000',
    noPagination: '?limit=1000',
    allowedActions: this._constants.SITE_URL + '/api/user-subscriptions?product_root=AC',
    editOptions: this._constants.SITE_URL + '/api/config',
    faq: this._constants.SITE_URL + '/api/faq?limit=1000',
  };
  regExPatterns = {
    url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/,
    linkedinUrl: /https?:\/\/(www\.)?[linkedin\/in\/]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/,
    whitespace: /^[^\s]+(\s+[^\s]+)*$/,
    phoneNumber: /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/,
    date: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
    nonDecimalInteger: /^\d*$/,
  };

  finalize = ['I will apply', 'I will NOT apply'];

  universityTableTextContent = {
    someorNOUniversityFinalized:
      'please click on each shorlisted university and give us your comments',
    allUniversityDocFinalized:
      'please update your application status here and feel free to use this as your one-stop Applications Dashboard. All the best !!',
    someOrNoUniversityDocFinalized:
      'your university specific documents for {{X}} out of {{Y}} universities are incomplete. Please complete the documents to proceed with the application process.',
    noServicingUniversitiesFound:
      'please convey {{here}} the applications for which you need Galvanize’s Documentation servicing (based on your package - {{N}} universities). Only after you finalize {{N}} universities, we can start working on your university-specific essays',
  };
  tooltipContent = {
    Category: 'Indicates your chances of getting an admit to the University.',
    '%': 'Indicates the % completion of your application for this University.',
    Finalize:
      'Indicate whether you decided to apply or not apply to each University in your shortlist',
    Status:
      'Indicate whether you Applied to the University and then update the result once you receive an admit/reject from the University.',
    feeInState:
      'Tuition in-state fees refers to the fees students who are residents of the same state as the University have to pay.',
    feeOutState:
      'Tuition out-state fees refers to the fees students who are not residents of the same state as the University have to pay.',
    feeInternational:
      'Tuition International is the fees charged to all new and continuing nonimmigrant international undergraduate, graduate, and non-degree students registered for classes at an University.',
    livingExpenses:
      'Living Expenses is the cost for accomodation, food/clothes, transport, leisure activities and other study-related costs a student will incur while studying at the University.',
    percentage: 'Indicates the % completion of your application for this University.',
    Priority:
      'If you apply before this deadline, you have a higher chance of getting admitted and/or receiving a scholarship.',
    Regular: 'You have to apply before this deadline for your application to be considered.',
  };
  notADocUserContent =
    ", your documentation process will begin once you complete the pending payment for our Admissions Counselling services. To obtain your custom payment link, please contact your sales representative or send an email to <a href='https://mail.google.com/mail/?view=cm&fs=1&to=sales@entrayn.com' target='_top'>sales@entrayn.com";
  notFinalizedContent =
    ', we can start the university-specific SOP process only after you have finalized the {{N}} universities whose applications Galvanize will be helping you with.';
  enrollmentStatusCriteria = ['AFT', 'AR', 'DT', 'UD'];
  enrollmentStatusCriteriaOptions = {
    AFT: 'Active-FirstTimer',
    PAFT: 'Partner-Active FirstTimer',
    AR: 'Active Repeat',
    DT: 'Dormant',
    UD: 'Undecided',
    SD: 'Serviced',
    RF: 'Refund',
    DD: 'Dropped',
    AOO: 'Applied on Own',
  };
  visaStatus = [
    'Booked',
    'Issued',
    'Not Booked',
    'Rejected',
    'Not Informed',
    'Administrative Check',
    'Awaiting Visa',
  ];
  fundingStatus = [
    'TA',
    'RA',
    'Tuition Waiver',
    'Partial Scholarship',
    'Full Scholarship',
    'Fellowship',
    'Other Scholarships',
    'No Scholarship',
  ];
  galvanizeACWebsite = 'https://galvanizetestprep.com/admission-counselling/';
  studentComments = [
    'Student agreed',
    'Did not like the research',
    'Cost too high',
    'Did not like the course offerings',
    'Extreme weather conditions',
    'Did not like the place',
    'Leads to better professional employment. Leads to better professional employment. Leads to better professional employment. ',
  ];
  galvanizeComments = [
    'Research-oriented degree program',
    'Leads to better professional employment ',
    'Designed to enhance your knowledge by providing a broad view',
    'Deep understanding of the subject ',
    'Good faculty and research ',
    'Good course offerings',
    'Encompasses latest research findings both applied and fundamental ',
    'Program offers a technical focus ',
    'Excellent Research and Faculty',
    'Consistent Ranking and Outstanding Research',
  ];
  /*
   isPositiveOutcome denotes whether the status is deadend (positive / negative), the key won't be available for the normal transition status.
  */
  status = [
    { level: 0, optionItem: 'Yet to apply', optionLevel: 0, isConfirmationRequired: false },
    { level: 0, optionItem: 'Applied', optionLevel: 1, isConfirmationRequired: true },
    {
      level: 0,
      optionItem: 'Decided not to Apply',
      optionLevel: 0,
      isConfirmationRequired: true,
      isPostiveOutCome: false,
    },
    { level: 1, optionItem: 'Interview', optionLevel: 1, isConfirmationRequired: false },
    { level: 1, optionItem: 'Waitlisted', optionLevel: 1, isConfirmationRequired: false },
    { level: 1, optionItem: 'Got Admit', optionLevel: 2, isConfirmationRequired: true },
    {
      level: 1,
      optionItem: 'Got Reject',
      optionLevel: 1,
      isConfirmationRequired: true,
      isPostiveOutCome: false,
    },
    { level: 2, optionItem: 'Accepted the offer', optionLevel: 3, isConfirmationRequired: true },
    {
      level: 2,
      optionItem: 'Deferred the offer',
      optionLevel: 2,
      isConfirmationRequired: true,
      isPostiveOutCome: false,
    },
    {
      level: 2,
      optionItem: 'Declined the offer',
      optionLevel: 2,
      isConfirmationRequired: true,
      isPostiveOutCome: false,
    },
    {
      level: 3,
      optionItem: 'Joined',
      optionLevel: 3,
      isConfirmationRequired: true,
      isPostiveOutCome: true,
    },
    {
      level: 3,
      optionItem: 'Not Joined',
      optionLevel: 3,
      isConfirmationRequired: true,
      isPostiveOutCome: false,
    },
  ];

  statusPopoverContent = {
    Applied: {
      content: 'Are you sure you have applied to <university>?',
      successButtonText: "Yes, I've Applied",
    },
    AppliedWhenNotFinalizingDocuments: {
      content:
        "Dear <FName>, You haven't completed your documentation for this university. Once you update the status of all your documents, you will be able to change the status to Applied.",
      successButtonText: 'Let me check',
    },
    'Decided not to Apply': {
      content: 'Please share your reason for not applying to <university>',
      successButtonText: 'Submit',
      successfullResponseContent: {
        imageURL: '/assets/static/images/Thumbs up.png',
        content: 'We appreciate your feedback',
      },
    },
    'Got Admit': {
      content: 'Are you sure you have got an admit from <university>?',
      successButtonText: "Yes, I've got an Admit",
    },
    'Got Reject': {
      content: 'Are you sure your application got rejected?',
      successButtonText: "Yes, I've got rejected",
    },
    'Deferred the offer': {
      content: 'Have you deferred the offer?',
      successButtonText: "Yes, I've deferred",
    },
    'Declined the offer': {
      content: 'Have you declined the offer?',
      successButtonText: "Yes, I've declined",
    },
    'Accepted the offer': {
      content: 'Are you sure you have accepted <university>?',
      successButtonText: "Yes, I've accepted.",
    },
    Joined: {
      content: 'Are you sure you have joined <university>?',
      successButtonText: "Yes, I've joined",
    },
    'Not Joined': {
      content: 'Are you sure you have not joined <university>',
      successButtonText: "Yes, I've not joined",
    },
  };

  gyanMaterialsSequence = [
    'Introduction',
    'Documentation Guidelines',
    'University Shortlisting',
    'Visa',
    'Terms & Conditions',
  ];
  optionalDocuments = ['RS', 'DS', 'PS', 'FA', 'SE'];
  mainDocuments = ['RE', 'SOP', 'LOR', 'TA'];
  documentTypes = {
    RE: 'Resume',
    SOP: 'Statement of Purpose',
    LOR: 'Letter of Recommendation',
    DS: 'Diversity Statement',
    PS: 'Personal Statement',
    RS: 'Research Statement',
    FA: 'Financial Affidivit',
    TA: 'Transcripts',
    SE: 'Scholarship Essay',
  };

  universityDocumentTypes = {
    SOP: 'Statement of Purpose',
    RS: 'Research Statement',
    DS: 'Diversity Statement',
    PS: 'Personal Statement',
    FA: 'Financial Affidivit',
    SE: 'Scholarship Essay',
  };

  documentStatus = {
    initialInput: {
      status: 'Student yet to send initial inputs',
      content: 'you’re yet to send initial inputs',
      dependency: 'learner',
    },
    editorUnassigned: {
      status: 'Editor Unassigned but student submitted initial inputs',
      content: 'Galvanize is working on it',
      dependency: 'editor',
    },
    editorWorking: {
      status: 'Editor is working on it',
      content: 'Galvanize is working on it',
      dependency: 'editor',
    },
    editorReviewing: {
      status: 'Editor is reviewing student inputs',
      content: 'Galvanize is reviewing your feedback',
      dependency: 'editor',
    },
    awaitingFeedback: {
      status: 'Awaiting inputs from student',
      content: 'Awaiting feedback from',
      dependency: 'learner',
    },
    waitingEditorsFinalReview: {
      status: 'Waiting for Editor’s final review',
      content: 'Waiting for Editor’s final review',
      dependency: 'editor',
    },
    awaitingFinalInputs: {
      status: 'Awaiting final inputs from the student',
      content: 'Awaiting final inputs from',
      dependency: 'learner',
    },
    finaldocument: {
      status: 'Finalized by student and galvanize',
      content: 'Finalized by Galvanize and',
      dependency: 'learner',
    },
    notRequired: {
      status: 'Not Required',
      content: 'Document is not required',
      dependency: null,
    },
  };

  noteSeparator = '<br />';
  applicationStatusOptions = [
    'Not Uploaded',
    'Uploaded',
    'Not Couriered',
    'Couriered',
    'Not Required',
  ];
  scoreReportStatusOptions = [
    {
      shortName: 'Sent',
      name: 'Score Report Sent',
    },
    {
      shortName: 'Not Sent',
      name: 'Score Report Not Sent',
    },
  ];
  timeZoneConverterLink = 'https://www.thetimezoneconverter.com/';
  dropDownOptions = {
    intake: [
      'Spring 2014',
      'Fall 2014',
      'Spring 2015',
      'Fall 2015',
      'Spring 2016',
      'Fall 2016',
      'Spring 2017',
      'Fall 2017',
      'Spring 2018',
      'Fall 2018',
      'Spring 2019',
      'Fall 2019',
      'Spring 2020',
      'Fall 2020',
      'Spring 2021',
      'Fall 2021',
      'Undecided',
    ],
    annual_budget: ['Up to 20K', '20K - 30K', '30K - 40K', '40K - 50K', '50K and above'],
    parameters: ['Subject', 'University Rank', 'Cost ', 'Location', 'Duration'],
    funds: [
      {
        optionItem: 'Your Income',
        optionValue: 'YI',
      },
      {
        optionItem: 'Family Funds',
        optionValue: 'FF',
      },
      {
        optionItem: 'Education Loan',
        optionValue: 'EL',
      },
      {
        optionItem: 'Combination of Above',
        optionValue: 'COA',
      },
    ],
    gender: [
      {
        optionItem: 'Male',
        optionValue: 'M',
      },
      {
        optionItem: 'Female',
        optionValue: 'F',
      },
      {
        optionItem: 'Other',
        optionValue: 'O',
      },
    ],
    retake_gre: ['Plan to retake GRE', 'No plans to retake GRE'],
    retake_toefl: ['Plan to retake TOEFL', 'No plans to retake TOEFL'],
    retake_gmat: ['Plan to retake GMAT', 'No plans to retake GMAT'],
    retake_ielts: ['Plan to retake IELTS', 'No plans to retake IELTS'],
    retake_pte: ['Plan to retake PTE', 'No plans to retake PTE'],
    year_of_graduation: [
      'Before 2000',
      '2000',
      '2001',
      '2002',
      '2003',
      '2004',
      '2005',
      '2006',
      '2007',
      '2008',
      '2009',
      '2010',
      '2011',
      '2012',
      '2013',
      '2014',
      '2015',
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      'After 2020',
    ],
    faq: [
      'online application form filling',
      'Student Profile',
      'Standardized Tests',
      'English Proficiency Tests',
    ],
  };

  transcriptOptions = {
    'Student yet to apply from college': 'I am yet to apply from my college',
    'Student has applied at their college': 'I have applied at my college',
    'Student has obtained from the college': 'I have obtained from my college',
  };

  documentsTransitionStatus = {
    awaitingFeedback: 'Awaiting Feedback',
    awaitingFinalFeedback: 'Awaiting Final Feedback',
  };

  // check for reason_for_applying field from university_application model
  applicationCommentsStatus = {
    "studentPreferedGalvanizerDidn'tAgree": "Student preference, Galvanize didn't agree",
    GalvanizeAgreedWithStudentPreference: 'Galvanize agreed with student preference',
    GalvanizeRecommendedAndStudentAgreed: 'Galvanize recommended and student agreed',
    StudentDisAgreedButAgreedLater: "Student didn't agree before but agreed later",
  };

  universityComments = {
    GalvanizeAgreedWithStudentPreference: (fName, univName, timestamp) => ({
      title: `${fName}, you proposed the<b>  ${univName} and, we agree </b> for the following reason:`,
      agreementText: `<span class="text-lg-green ">Galvanize agreed with this recommendation on ${timestamp}</span>`,
    }),
    studentPreferedGalvanizerNotAgreed: (fName, univName) => ({
      title: `${fName}
        , you proposed <b>
        ${univName}
        but we are not comfortable </b>with this choice for the following reason:`,
      popOverContent: {
        failurePopover: {
          buttonText: "Okay, I won't apply to this program",
          statusChange: 'Student decided NOT to apply based on Galvanize recommendation',
          content: {
            header: univName,
            content: `${fName}
              based on Galvanize's recommendation you have decided to not apply to
              ${univName}
              . Are you sure?`,
          },
          onSuccess: {
            header: univName,
            content: `Please note that you have decided not to apply to
              ${univName}
              as per our recommendation. `,
            showFooter: false,
            showCancelButton: false,
          },
        },
        successPopover: {
          buttonText: 'I understand, but still want to apply',
          statusChange: 'Student applied without Galvanize agreement',
          content: {
            header: univName,
            content: `Are you sure you want to apply to ${univName} against our recommendation?`,
          },
          onSuccess: {
            header: univName,
            content: `${fName}, please note that <i><b>${univName}</i></b> is not in our list of recommendations for you.
              <p class='text-danger font-weight-bold font-italic pt-3'>Galvanize will not be able to help you apply to
              ${univName}, but you are free to apply to it on your own.</p>`,
            successButtonText: 'okay',
            showFooter: false,
            showCancelButton: false,
          },
        },
      },
    }),
    StudentAppliedWithoutGalvanizeAgreement: (fName, univName, timestamp) => ({
      title: `${fName}
        , you proposed <b>
        ${univName}
        but we are not comfortable </b>with this choice for the following reason:`,
      agreementText: `<span class="text-danger ">${fName}, Galvanize disagreed but you still wanted to apply for this university. Time: ${timestamp}</span>`,
    }),
    StudentNOTApplyingBasedOnGalvanizeRecommendation: (fName, univName, timestamp) => ({
      title: `${fName}
        , you proposed <b>
        ${univName}
        but we are not comfortable </b>with this choice for the following reason:`,
      agreementText: `<span class="text-danger "> ${fName}, you have decided not to apply for this university on ${timestamp}</span>.`,
    }),

    GalvanizeRecommendedAndStudentAgreed: (fName, univName, timestamp) => ({
      title: `Galvanize recommends you the <b>  ${univName}</b> for the following reasons:`,
      agreementText: `<span class="text-lg-green "> ${fName}, you agreed with this recommendation on ${timestamp} </span>`,
    }),
    GalvanizeRecommendedNeedStudentInput: (fName, univName) => ({
      title: `Galvanize recommends you the <b>  ${univName}</b> for the following reasons:`,
      popOverContent: {
        successPopover: {
          buttonText: 'I agree with this recommendation',
          statusChange: 'Galvanize recommended and student agreed',
          content: {
            header: univName,
            content: 'Are you sure you agree with this recommendation?',
          },
          onSuccess: {
            header: univName,
            content: `${fName}, once you've decided to apply to ${univName}, please go to the shortlisted universities and select "I will apply" under "Finalize. After you do this, we may provide more information needed to complete your application.`,
            imageUrl: '/assets/static/images/Finalize_application_demo.png',
            successButtonText: 'Ok, let me do this!',
            showFooter: false,
            showCancelButton: false,
          },
        },
        failurePopover: {
          statusChange: "Galvanize recommended and student didn't agree",
          buttonText: "I don't agree with this recommendation",
          loadNewPopover: 'ReasonForNotApplyingPopover',
        },
      },
    }),
    GalvanizeRecommendedAndStudentNotAgreed: (fName, univName, timestamp) => ({
      title: `Galvanize recommends you the<b>  ${univName}</b> for the following reasons:`,
      agreementText: `<span class="text-danger "> ${fName}, you are not agreed with this recommendation on ${timestamp} for the following reasons:</span>`,
      isShowStudentComments: true,
    }),
    StudentApplyingAfterDisagree: (fName, univName, newtimestamp, oldtimestamp) => ({
      title: `Galvanize recommends you the<b>  ${univName}</b> for the following reasons:`,
      agreementText: `<span class="text-lg-green"> ${fName}, you are not agreed with this recommendation on ${oldtimestamp}. ${
        newtimestamp ? `However on ${newtimestamp} you,` : 'You'
      } informed us that you want to apply to this program. </span>`,
    }),
  };
  studentPreferredUniversityComment = 'Preferred University';
  // keeping it array to add more change of agreements in future
  changeOfAgreementCriteria = ["Galvanize recommended and student didn't agree"];

  loadNewPopover = {
    ReasonForNotApplyingPopover: {
      content: {
        successButtonText: 'Submit',
        content:
          "Please select upto 3 reasons as to why you don't agree with this university recommendation.",
      },
    },
  };

  commentsMisMatchPopover = {
    applyingUniversityWithoutAgreement: (universityName, fName) => {
      return `${fName}, you have not given any comments for the ${universityName} shortlisted for you. Please give your comments for this university before you select "I will apply/I will NOT apply".`;
    },
    applyingUniversityWithDisagreement: (universityName, fName, timestamp, program) => {
      return `${fName} on ${timestamp}, you had disagreed with Galvanize recommendation to apply to <b><i>${program}</i></b> at <b><i>${universityName}</i></b>. Are you sure you want to apply to this program?`;
    },
  };
  servicingUniversityCombinations = {
    3: [
      ['Ambitious', 'Practical', 'Safe'],
      ['Practical', 'Practical', 'Safe'],
      ['Practical', 'Safe', 'Safe'],
      ['Safe', 'Safe', 'Safe'],
    ],
    6: [
      ['Ambitious', 'Ambitious', 'Practical', 'Practical', 'Practical', 'Safe'],
      ['Ambitious', 'Ambitious', 'Practical', 'Practical', 'Safe', 'Safe'],
      ['Ambitious', 'Ambitious', 'Practical', 'Safe', 'Safe', 'Safe'],
      ['Ambitious', 'Practical', 'Safe', 'Safe', 'Safe', 'Safe'],
      ['Ambitious', 'Practical', 'Practical', 'Safe', 'Safe', 'Safe'],
      ['Ambitious', 'Practical', 'Practical', 'Practical', 'Safe', 'Safe'],
      ['Ambitious', 'Practical', 'Practical', 'Practical', 'Practical', 'Safe'],
      ['Practical', 'Practical', 'Practical', 'Practical', 'Safe', 'Safe'],
      ['Practical', 'Practical', 'Practical', 'Safe', 'Safe', 'Safe'],
      ['Practical', 'Practical', 'Safe', 'Safe', 'Safe', 'Safe'],
      ['Practical', 'Safe', 'Safe', 'Safe', 'Safe', 'Safe'],
      ['Safe', 'Safe', 'Safe', 'Safe', 'Safe', 'Safe'],
    ],
  };
  allUniversityCombinations = {
    '3': [
      ['Ambitious', 'Ambitious', 'Ambitious'],
      ['Ambitious', 'Ambitious', 'Practical'],
      ['Ambitious', 'Ambitious', 'Safe'],
      ['Ambitious', 'Practical', 'Practical'],
      ['Ambitious', 'Practical', 'Safe'],
      ['Ambitious', 'Safe', 'Safe'],
      ['Practical', 'Practical', 'Practical'],
      ['Practical', 'Practical', 'Safe'],
      ['Practical', 'Safe', 'Safe'],
      ['Safe', 'Safe', 'Safe'],
    ],
    '6': [
      ['Ambitious', 'Ambitious', 'Ambitious', 'Ambitious', 'Ambitious', 'Ambitious'],
      ['Ambitious', 'Ambitious', 'Ambitious', 'Ambitious', 'Ambitious', 'Practical'],
      ['Ambitious', 'Ambitious', 'Ambitious', 'Ambitious', 'Ambitious', 'Safe'],
      ['Ambitious', 'Ambitious', 'Ambitious', 'Ambitious', 'Practical', 'Practical'],
      ['Ambitious', 'Ambitious', 'Ambitious', 'Ambitious', 'Practical', 'Safe'],
      ['Ambitious', 'Ambitious', 'Ambitious', 'Ambitious', 'Safe', 'Safe'],
      ['Ambitious', 'Ambitious', 'Ambitious', 'Practical', 'Practical', 'Practical'],
      ['Ambitious', 'Ambitious', 'Ambitious', 'Practical', 'Practical', 'Safe'],
      ['Ambitious', 'Ambitious', 'Ambitious', 'Practical', 'Safe', 'Safe'],
      ['Ambitious', 'Ambitious', 'Ambitious', 'Safe', 'Safe', 'Safe'],
      ['Ambitious', 'Ambitious', 'Practical', 'Practical', 'Practical', 'Practical'],
      ['Ambitious', 'Ambitious', 'Practical', 'Practical', 'Practical', 'Safe'],
      ['Ambitious', 'Ambitious', 'Practical', 'Practical', 'Safe', 'Safe'],
      ['Ambitious', 'Ambitious', 'Practical', 'Safe', 'Safe', 'Safe'],
      ['Ambitious', 'Ambitious', 'Safe', 'Safe', 'Safe', 'Safe'],
      ['Ambitious', 'Practical', 'Practical', 'Practical', 'Practical', 'Practical'],
      ['Ambitious', 'Practical', 'Practical', 'Practical', 'Practical', 'Safe'],
      ['Ambitious', 'Practical', 'Practical', 'Practical', 'Safe', 'Safe'],
      ['Ambitious', 'Practical', 'Practical', 'Safe', 'Safe', 'Safe'],
      ['Ambitious', 'Practical', 'Safe', 'Safe', 'Safe', 'Safe'],
      ['Ambitious', 'Safe', 'Safe', 'Safe', 'Safe', 'Safe'],
      ['Practical', 'Practical', 'Practical', 'Practical', 'Practical', 'Practical'],
      ['Practical', 'Practical', 'Practical', 'Practical', 'Practical', 'Safe'],
      ['Practical', 'Practical', 'Practical', 'Practical', 'Safe', 'Safe'],
      ['Practical', 'Practical', 'Practical', 'Safe', 'Safe', 'Safe'],
      ['Practical', 'Practical', 'Safe', 'Safe', 'Safe', 'Safe'],
      ['Practical', 'Safe', 'Safe', 'Safe', 'Safe', 'Safe'],
      ['Safe', 'Safe', 'Safe', 'Safe', 'Safe', 'Safe'],
    ],
  };
  constructor(private _constants: Constants) {}
}
