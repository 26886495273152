import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 *
 *
 * @export
 * @class NavQuestPrevNextComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-nav-quest-prev-next',
  templateUrl: './nav-quest-prev-next.component.html',
  styleUrls: ['./nav-quest-prev-next.component.scss'],
})
export class NavQuestPrevNextComponent implements OnInit {
  /**
   *
   *
   * @type {String}
   * @memberof NavQuestPrevNextComponent
   */
  @Input() public prevScreenLabel: String = null;
  @Input() public isSectionInstructionsPage = null;
  @Input() public hideNextScreenLabel = null;

  /**
   *
   *
   * @type {String}
   * @memberof NavQuestPrevNextComponent
   */
  @Input() public nextScreenLabel: String = null;
  /**
   *
   *
   * @type {*}
   * @memberof NavQuestPrevNextComponent
   */
  @Input() public currentScreenDetails: any = null;
  /**
   *
   *
   * @type {*}
   * @memberof NavQuestPrevNextComponent
   */
  @Input() public prevScreenDetails: any = null;
  /**
   *
   *
   * @type {*}
   * @memberof NavQuestPrevNextComponent
   */
  @Input() public nextScreenDetails: any = null;

  /**
   *
   *
   * @type {EventEmitter<any>}
   * @memberof NavQuestPrevNextComponent
   */
  @Output() public gotoByScreenDetailsEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  /**
   *
   *
   * @memberof NavQuestPrevNextComponent
   */
  ngOnInit() {}

  /**
   *
   *
   * @param {*} screenDetails
   * @param {*} [navigationType=null]
   * @memberof NavQuestPrevNextComponent
   */
  onGoTo(screenDetails, navigationType = null) {
    const dict = {
      screenDetails: screenDetails,
      navigationType: navigationType,
    };
    this.gotoByScreenDetailsEmitter.emit(dict);
  }
}
