<div class="modal-parent">
  <div [ngClass]="headerClass ? [headerClass] : 'header-popup'">
    <div [ngClass]="titleClass ? [titleClass] : ''" [innerHTML]="header"></div>
  </div>
  <div mat-dialog-content>
    <p class="content" [innerHTML]="content"></p>
  </div>
  <div class="dialog-footer no-padding" align="right">
    <button
      class="btn btn-link secondary-button"
      *ngIf="secondaryText"
      (click)="doAction('secondary')"
    >
      {{ secondaryText }}
    </button>
    <button class="btn btn-red-button next" *ngIf="primaryText" (click)="doAction('primary')">
      {{ primaryText }}
    </button>
  </div>
</div>
