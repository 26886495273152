import { Pipe, PipeTransform } from '@angular/core';

/**
 *
 *
 * @export
 * @class IndexToCharPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'indexToChar',
  pure: false,
})
export class IndexToCharPipe implements PipeTransform {
  /**
   *
   *
   * @param {*} value
   * @param {*} [args]
   * @returns {*}
   * @memberof IndexToCharPipe
   */
  transform(value: any, args?: any): any {
    if (value <= 26) {
      return String.fromCharCode(65 + value);
    }
    return null;
  }
}
