import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetDocService } from '@entrayn/web/features/authoring-tools/services/api-data/getdoc.service';
import { map } from 'rxjs/operators';

import { Constants } from '../../../libs/core/services/config';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private _siteUrl = this.constants.SITE_URL;
  public requestsData = [];

  constructor(
    private _http: HttpClient,
    public constants: Constants,
    private _docService: GetDocService
  ) {}

  apiGet(url: string, needsAuth = false) {
    return this._http.get(`${this._siteUrl}${url}`);
  }

  apiPatch(url: string, data: any, needsAuth = false) {
    return this._http.patch(`${this._siteUrl}${url}`, data);
  }

  apiPost(url: string, data: any, needsAuth = false) {
    return this._http.post(`${this._siteUrl}${url}`, data);
  }

  apiDelete(url: string, needsAuth = false) {
    return this._http.delete(`${this._siteUrl}${url}`);
  }
  getResourceSet(uuid) {
    return this.apiGet(`${this.constants.RESOURCE_SET_URL}/${uuid}`);
  }
  private apiPostForFormData(url: string, data: FormData, needsAuth = false) {
    return this._http.post(`${this._siteUrl}${url}`, data);
  }

  getS3Url(uuid) {
    return this.apiGet(`${this.constants.POST_RESOURCE_URL}/${uuid}`, true).pipe(
      map(res => {
        // TODO(praveena): remove this
        return res[Object.keys(res)[0]];
      })
    );
  }

  getRefreshedResourceUrl(uuid) {
    return this._http.get(`${this.constants.SITE_URL}${this.constants.POST_RESOURCE_URL}/${uuid}`);
  }

  getQuestionSet(uuid) {
    return this.apiGet(`${this.constants.POST_QUESTION_SET_URL}/${uuid}`, true);
  }

  getFilteredQuestionSets(subjects = null, topics = null, pageNumber = null, isPublished = null) {
    let filterParams = '';
    if (subjects.length >= 1) {
      const chosenSubjects = subjects.join(',');
      filterParams = filterParams.concat(`?subject_names=${chosenSubjects}`);
    }
    if (topics.length >= 1) {
      const chosenTopics = topics.join(',');
      if (filterParams !== '') {
        filterParams = filterParams.concat(`&topic_names=${chosenTopics}`);
      } else {
        filterParams = filterParams.concat(`?topic_names=${chosenTopics}`);
      }
    }
    if (pageNumber) {
      if (filterParams !== '') {
        filterParams = filterParams.concat(`&page_number=${pageNumber}`);
      } else {
        filterParams = filterParams.concat(`?page_number=${pageNumber}`);
      }
    }
    if (isPublished) {
      if (filterParams !== '') {
        filterParams = filterParams.concat(`&is_published=${isPublished}`);
      } else {
        filterParams = filterParams.concat(`?is_published=${isPublished}`);
      }
    }
    if (filterParams !== '') {
      return this.apiGet(`${this.constants.POST_QUESTION_SET_URL}${filterParams}`, true);
    }
    return this.apiGet(`${this.constants.POST_QUESTION_SET_URL}`, true);
  }

  postAssessment(assessmentTemplate) {
    return this.apiPost(this.constants.POST_ASSESSMENT_TEMPLATE_URL, assessmentTemplate, true);
  }

  getAssessment(uuid) {
    return this.apiGet(`${this.constants.POST_ASSESSMENT_TEMPLATE_URL}/${uuid}`, true).pipe(
      map(assessment => {
        // TODO(praveena): remove this
        Object.keys(assessment['assets']['questions']).forEach(questionUuid => {
          const value = assessment['assets']['questions'][questionUuid];
          const questionPayload = value[Object.keys(value)[0]];
          assessment['assets']['questions'][questionUuid] = questionPayload;
        });
        Object.keys(assessment['assets']['resources']).forEach(resourceUuid => {
          const value = assessment['assets']['resources'][resourceUuid];
          const resourcePayload = value[Object.keys(value)[0]];
          assessment['assets']['resources'][resourceUuid] = resourcePayload;
        });
        return assessment;
      })
    );
  }

  getRoots() {
    return this.apiGet(`${this.constants.GET_ROOTS_URL}/roots`, true);
  }

  getFlatDescendants(uuid) {
    return this.apiGet(`${this.constants.GET_ROOTS_URL}/${uuid}/descendants?flat=True`, true);
  }

  getDescendants(uuid) {
    return this.apiGet(`${this.constants.GET_ROOTS_URL}/${uuid}/descendants`, true);
  }

  patchAssessment(assessmentTemplate, uuid) {
    return this.apiPatch(
      `${this.constants.POST_ASSESSMENT_TEMPLATE_URL}/${uuid}`,
      assessmentTemplate,
      true
    );
  }

  patchSection(sectionTemplate, assessmentUuid, sectionUuid) {
    return this.apiPatch(
      `${this.constants.POST_ASSESSMENT_TEMPLATE_URL}/${assessmentUuid}/sections/${sectionUuid}`,
      sectionTemplate,
      true
    );
  }

  postSection(sectionTemplate, assessmentUuid) {
    return this.apiPost(
      `${this.constants.POST_ASSESSMENT_TEMPLATE_URL}/${assessmentUuid}/sections`,
      sectionTemplate,
      true
    );
  }

  postSectionElementMeta(sectionElement, assessmentUuid, sectionUuid) {
    return this.apiPost(
      `${this.constants.POST_ASSESSMENT_TEMPLATE_URL}/${assessmentUuid}/${this.constants.POST_SECTIONS}/${sectionUuid}/${this.constants.POST_ELEMENTS}`,
      sectionElement,
      true
    );
  }

  patchSectionElementMeta(
    sectionElement,
    assessmentTemplateUuid,
    sectiontemplateUuid,
    elementTemplateUuid
  ) {
    return this.apiPatch(
      `${this.constants.POST_ASSESSMENT_TEMPLATE_URL}/${assessmentTemplateUuid}/${this.constants.POST_SECTIONS}/${sectiontemplateUuid}/${this.constants.POST_ELEMENTS}/${elementTemplateUuid}`,
      sectionElement,
      true
    );
  }

  postQuestion(question, questionIndex) {
    // TODO(praveena): remove this
    const key = question.exam.toLowerCase() + '_' + question.question_type.toLowerCase();
    const payload = {
      [key]: question,
    };
    return this.apiPost(
      `${this.constants.POST_QUESTION_URL}?file_id=${this._docService.fileID}||${questionIndex}||${this.constants.TABLE_KEY_QUESTION_UUID}`,
      payload,
      true
    ).pipe(
      map(res => {
        // TODO(praveena): remove this
        return res[Object.keys(res)[0]];
      })
    );
  }

  patchQuestion(question, questionUuid) {
    /* TODO(Chinmaie): Until we use the old BE i.e., which is not yet refactored, we'll have to send in the 'key:{payload}' structure.
    This can be removed once the refactored BE where outer key is not required is moved to master*/
    const key = question.exam.toLowerCase() + '_' + question.question_type.toLowerCase();
    const payload = {
      [key]: question,
    };
    return this.apiPatch(`${this.constants.POST_QUESTION_URL}/${questionUuid}`, payload, true).pipe(
      map(res => {
        // TODO(Chinmaie): remove this
        return res[Object.keys(res)[0]];
      })
    );
  }

  postQuestionMeta(questionMetaData) {
    return this.apiPost(`${this.constants.POST_QUESTIONS_META}`, questionMetaData, true);
  }

  postIndependentResourceMeta(independentResourceMetaData) {
    return this.apiPost(
      `${this.constants.POST_INDEPENDENT_RESOURCE_META}`,
      independentResourceMetaData,
      true
    );
  }

  patchQuestionMeta(questionMetaData, questionTemplateUuid) {
    return this.apiPatch(
      `${this.constants.POST_QUESTIONS_META}/${questionTemplateUuid}`,
      questionMetaData,
      true
    );
  }

  patchInstructorScore(payload, uuid) {
    return this.apiPatch(this.constants.INSTRUCTOR_FEEDBACK.PATCH_SCORE_URL(uuid), payload);
  }

  patchInstructorFeedback(payload, uuid) {
    return this.apiPatch(this.constants.INSTRUCTOR_FEEDBACK.PATCH_FEEDBACK_URL(uuid), payload);
  }

  patchInstructorScoreFeedBack(payload, uuid) {
    return this.apiPatch(`${this.constants.QUESTION_INSTANCE_URL}/${uuid}`, payload, true);
  }

  patchIndependentResourceMeta(independentResourceMetaData, independentResourceTemplateUuid) {
    return this.apiPatch(
      `${this.constants.POST_INDEPENDENT_RESOURCE_META}/${independentResourceTemplateUuid}`,
      independentResourceMetaData,
      true
    );
  }

  postPassage(resource, resourceIndex) {
    // const payload = {
    //   data: resource,
    // };
    // TODO(praveena): remove below code and uncomment above code
    const resourceType = resource.resource_type.toLowerCase();
    const payload = {
      data: {
        [resourceType]: resource,
      },
    };
    return this.apiPost(
      `${this.constants.POST_RESOURCE_URL}?file_id=${this._docService.fileID}||${resourceIndex}||PASS_UUID`,
      payload,
      true
    ).pipe(
      map(res => {
        // TODO(praveena): remove this
        return res[Object.keys(res)[0]];
      })
    );
  }

  patchResource(resource, resourceUuid) {
    return this.apiPatch(`${this.constants.POST_RESOURCE_URL}/${resourceUuid}`, resource, true);
  }

  postQuestionSet(qsetPayload) {
    return this.apiPost(`${this.constants.POST_QUESTION_SET_URL}`, qsetPayload, true);
  }

  patchQuestionSet(payload, questionSetUuid) {
    return this.apiPatch(
      `${this.constants.POST_QUESTION_SET_URL}/${questionSetUuid}`,
      payload,
      true
    );
  }

  postQuestionSetElement(qsetElementPayload) {
    return this.apiPost(
      `${this.constants.POST_QUESTION_SET_ELEMENT_URL}`,
      qsetElementPayload,
      true
    );
  }

  attachResourceToQuestionSetElement(qsetElementPayload, resourceData) {
    return this.apiPost(
      `${this.constants.POST_RESOURCE_URL}/${resourceData}/attach`,
      qsetElementPayload,
      true
    );
  }

  fileDownload(resourceIndex, tableKey, postData) {
    // TODO(praveena): remove this.
    const resourceType = postData['data']['resource_type'].toLowerCase();
    const resourceFileId = postData['data']['resource_file_id'];
    delete postData['data']['resource_file_id'];
    const payload = {
      data: {
        [resourceType]: postData['data'],
        resource_file_id: resourceFileId,
      },
    };
    // ***********(till here)  In both api requests replace payload with postData
    if (tableKey) {
      return this.apiPost(
        `${this.constants.POST_RESOURCE_URL}?file_id=${this._docService.fileID}||${resourceIndex}||${tableKey}`,
        payload,
        true
      ).pipe(
        map(res => {
          // TODO(praveena): remove this
          return res[Object.keys(res)[0]];
        })
      );
    } else {
      return this.apiPost(`${this.constants.POST_RESOURCE_URL}`, payload, true).pipe(
        map(res => {
          // TODO(praveena): remove this
          return res[Object.keys(res)[0]];
        })
      );
    }
  }

  getSampleTestInstance() {
    return this.apiPost(this.constants.MARKETING_TEST_URL, null);
  }
  doLogin(postLoginData) {
    return this.apiPost(this.constants.LOGIN_URL, postLoginData);
  }

  getEtagOfDataFromS3(url) {
    return this._http.head(url, { observe: 'response' });
  }

  getDataFromSheets(url) {
    return this._http.get(url, { observe: 'response' });
  }

  deleteElementTemplate(assessmentTemplateUuid, sectiontemplateUuid, elementTemplateUuid) {
    return this.apiDelete(
      `${this.constants.POST_ASSESSMENT_TEMPLATE_URL}/${assessmentTemplateUuid}/${this.constants.POST_SECTIONS}/${sectiontemplateUuid}/${this.constants.POST_ELEMENTS}/${elementTemplateUuid}`,
      true
    );
  }

  getResource(uuid) {
    return this.apiGet(`${this.constants.POST_RESOURCE_URL}/${uuid}`, true).pipe(
      map(res => {
        // TODO(praveena): remove this
        return res[Object.keys(res)[0]];
      })
    );
  }

  postAssessmentInteractions(uuid, payload) {
    const requestInformation = {
      url: `${this.constants.SITE_URL}${this.constants.ASSESSMENT_INSTANCE_URL}/${uuid}/${this.constants.ASSESSMENT_INTERACTIONS}`,
      method: 'POST',
      body: JSON.stringify(payload),
      contentType: 'application/json',
    };
    this.requestsData.push(requestInformation);
    return this.apiPost(
      `${this.constants.ASSESSMENT_INSTANCE_URL}/${uuid}/${this.constants.ASSESSMENT_INTERACTIONS}`,
      payload
    );
  }

  postQuestionInteraction(postData) {
    const requestInformation = {
      url: `${this.constants.SITE_URL}${this.constants.POST_QUESTION_INTERACTIONS_URL}`,
      method: 'POST',
      body: postData.get('data'),
      file: postData.get('file'),
      contentType: 'form-data',
    };
    this.requestsData.push(requestInformation);
    return this.apiPost(this.constants.POST_QUESTION_INTERACTIONS_URL, postData);
  }
  submitAssessment(uuid, payload) {
    const requestInformation = {
      url: `${this.constants.SITE_URL}${this.constants.ASSESSMENT_INSTANCE_URL}/${uuid}/${this.constants.END_TEST_URL}`,
      method: 'POST',
      body: JSON.stringify(payload),
      contentType: 'application/json',
    };
    this.requestsData.push(requestInformation);
    return this.apiPost(
      `${this.constants.ASSESSMENT_INSTANCE_URL}/${uuid}/${this.constants.END_TEST_URL}`,
      payload
    );
  }

  getUserEntitlements(conceptUuid) {
    return this._http.get(
      `${this.constants.SITE_URL}${this.constants.ENTITLEMENTS_URL}?concept_uuid=${conceptUuid}`
    );
  }

  getUserAudioResponse(uuid) {
    return this.apiGet(`${this.constants.POST_QUESTION_INTERACTIONS_URL}/${uuid}`, true);
  }

  getResultsJson(uuid) {
    return this._http.get(
      `${this.constants.SITE_URL}${this.constants.ASSESSMENT_INSTANCE_URL}/${uuid}/${this.constants.EVALUATE_URL}`
    );
  }
  isNetworkAvailable() {
    return this._http.head(this.constants.URL_TO_CHECK_NETWORK_AVAILABILITY, {
      observe: 'response',
    });
  }
}
