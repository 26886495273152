import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionService } from 'ng-connection-service';

import { ConfigurablePopUpComponent } from '../../ui/components/configurable-pop-up/configurable-pop-up.component';

@Injectable()
export class UIService {
  popupData;
  constructor(
    private _http: HttpClient,
    private _dialog: MatDialog,
    private _connectionService: ConnectionService
  ) {}
  getPopupData() {
    if (this.popupData) {
      return of(this.popupData);
    }
    return this._http.get('/assets/popup.json').pipe(
      map(res => {
        this.popupData = res;
        return res;
      })
    );
  }
  capitalizeFirstAlphabet(text) {
    if (text.charAt(0) === ',') return text.charAt(2).toUpperCase() + text.substring(3);
    else
      return (
        text
          .trimStart()
          .charAt(0)
          .toUpperCase() + text.substring(1)
      );
  }
  alertNoInternetConnectivityService() {
    return this._connectionService.monitor();
  }
  replaceVariables(message, variableDict) {
    // When the keys such as 'Fname', 'SimTitle' etc. are absent we are removing that field and capitalizing the alphabet after comma
    if (Object.keys(variableDict).length === 0) {
      message = message.replace(/{{([^}]*)}}/, '');
    } else {
      Object.keys(variableDict).forEach(key => {
        /* Replace the words like {{Fname}}, {{SimTitle}} with actual values. The variable key contains
        'Fname', 'SimTitle' etc. */
        const re = new RegExp(`{{${key}}}`, 'g');
        message = message.replace(re, variableDict[key]);
      });
    }
    return this.capitalizeFirstAlphabet(message);
  }

  showPopUp(type, configurableMessageDict, styles = null) {
    const popupInformation = this.popupData[type];
    const arrayOfContents = popupInformation['content'];
    const randMessage = arrayOfContents[Math.floor(Math.random() * arrayOfContents.length)];
    const randHeader =
      popupInformation['header'][Math.floor(Math.random() * popupInformation['header'].length)];
    return this._dialog.open(ConfigurablePopUpComponent, {
      data: {
        header: this.replaceVariables(randHeader, configurableMessageDict),
        content: this.replaceVariables(randMessage, configurableMessageDict),
        primaryText: popupInformation['primary_text'],
        secondaryText: popupInformation['secondary_text'],
        disablePopupClose: popupInformation['disablePopupClose'],
        headerClass: styles ? styles['headerClass'] : null,
        titleClass: styles ? styles['titleClass'] : null,
      },
    });
  }
}
