import { Injectable } from '@angular/core';

import { InternetConnectivityService } from '@entrayn/core/services/internet-connectivity.service';

@Injectable({
  providedIn: 'root',
})
export class RequestErrorService {
  private _failedRequestCounter: any[] = [];

  constructor(private _internetConnectivity: InternetConnectivityService) {}

  addErrorCounter(request) {
    const requestExists = this._failedRequestCounter.find(element => {
      return element['request'] == request.request;
    });
    // To not push the same failed request into the counter more than once
    if (!requestExists) {
      this._failedRequestCounter.push(request);
    }
  }

  removeError(request) {
    if (this._failedRequestCounter.length > 0) {
      const errorIndex = this._failedRequestCounter.findIndex(requestObject => {
        return requestObject['request'] === request;
      });
      if (errorIndex !== -1) {
        this._failedRequestCounter.splice(errorIndex, 1);
        this._internetConnectivity.retrySubject.next(this._failedRequestCounter.length === 0);
      }
    }
  }
}
