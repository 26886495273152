/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./go-to-top.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./go-to-top.component";
var styles_GoToTopComponent = [i0.styles];
var RenderType_GoToTopComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GoToTopComponent, data: {} });
export { RenderType_GoToTopComponent as RenderType_GoToTopComponent };
export function View_GoToTopComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["id", "goToTop"], ["title", "Go to top"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoTopPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "svg-entrayn-up-big"]], null, null, null, null, null))], null, null); }
export function View_GoToTopComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-go-to-top", [], null, null, null, View_GoToTopComponent_0, RenderType_GoToTopComponent)), i1.ɵdid(1, 114688, null, 0, i2.GoToTopComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GoToTopComponentNgFactory = i1.ɵccf("app-go-to-top", i2.GoToTopComponent, View_GoToTopComponent_Host_0, {}, {}, []);
export { GoToTopComponentNgFactory as GoToTopComponentNgFactory };
