import { EventEmitter, Injectable, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from '@entrayn/core/services/config';

import { DialogBodyComponent } from '../../../xplat/web/features/shared/components/dialog-body/dialog-body.component';

declare var $, window;
/**
 *
 *
 * @export
 * @class GlobalDataService
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalDataService {
  public data: any;
  @Output()
  public dataChangeObserver: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private _constants: Constants
  ) {}

  /**
   *
   *
   * @param {*} data
   * @memberof GlobalDataService
   */
  setData(data: any) {
    this.data = data;
    this.dataChangeObserver.emit(this.data);
  }

  openPopUp(content) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = content;
    this.dialog.open(DialogBodyComponent, dialogConfig);
  }

  errorHandler(error) {
    return this.openPopUp(JSON.stringify(error));
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }

  stripHtmlTags(text) {
    const div = document.createElement('div');
    div.innerHTML = text;
    return div.textContent || div.innerText || '';
  }
  getParentNode(event) {
    return $(event.target)
      .parent()
      .get(0);
  }
  isRedirectingToDrupalLogin() {
    if (window.location.host.includes(this._constants.DRUPAL_HOST)) {
      window.location.href = this._constants.DRUPAL_URL.LOGIN;
      return true;
    }
    return false;
  }
  getTypeForManualGrading(value) {
    return value.includes('INTEGRATED') ? 'INT' : 'IND';
  }

  trimWhitespace(formData) {
    for (const key in formData) {
      if (formData[key]) {
        if (typeof formData[key] === 'number') {
          formData[key] = formData[key].toString().trim();
          formData[key] = Number(formData[key]);
        } else if (typeof formData[key] === 'string') {
          formData[key] = formData[key].trim();
        } else if (typeof formData[key] === 'object') this.trimWhitespace(formData[key]);
      }
    }
    return formData;
  }
}
