import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as _throw } from 'rxjs';
import { catchError, map, tap, concatMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';

import { Config } from '../config/config';
export interface PaginatedResponse {
  count: number;
  previous: string;
  next: string;
  results: any[];
}
@Injectable({
  providedIn: 'root',
})
export class StudentList {
  constructor(private _http: HttpClient, private _config: Config) {}
  public studentList: any[];
  public dataProgressChanged = new BehaviorSubject(null);
  public getStudentData(url?: string) {
    if (this.studentList) {
      return observableOf(this.studentList);
    } else {
      return this._http.get<PaginatedResponse>(url ? url : this._config.apiEndpoints.users).pipe(
        concatMap(response => {
          if (response.next) {
            this.dataProgressChanged.next(
              Math.floor((response.results.length / response.count) * 100)
            );
            return this.getStudentData(response.next).pipe(
              map((resultsToJoin: any[]) => {
                this.studentList = [...response.results, ...resultsToJoin];
                return this.studentList;
              })
            );
          } else {
            this.dataProgressChanged.next(
              Math.floor((response.results.length / response.count) * 100)
            );
            this.studentList = [response.results];
            return this.studentList;
          }
        })
      );
    }
  }

  public createUserOnBoardingInformation(payload) {
    const uuid = payload['uuid'];
    const onBoardingPayload = payload['on_boarding'];
    return this._http.post(this._config.apiEndpoints.onBoardingInformation, onBoardingPayload).pipe(
      map(res => {
        const index = this.studentList.findIndex(user => user['uuid'] === uuid);
        this.studentList[index]['on_boarding'] = res;
        return this.studentList[index];
      })
    );
  }

  public createUserVisaInformation(payload) {
    const uuid = payload['uuid'];
    const visaPayload = payload['visa_information'];
    return this._http.post(this._config.apiEndpoints.visaInformation, visaPayload).pipe(
      map(res => {
        const index = this.studentList.findIndex(user => user['uuid'] === uuid);
        this.studentList[index]['visa_information'] = res;
        return this.studentList[index];
      })
    );
  }

  getStudentResponseFromList(uuid: string) {
    const selectedStudent = this.studentList.find(student => {
      return student.uuid === uuid;
    });
    return selectedStudent;
  }

  updateStudentList(userData: any) {
    if (this.studentList.length > 0) {
      const index = this.studentList.findIndex(user => user['uuid'] === userData['uuid']);
      this.studentList[index] = userData;
    }
  }
}
