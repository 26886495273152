import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '@entrayn/web/features/auth/services/auth.service';
import { AuthGuard } from './auth.guard';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  private _isAuthorized;
  private _isStaff;
  private _currentUserInfo;
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _authGuard: AuthGuard
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this._isAuthorized = this._authGuard.canActivate(next, state);
    if (!this._isAuthorized) {
      return false;
    }
    return this._authService.getUserObjectFromToken().pipe(
      map(response => {
        this._currentUserInfo = response;
        // TODO: replace this with an access denied page when user is not staff
        return response['is_staff'] ? true : this._authGuard.redirectToLogin();
      }),
      // If user is not logged in, i.e user object cannot be fetched, go back to login
      catchError(err => of(this._authGuard.redirectToLogin()))
    );
  }

  getCurrentUserInfo(params: string[]) {
    const userInfo = {};
    params.forEach(param => {
      userInfo[param] = this._currentUserInfo[param];
    });
    return userInfo;
  }
}
