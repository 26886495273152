import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSectionElements',
  pure: false,
})
export class FilterSectionElementsPipe implements PipeTransform {
  transform(allScreensList: any, sectionInstance: any, element_uuid: any): any {
    const questionSetElements = [];
    const sectionInstanceUuid = sectionInstance['uuid'];

    for (const screenDetail of allScreensList) {
      try {
        if (
          screenDetail.params.section_uuid === sectionInstanceUuid &&
          (screenDetail.params.is_question_page === true ||
            screenDetail.params.is_resource_page === true) &&
          screenDetail.params.element_uuid === element_uuid
        ) {
          questionSetElements.push(screenDetail);
        }
      } catch (e) {}
    }

    return questionSetElements;
  }
}
