import { Pipe, PipeTransform } from '@angular/core';
import { vsprintf } from 'sprintf-js';

/**
 *
 *
 * @export
 * @class VsprintfPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'vsprintf',
  pure: false,
})
export class VsprintfPipe implements PipeTransform {
  /**
   *
   *
   * @param {*} value
   * @param {*} [args]
   * @returns {*}
   * @memberof VsprintfPipe
   */
  transform(value: any, args?: any): any {
    const v = vsprintf(value, args);
    return v;
  }
}
