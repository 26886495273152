import { Pipe, PipeTransform } from '@angular/core';

/**
 *
 *
 * @export
 * @class FilterInRcFillTablePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'filterInRcFillTable',
  pure: false,
})
export class FilterInRcFillTablePipe implements PipeTransform {
  /**
   *
   *
   * @param {*} matchedPairs
   * @param {*} currentChoices
   * @returns {*}
   * @memberof FilterInRcFillTablePipe
   */
  transform(matchedPairs: any, currentChoices: any): any {
    if (matchedPairs instanceof Array) {
      const length = matchedPairs.length;
      for (let i = 0; i < length; i++) {
        const element = matchedPairs[i];
        if (
          element.column1_choice_uuid === currentChoices.column1_choice_uuid &&
          element.column2_choice_uuid === currentChoices.column2_choice_uuid
        ) {
          return true;
        }
      }
    }
    return false;
  }
}
