import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 *
 *
 * @export
 * @class PrettyPrintPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'prettyPrint',
})
export class PrettyPrintPipe implements PipeTransform {
  /**
   *Creates an instance of PrettyPrintPipe.
   * @param {DomSanitizer} sanitizer
   * @memberof PrettyPrintPipe
   */
  constructor(private sanitizer: DomSanitizer) {}

  /**
   *
   *
   * @param {*} value
   * @param {*} [args]
   * @returns {*}
   * @memberof PrettyPrintPipe
   */
  transform(value: any, args?: any): any {
    if (value) {
      let s = ('' + value).toString();
      s = s.replace(/(\\u[A-Fa-f0-9]{4})/gi, ch => {
        return JSON.parse('"' + ch + '"');
      });
      // s = s.replace(/  /g , ' &nbsp;');
      s = s.replace(/\\n/g, '<br/>');
      return this.sanitizer.bypassSecurityTrustHtml(s);
    } else {
      return value;
    }
  }
}
