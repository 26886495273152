import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../../../../libs/core/services/config';
import { GlobalDataService } from '../../../../../libs/features/assessment/global-data.service';
import { AnalyticsService } from './analytics.service';

/**
 *
 *
 * @export
 * @class AuthService
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserObject: any;
  /**
   *Creates an instance of AuthService.
   * @param {HttpClient} _http
   * @param {CookieService} _cookieService
   * @memberof AuthService
   */
  constructor(
    private _http: HttpClient,
    private _cookieService: CookieService,
    private _constants: Constants,
    private _globalService: GlobalDataService,
    private _analytics: AnalyticsService
  ) {}

  /**
   *
   *
   * @param {*} tokenDetails
   * @memberof AuthService
   */
  setAccessTokenDetails(tokenDetails) {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + this._constants.COOKIE_EXPIRATION_PERIOD);
    let host;
    if (window.location.host.includes(this._constants.DRUPAL_HOST)) {
      host = '.entrayn.com';
    }
    this._cookieService.set('refresh_token', tokenDetails.refresh, expiryDate, '/', host);
    this._cookieService.set('access_token', tokenDetails.access, expiryDate, '/', host);
  }

  getUserObjectFromToken() {
    if (this.currentUserObject) {
      return observableOf(this.currentUserObject);
    }
    const url = `${this._constants.SITE_URL}/api/users/current`;
    return this._http.get(url).pipe(
      map(response => {
        this.currentUserObject = response;
        return response;
      })
    );
  }
  /**
   *
   *
   * @returns
   * @memberof AuthService
   */
  getAccessTokenDetails() {
    const currentUserToken = {
      refresh: this._cookieService.get('refresh_token'),
      access: this._cookieService.get('access_token'),
    };
    if (currentUserToken.access && currentUserToken.refresh) {
      return currentUserToken;
    }
    return null;
  }
  /**
   *
   *
   * @memberof AuthService
   */
  removeAccessTokenDetails() {
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token');
  }

  /**
   *
   *
   * @param {*} username
   * @param {*} password
   * @returns
   * @memberof AuthService
   */
  login(username, password) {
    const url = `${this._constants.SITE_URL}${this._constants.LOGIN_URL}`;
    const formData = {
      username: username,
      password: password,
    };
    return this._http.post(url, formData).pipe(
      map(
        data => {
          if (data) {
            this.setAccessTokenDetails(data);
            this.identifyUserForAnalytics(true);
          }
          return data;
        },
        error => {
          this._globalService.errorHandler(error);
        }
      )
    );
  }

  /**
   *
   *
   * @returns
   * @memberof AuthService
   */
  refreshAccessToken() {
    const url = `${this._constants.SITE_URL}${this._constants.REFRESH_TOKEN_URL}`;
    const refresh = this.getRefreshToken();
    const formData = {
      refresh: refresh,
    };
    return this._http.post(url, formData);
  }

  /**
   *
   *
   * @param {*} token
   * @memberof AuthService
   */
  updateAccessTokenDetails(token) {
    const currentUserToken = this.getAccessTokenDetails();
    currentUserToken.access = token.access;
    currentUserToken.refresh = token.refresh;
    this.setAccessTokenDetails(currentUserToken);
  }

  /**
   *
   *
   * @memberof AuthService
   */
  logout() {
    /*
    const url = `${Settings.API_URL_PREFIX}/o/revoke_token/`;
    const t = this.getAccessToken();
    const formData = new FormData();
    formData.set('token', t);
    this.removeAccessTokenDetails();
    this.http.post(url, formData).subscribe(
      data => {
      }
    );
    this.router.navigate(['', 'login']);
    */
  }

  /**
   *
   *
   * @returns
   * @memberof AuthService
   */
  getAccessToken() {
    const currentUserToken = this.getAccessTokenDetails();
    if (currentUserToken === null) {
      return null;
    }
    return currentUserToken.access;
  }

  getRequestHeaders() {
    const headers = {
      Authorization: 'Bearer ' + this.getAccessToken(),
    };
    return headers;
  }
  /**
   *
   *
   * @returns
   * @memberof AuthService
   */
  getRefreshToken() {
    const currentUserToken = this.getAccessTokenDetails();
    if (currentUserToken === null) {
      return null;
    }
    return currentUserToken.refresh;
  }

  /**
   *
   *
   * @returns
   * @memberof AuthService
   */
  loggedIn() {
    const accessToken = this.getAccessTokenDetails();
    if (accessToken !== null) {
      // This will also be triggered if the user goes from testing.entrayn.com
      // to toefl.entrayn.com
      this.identifyUserForAnalytics();
      return true;
    }
    return false;
  }
  identifyUserForAnalytics(force = false) {
    this.getUserObjectFromToken().subscribe(this._analytics.checkAndIdentifyUser(force));
  }
}
