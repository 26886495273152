import { Pipe, PipeTransform } from '@angular/core';

/**
 *
 *
 * @export
 * @class FilterSectionQuestionPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'filterSectionQuestion',
  pure: false,
})
export class FilterSectionQuestionPipe implements PipeTransform {
  /**
   *
   *
   * @param {*} allScreensList
   * @param {*} sectionInstance
   * @param {*} element_uuid
   * @returns {*}
   * @memberof FilterSectionQuestionPipe
   */
  transform(allScreensList: any, sectionInstance: any, element_uuid: any): any {
    const validQuestions = [];
    const sectionInstanceUuid = sectionInstance['uuid'];

    for (const screenDetail of allScreensList) {
      try {
        if (
          screenDetail.params.section_uuid === sectionInstanceUuid &&
          screenDetail.params.is_question_page === true &&
          screenDetail.params.element_uuid === element_uuid
        ) {
          validQuestions.push(screenDetail);
        }
      } catch (e) {}
    }

    return validQuestions;
  }
}
