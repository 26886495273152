import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

declare var $;
@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements AfterViewInit {
  tooltipText = '';
  @Input() classList: string | Array<string> | Object = '';
  @Input() type = 'tooltip';
  @Input()
  set tooltip(tooltip) {
    this.tooltipText = tooltip;
  }
  @ViewChild('tooltipEl', { static: true }) tooltipEl: ElementRef;
  constructor() {}
  ngAfterViewInit() {
    if (this.tooltipEl) {
      // dispose is used to destroy the previously set value
      if (this.type === 'tooltip') {
        $(this.tooltipEl.nativeElement)
          .tooltip('dispose')
          .tooltip({ title: this.tooltipText, boundary: 'window' });
      } else if (this.type === 'popover') {
        $(this.tooltipEl.nativeElement)
          .popover('dispose')
          .popover({
            trigger: 'hover',
            content: this.tooltipText,
            boundary: 'window',
            placement: 'top',
          });
      }
    }
  }
}
