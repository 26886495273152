import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '@entrayn/web/features/auth/services/auth.service';
import { AuthGuard } from './auth.guard';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
import * as i3 from "./auth.guard";
export class RoleGuard {
    constructor(_authService, _router, _authGuard) {
        this._authService = _authService;
        this._router = _router;
        this._authGuard = _authGuard;
    }
    canActivate(next, state) {
        this._isAuthorized = this._authGuard.canActivate(next, state);
        if (!this._isAuthorized) {
            return false;
        }
        return this._authService.getUserObjectFromToken().pipe(map(response => {
            this._currentUserInfo = response;
            // TODO: replace this with an access denied page when user is not staff
            return response['is_staff'] ? true : this._authGuard.redirectToLogin();
        }), 
        // If user is not logged in, i.e user object cannot be fetched, go back to login
        catchError(err => of(this._authGuard.redirectToLogin())));
    }
    getCurrentUserInfo(params) {
        const userInfo = {};
        params.forEach(param => {
            userInfo[param] = this._currentUserInfo[param];
        });
        return userInfo;
    }
}
RoleGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoleGuard_Factory() { return new RoleGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AuthGuard)); }, token: RoleGuard, providedIn: "root" });
