import { versions } from './versions';

// The `versions` file is auto generated on npm run build:<anything>
export const environment = {
  ...versions,
  production: false,
  ENV: 'qa',
  SENTRY_DSN: '',
  SITE_URL: 'https://hulk.entrayn.com',
  DRUPAL_BASE_URL: 'http://qa1.entrayn.com',
  MARKETING_DATA_S3URL:
    'https://s3-ap-southeast-1.amazonaws.com/entrayn-public/UniversityAppListing/dev/marketingTestBanner.json',
  // profile-form-link will be changed later
  AC_PROFILE_FORM:
    'https://docs.google.com/a/entrayn.com/forms/d/e/1FAIpQLSeSvO8h3VU7xr7buFKretrWGzhHmdG3Ch9hGjYdRFD20cpjpA/viewform',
};
