import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

/**
 *
 *
 * @export
 * @class ToeflPassageComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-toefl-passage',
  templateUrl: './toefl-passage.component.html',
  styleUrls: ['./toefl-passage.component.scss'],
})
export class ToeflPassageComponent implements OnInit, OnChanges {
  /**
   *
   *
   * @type {Number}
   * @memberof ToeflPassageComponent
   */
  @Input() public screenNo: Number = null;
  /**
   *
   *
   * @type {*}
   * @memberof ToeflPassageComponent
   */
  @Input() public question: any = null;
  /**
   *
   *
   * @type {*}
   * @memberof ToeflPassageComponent
   */
  @Input() public resource: any = null;

  /**
   *
   *
   * @memberof ToeflPassageComponent
   */
  @Input() public nextScreenDetails = null;
  @Input() public currentScreenDetails: any = null;

  /**
   *
   *
   * @type {EventEmitter<any>}
   * @memberof ToeflPassageComponent
   */
  @Output() public emitter: EventEmitter<any> = new EventEmitter<any>();
  /**
   *
   *
   * @type {EventEmitter<any>}
   * @memberof ToeflPassageComponent
   */
  @Output() public markedEmitter: EventEmitter<any> = new EventEmitter<any>();
  /**
   *
   *
   * @type {EventEmitter<any>}
   * @memberof ToeflPassageComponent
   */
  @Output() public gotoByScreenDetailsEmitter: EventEmitter<any> = new EventEmitter<any>();

  /**
   *Creates an instance of ToeflPassageComponent.
   * @memberof ToeflPassageComponent
   */
  constructor() {}

  /**
   *
   *
   * @memberof ToeflPassageComponent
   */
  ngOnInit() {}

  /**
   *
   *
   * @memberof ToeflPassageComponent
   */
  onGotoNext() {
    const dict = {
      screenDetails: this.nextScreenDetails,
      navigationType: 'next',
    };
    this.gotoByScreenDetailsEmitter.emit(dict);
  }

  /**
   *
   *
   * @param {SimpleChanges} changes
   * @returns
   * @memberof ToeflPassageComponent
   */
  ngOnChanges(changes: SimpleChanges) {
    if (!changes.screenNo) {
      return;
    }
    if (changes.screenNo.currentValue !== changes.screenNo.previousValue) {
    }
  }
}
