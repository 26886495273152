import { Pipe, PipeTransform } from '@angular/core';

declare var $;

@Pipe({
  name: 'paragraphIndex',
})
export class ParagraphIndexPipe implements PipeTransform {
  transform(html_string: any, paragraph_position_id: any): any {
    const $html = $('<span>' + html_string + '</span>');
    const text = $('#' + paragraph_position_id, $html)[0].outerHTML;
    const lineNumber = $(text)
      .attr('data-doc-position')
      .split('#')[1];
    const $text = $(
      '<b>' +
        'Paragraph ' +
        lineNumber +
        ' is marked with an arrow' +
        '<i class="svg-entrayn-right-arrow"></i>' +
        '</b>'
    );
    return $text.html();
  }
}
