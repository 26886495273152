import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InternetConnectivityService } from '@entrayn/core/services';
import { Constants } from '@entrayn/core/services/config';
import * as Sentry from '@sentry/browser';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, finalize, retryWhen, tap, timeout } from 'rxjs/operators';
import { RequestErrorService } from '../services/request-error.service';

@Injectable()
export class RetryRequestInterceptor implements HttpInterceptor {
  constructor(
    private _requestErrorService: RequestErrorService,
    private _constants: Constants,
    private _internetConnectivity: InternetConnectivityService
  ) {}
  // The requests will be retried after every 5 seconds
  private _retryTimer$ = of(true).pipe(delay(5000));

  sentryError(request, error) {
    // Don't send a sentry error for discussion-thread 404s
    if (!(error.status === 404 && error.url && error.url.endsWith('/discussion-thread/thread'))) {
      Sentry.withScope(scope => {
        scope.setExtra('request', request);
        scope.setExtra('error', error);
        Sentry.captureException(new Error(error.message));
      });
    }
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // 25 second timeout, if a request takes longer than that, it'll fail, and retry normally through !error.status
      timeout(this._constants.REQUEST_TIMEOUT),
      retryWhen(errors =>
        errors.pipe(
          tap(error => {
            this._requestErrorService.addErrorCounter({ request: request });
          }),
          concatMap((error, count) => {
            if (error.status === 0 || !error.status) {
              this._internetConnectivity.retrySubject.next(false);
              return this._retryTimer$;
            } else {
              // retry 3 times and avoid retry for request status startwith 4.
              // because BE itself those request are if retry also getting fail only.
              if (count < 3 && error.status.toString()[0] !== '4') {
                return of(error.status);
              } else {
                this.sentryError(request, error);
                return throwError(error);
              }
            }
          }),
          finalize(() => {
            this._requestErrorService.removeError(request);
          })
        )
      )
    );
  }
}
