import { Pipe, PipeTransform } from '@angular/core';

import { InstructionsService } from '../../../../../libs/features/assessment/api-data/instructions.service';

@Pipe({
  name: 'getInstruction',
})
export class GetInstructionPipe implements PipeTransform {
  constructor(private _instructionsService: InstructionsService) {}

  transform(
    question: any,
    subInstructions?: any,
    learningStage?: any,
    questionInstance?: any
  ): any {
    return this._instructionsService.getQuestionInstruction(
      question,
      subInstructions,
      learningStage,
      questionInstance
    );
  }
}
