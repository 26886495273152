import { Pipe, PipeTransform } from '@angular/core';

declare var $;

@Pipe({
  name: 'paragraphMarker',
})
export class ParagraphMarkerPipe implements PipeTransform {
  transform(html_string: any, paragraph_position_id: any): any {
    const $html = $('<span>' + html_string + '</span>');
    if (paragraph_position_id) {
      if ($('#' + paragraph_position_id, $html).length) {
        $('#' + paragraph_position_id, $html).html('<i class="svg-entrayn-right-arrow arrow"></i>');
      }
    }
    return $html.html();
  }
}
