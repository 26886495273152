<div class="container">
  <div class="row">
    <div class="col">
      <h1 *ngIf="data.title">{{ data.title }}</h1>
    </div>
    <button class="btn btn-red-filled pull-right" (click)="close()">Close</button>
  </div>
  <br />
  <div class="row">
    <div class="col">
      <mat-dialog-content> <ng-template appModalHost></ng-template> </mat-dialog-content>
    </div>
  </div>
  <div *ngIf="data.resourceInstance" class="row">
    <div *ngIf="data.resourceInstance.resource_type == 'AUDIO'">
      <a [href]="data.resourceInstance.file_url" target="_blank"> Audio Url</a>
    </div>
    <div *ngIf="data.resourceInstance.resource_type == 'IMAGE'">
      <a [href]="data.resourceInstance.file_url" target="_blank"> Image Url </a>
    </div>
  </div>
</div>
