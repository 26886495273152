import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../../../libs/core/services/api.service';

@Pipe({
  name: 'refreshUrl',
})
export class RefreshUrlPipe implements PipeTransform {
  constructor(private _apiService: ApiService) {}

  transform(value: any, args?: any): any {
    const uuid = value['uuid'];
    return this._apiService.getRefreshedResourceUrl(uuid).pipe(
      map(res => {
        if (value['resource_type'] == 'AUDIO') {
          return res['audio']['file_url'];
        } else if (value['resource_type'] == 'IMAGE') {
          return res['image']['file_url'];
        }
      })
    );
  }
}
