import { Component, ComponentFactoryResolver, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ModalHostDirective } from '../../directives/modal-host.directive';

@Component({
  selector: 'app-modal-component',
  templateUrl: './modal-component.component.html',
  styleUrls: ['./modal-component.component.scss'],
})
export class ModalComponentComponent implements OnInit {
  @ViewChild(ModalHostDirective, { static: true })
  modalHost: ModalHostDirective;
  constructor(
    private _dialogRef: MatDialogRef<ModalComponentComponent>,
    private _componentFactoryResolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.loadComponent();
  }
  loadComponent() {
    let componentRef;
    let componentFactory;
    if (this.data.component) {
      componentFactory = this._componentFactoryResolver.resolveComponentFactory(
        this.data.component
      );

      const viewContainerRef = this.modalHost.viewContainerRef;
      viewContainerRef.clear();

      componentRef = viewContainerRef.createComponent(componentFactory);
    }

    if (this.data.data) {
      (<any>componentRef.instance).formData = this.data.data;
    }
    if (this.data.questionInstance && componentRef) {
      (<any>componentRef.instance).question = this.data.questionInstance;
    }
    if (this.data.resourceInstance && componentRef) {
      (<any>componentRef.instance).resource = this.data.resourceInstance;
    }
    if (this.data.questionData && componentRef) {
      (<any>componentRef.instance).questionData = this.data.questionData;
    }
    if (this.data.resourceData && componentRef) {
      (<any>componentRef.instance).resourceData = this.data.resourceData;
    }
    if (this.data.questionSetType && componentRef) {
      (<any>componentRef.instance).questionSetType = this.data.questionSetType;
    }
    if (this.data.mode && componentRef) {
      (<any>componentRef.instance).mode = this.data.mode;
    }
  }
  close() {
    this._dialogRef.close();
  }
}
