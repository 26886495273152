import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@entrayn/core';

import { UserReviewService } from '../../../../../../libs/features/assessment/api/user-review.service';

@Component({
  selector: 'app-header-tabs',
  templateUrl: 'header-tabs.component.html',
})
export class HeaderTabsComponent extends BaseComponent {
  @Input() sectionInstance;
  @Input() sectionTitles;
  @Input() sectionUrls;
  @Input() pageName = false;
  @Input() activeSectiontab;
  @Input() entraynHomeUrl;
  @Input() assessmentTitle;
  @Output() sectionUrlEvent = new EventEmitter();
  @Output() summarySectionUrlEvent = new EventEmitter();
  summaryUrl = this._userReviewService.summaryUrl;
  constructor(private _userReviewService: UserReviewService) {
    super();
  }
  gotoQuestion(sectionUrl) {
    this.sectionUrlEvent.emit(sectionUrl);
  }
  gotoSection(sectionUrl) {
    this.summarySectionUrlEvent.emit(sectionUrl);
  }
}
