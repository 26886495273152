import { EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from '@entrayn/core/services/config';
import { DialogBodyComponent } from '../../../xplat/web/features/shared/components/dialog-body/dialog-body.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/material/snack-bar";
import * as i3 from "../../core/services/config";
/**
 *
 *
 * @export
 * @class GlobalDataService
 */
export class GlobalDataService {
    constructor(dialog, snackBar, _constants) {
        this.dialog = dialog;
        this.snackBar = snackBar;
        this._constants = _constants;
        this.dataChangeObserver = new EventEmitter();
    }
    /**
     *
     *
     * @param {*} data
     * @memberof GlobalDataService
     */
    setData(data) {
        this.data = data;
        this.dataChangeObserver.emit(this.data);
    }
    openPopUp(content) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = content;
        this.dialog.open(DialogBodyComponent, dialogConfig);
    }
    errorHandler(error) {
        return this.openPopUp(JSON.stringify(error));
    }
    openSnackBar(message) {
        this.snackBar.open(message, '', {
            duration: 2000,
        });
    }
    stripHtmlTags(text) {
        const div = document.createElement('div');
        div.innerHTML = text;
        return div.textContent || div.innerText || '';
    }
    getParentNode(event) {
        return $(event.target)
            .parent()
            .get(0);
    }
    isRedirectingToDrupalLogin() {
        if (window.location.host.includes(this._constants.DRUPAL_HOST)) {
            window.location.href = this._constants.DRUPAL_URL.LOGIN;
            return true;
        }
        return false;
    }
    getTypeForManualGrading(value) {
        return value.includes('INTEGRATED') ? 'INT' : 'IND';
    }
    trimWhitespace(formData) {
        for (const key in formData) {
            if (formData[key]) {
                if (typeof formData[key] === 'number') {
                    formData[key] = formData[key].toString().trim();
                    formData[key] = Number(formData[key]);
                }
                else if (typeof formData[key] === 'string') {
                    formData[key] = formData[key].trim();
                }
                else if (typeof formData[key] === 'object')
                    this.trimWhitespace(formData[key]);
            }
        }
        return formData;
    }
}
GlobalDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalDataService_Factory() { return new GlobalDataService(i0.ɵɵinject(i1.MatDialog), i0.ɵɵinject(i2.MatSnackBar), i0.ɵɵinject(i3.Constants)); }, token: GlobalDataService, providedIn: "root" });
