import { Component, OnInit } from '@angular/core';

/**
 *
 *
 * @export
 * @class PageNotFoundComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  /**
   *Creates an instance of PageNotFoundComponent.
   * @memberof PageNotFoundComponent
   */
  constructor() {}

  /**
   *
   *
   * @memberof PageNotFoundComponent
   */
  ngOnInit() {}
}
