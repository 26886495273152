import { Pipe, PipeTransform } from '@angular/core';

/**
 *
 *
 * @export
 * @class MinuteSecondsFormatPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'minuteSecondsFormat',
})
export class MinuteSecondsFormatPipe implements PipeTransform {
  /**
   *
   *
   * @param {*} value
   * @param {*} [args]
   * @returns {*}
   * @memberof MinuteSecondsFormatPipe
   */
  transform(value: any, args?: any): any {
    // const h = Math.floor(value / 3600);
    const m = Math.floor((value % 3600) / 60);
    const s = Math.floor((value % 3600) % 60);
    return ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2);
  }
}
