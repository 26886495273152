import { Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { JQueryUtil } from '../../../../libs/core/services/jquery-util';
import { AssessmentInstanceService } from '../../../../libs/features/assessment/api/assessment-instance.service';

/**
 *
 *
 * @export
 * @class AppComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewChecked}
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isScrolled = false;
  /**
   *
   *
   * @memberof AppComponent
   */
  public title = 'app';
  /**
   *
   *
   * @memberof AppComponent
   */
  public currentScreen = null;
  /**
   *
   *
   * @memberof AppComponent
   */
  public allScreensList = [];
  /**
   *
   *
   * @memberof AppComponent
   */
  public assessmentInstance = null;
  /**
   *
   *
   * @memberof AppComponent
   */
  public assessmentInstanceUuid = null;

  /**
   *
   *
   * @private
   * @type {Subscription}
   * @memberof AppComponent
   */
  private _paramsSubscription: Subscription = null;
  /**
   *
   *
   * @private
   * @type {Subscription}
   * @memberof AppComponent
   */
  private _eventsSubscription: Subscription = null;
  /**
   *
   *
   * @private
   * @type {Subscription}
   * @memberof AppComponent
   */
  private _assessmentSubscription: Subscription = null;

  /**
   *Creates an instance of AppComponent.
   * @param {NgZone} _ngZone
   * @param {Router} _router
   * @param {ActivatedRoute} _activatedRoute
   * @param {JQueryUtil} _jqueryMethods
   * @param {AssessmentInstanceService} _assessmentService
   * @memberof AppComponent
   */
  constructor(
    private _ngZone: NgZone,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _jqueryMethods: JQueryUtil,
    private _titleService: Title,
    private _assessmentService: AssessmentInstanceService
  ) {}

  ngOnInit() {
    this._paramsSubscription = this._activatedRoute.params.subscribe(data => {
      if ('assessment_template_uuid' in data) {
        this.assessmentInstanceUuid = data['assessment_template_uuid'];
      } else {
        this.assessmentInstanceUuid = null;
      }
    });
    this._eventsSubscription = this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentScreen = event.url;
        // If the .body class's css or the header's height changes outside of a route change, then this won't work
        this._ngZone.runOutsideAngular(() => {
          this._jqueryMethods.runRoutineMethods();
        });
      }
    });
    this._ngZone.runOutsideAngular(() => {
      this._jqueryMethods.bindEvents();
    });
    this.titleUpdater();

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(function(reg) {
          // registration success
        })
        .catch(function(error) {
          // registration failed
        });
    }
  }

  titleUpdater() {
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this._activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event =>
        this._titleService.setTitle(`Galvanize${event['title'] ? ' | ' + event['title'] : ''}`)
      );
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isScrolled = window.scrollY > 0;
  }
  /**
   *
   *
   * @memberof AppComponent
   */

  /**
   *
   *
   * @memberof AppComponent
   */
  ngOnDestroy() {
    this._paramsSubscription.unsubscribe();
    this._eventsSubscription.unsubscribe();
    this._assessmentSubscription.unsubscribe();
  }
}
