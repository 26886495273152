import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-expandable-passage',
  templateUrl: './expandable-passage.component.html',
  styleUrls: ['./expandable-passage.component.scss'],
})
export class ExpandablePassageComponent implements OnInit {
  @Input() isFullScreen = false;
  @Output() isFullScreenChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    this.isFullScreenChange.emit(this.isFullScreen);
  }
}
