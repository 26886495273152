import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../../../../../libs/core/services/config';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

/**
 *
 *
 * @export
 * @class AccessTokenInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  /**
   *Creates an instance of AccessTokenInterceptor.
   * @param {AuthService} authService
   * @memberof AccessTokenInterceptor
   */
  constructor(private authService: AuthService, private _constants: Constants) {}
  /**
   *
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof AccessTokenInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authService.getAccessTokenDetails();
    if (currentUser && currentUser.access && request.url.startsWith(this._constants.SITE_URL)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.access}`,
        },
      });
    }
    return next.handle(request);
  }
}
