import { Component, Input } from '@angular/core';

import { BaseComponent } from '@entrayn/core';

@Component({
  selector: 'app-progressbar',
  templateUrl: 'progressbar.component.html',
})
export class ProgressbarComponent extends BaseComponent {
  @Input() progressValue: number;
  @Input() tooltipValue: string;
  color = 'warn';
  constructor() {
    super();
  }
}
