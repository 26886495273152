/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toefl-passage.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./toefl-passage.component";
var styles_ToeflPassageComponent = [i0.styles];
var RenderType_ToeflPassageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToeflPassageComponent, data: {} });
export { RenderType_ToeflPassageComponent as RenderType_ToeflPassageComponent };
function View_ToeflPassageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource.text_html; _ck(_v, 0, 0, currVal_0); }); }
export function View_ToeflPassageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "toefl-passage"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToeflPassageComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ToeflPassageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toefl-passage", [], null, null, null, View_ToeflPassageComponent_0, RenderType_ToeflPassageComponent)), i1.ɵdid(1, 638976, null, 0, i3.ToeflPassageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToeflPassageComponentNgFactory = i1.ɵccf("app-toefl-passage", i3.ToeflPassageComponent, View_ToeflPassageComponent_Host_0, { screenNo: "screenNo", question: "question", resource: "resource", nextScreenDetails: "nextScreenDetails", currentScreenDetails: "currentScreenDetails" }, { emitter: "emitter", markedEmitter: "markedEmitter", gotoByScreenDetailsEmitter: "gotoByScreenDetailsEmitter" }, []);
export { ToeflPassageComponentNgFactory as ToeflPassageComponentNgFactory };
