<div [ngClass]="fullWidth ? 'container-fluid' : 'container'">
  <div class="row">
    <div class="col-12">
      <ul class="breadcrumb">
        <li *ngFor="let item of activeCrumbs; let i = index">
          <ng-container *ngIf="i != activeCrumbs.length - 1">
            <ng-container *ngIf="i === 0">
              <a class="active" [attr.href]="item.link">{{ item.field }} </a>
            </ng-container>
            <ng-container *ngIf="i !== 0">
              <a *ngIf="item.linkType === 'href'" class="active" [attr.href]="item.link"
                >{{ item.field }}
              </a>
              <a *ngIf="item.linkType !== 'href'" class="active" [routerLink]="item.link"
                >{{ item.field }}
              </a>
            </ng-container>
            <i class="svg-entrayn-next"></i>
          </ng-container>
          <ng-container *ngIf="i == activeCrumbs.length - 1">
            <div *ngIf="item.hasOwnProperty('tooltip')" [matTooltip]="item.tooltip">
              {{ item.field }}
            </div>
            <div *ngIf="!item.hasOwnProperty('tooltip')">{{ item.field }}</div>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</div>
