import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, of as observableOf } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Config } from '../config/config';
import { StudentProfileEditConfig } from '../config/student-profile-config';
import { StudentProfile } from '../models/student-profile';
import { StudentList } from './students-list.service';
import * as i0 from "@angular/core";
import * as i1 from "./students-list.service";
import * as i2 from "../config/student-profile-config";
import * as i3 from "../config/config";
import * as i4 from "@angular/common/http";
export class ViewEditService {
    constructor(_studentProfileService, _studentEditConfig, _config, _http) {
        this._studentProfileService = _studentProfileService;
        this._studentEditConfig = _studentEditConfig;
        this._config = _config;
        this._http = _http;
        this.selectedStudent = new BehaviorSubject(null);
    }
    getStudentDetail(id) {
        if (id) {
            // this.currentUserData = this._studentProfileService.getStudentResponseFromList(id);
            // Since loading user details in new tab, data from the list is always undefined. May be used for future update.
            return forkJoin(this._getStudentData(id), this.getConfig()).pipe(map(res => {
                return this._getProfile(res[0]);
            }));
        }
        else {
            return observableOf(this.currentUserData).pipe(map(res => {
                return this._getProfile(res);
            }));
        }
    }
    _getStudentData(id) {
        const url = this._config.apiEndpoints.userProfile + id;
        return this._http.get(url);
    }
    updateStudentProfile(value) {
        const url = this._config.apiEndpoints.userProfile + value['uuid'];
        return this._http.patch(url, value);
    }
    saveStudentProfile(value) {
        value['uuid'] = this.currentUserData['uuid'];
        return this.updateStudentProfile(value).pipe(map(res => {
            return this._getProfile(res);
        }));
    }
    _getProfile(userData) {
        this.currentUserData = userData;
        this.selectedStudent.next(userData);
        const isProfileFormFilled = userData['admission_info'] && userData['admission_info']['is_admission_info_form_filled'];
        if (isProfileFormFilled)
            this._studentEditConfig.createProfileEditConfig(userData);
        return {
            profileData: isProfileFormFilled ? new StudentProfile(userData) : null,
            userData: userData,
        };
    }
    checkUserEnrollmentStatus(enrollment_status) {
        return this._config.enrollmentStatusCriteria.includes(enrollment_status);
    }
    getConfig() {
        const url = this._config.apiEndpoints.editOptions;
        return this._http.get(url).pipe(tap(res => {
            const results = res['results'];
            const config = results[results.length - 1]['config'];
            this._config.dropDownOptions = Object.assign(this._config.dropDownOptions, config);
            this._config.servicingUniversityCombinations = this.constructUniversitiesCombination(config.recommended_pattern_3, config.recommended_pattern_6);
            this._config.allUniversityCombinations = this.constructUniversitiesCombination(config.pattern_3, config.pattern_6);
        }), catchError(err => {
            return observableOf(true);
            // returning true if error occured to use default config from config file
        }));
    }
    constructUniversitiesCombination(pattern_3, pattern_6) {
        const classification = { A: 'Ambitious', P: 'Practical', S: 'Safe' };
        const servicingUniversityCombinations = {
            3: this.splitAndSetFullNameForCombinations(pattern_3, classification),
            6: this.splitAndSetFullNameForCombinations(pattern_6, classification),
        };
        return servicingUniversityCombinations;
    }
    splitAndSetFullNameForCombinations(pattern, classification) {
        const combinations = [];
        pattern.forEach(element => {
            const singleCombination = [];
            element.split('').forEach(elem => {
                singleCombination.push(classification[elem]);
            });
            combinations.push(singleCombination);
        });
        return combinations;
    }
}
ViewEditService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ViewEditService_Factory() { return new ViewEditService(i0.ɵɵinject(i1.StudentList), i0.ɵɵinject(i2.StudentProfileEditConfig), i0.ɵɵinject(i3.Config), i0.ɵɵinject(i4.HttpClient)); }, token: ViewEditService, providedIn: "root" });
