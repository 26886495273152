import { Pipe, PipeTransform } from '@angular/core';

/**
 *
 *
 * @export
 * @class SecondsToTimePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'secondsToTime',
})
export class SecondsToTimePipe implements PipeTransform {
  /**
   *
   *
   * @param {*} value
   * @param {*} [args]
   * @returns {*}
   * @memberof SecondsToTimePipe
   */
  transform(value: any, args?: any): any {
    const h = Math.floor(value / 3600);
    const m = Math.floor((value % 3600) / 60);
    const s = Math.floor((value % 3600) % 60);
    if (args == 'm:s') {
      return ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2);
    } else {
      return ('0' + h).slice(-2) + ':' + ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2);
    }
  }
}
