import { Injectable } from '@angular/core';

import { environment } from '../../../apps/web-cux-entrayn/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Constants {
  constructor() {}
  static SENTRY_DSN = environment.SENTRY_DSN;
  static ENV = environment.ENV;
  static VERSION =
    `${environment.version}` +
    (environment.branch ? `-${environment.branch}.${environment.revision}` : '');
  SITE_URL = environment.SITE_URL;
  DRUPAL_BASE_URL = environment.DRUPAL_BASE_URL;
  DRUPAL_HOST = 'entrayn.com'; // For checking whether we're on a staging/live deployed front end
  // Temporarily allow anyone to manually go to the angular login page
  ALLOW_LOGIN = true; // !environment.production; // So that it's still possible to go to the login page manually on dev/staging
  DRUPAL_URL = {
    HOME: `${this.DRUPAL_BASE_URL}/home`,
    LOGIN: `${this.DRUPAL_BASE_URL}/galvanize/login`,
  };
  PRODUCT_URL = {
    TOEFL: 'https://galvanizetestprep.com',
  };
  ENTITLEMENTS_URL = '/api/user-subscriptions';
  COOKIE_EXPIRATION_PERIOD = 15; // in days
  REQUEST_TIMEOUT = 45000; // Originally 25 second timeout on the frontend
  ASSESSMENT_COMPLETED = 'assessment_completed';
  ENTITLEMENTS_FETCH_COMPLETED = 'entitlements_fetch_completed';
  GOOGLE_SCRIPT_URL =
    'https://script.google.com/macros/s/AKfycbzxzhySK60I8mm5Hj-DOTXoLZKqFCSf7qlQUBXTTqp1dXTQzpi0/exec';
  ASSESSMENT_INSTANCE_URL = '/api/assessments';
  ASSESSMENT_INTERACTIONS = 'interactions';
  END_TEST_URL = 'end-test';
  ASSESSMENT_INTERACTION_TYPE = 'AI';
  ASSESSMENT_END_TRIGGER = 'assessment_end';
  ASSESSMENT_MODE = 'ATTEMPT';
  EVALUATE_URL = 'get';
  FILE_RESOURCE_URL = '/api/file-resources';
  GET_ROOTS_URL = '/api/concepts';
  POST_RESOURCE_URL = '/api/resources';
  POST_QUESTION_URL = '/api/questions';
  POST_QUESTION_SET_URL = '/api/qsets';
  POST_QUESTION_SET_ELEMENT_URL = '/api/qset-elements';
  POST_QUESTION_RESOURCE_URL = '/api/question-resource/';
  POST_ASSESSMENT_TEMPLATE_URL = '/api/assessment-templates';
  MARKETING_TEST_URL = `${this.ASSESSMENT_INSTANCE_URL}/marketing-test?concept=TOEFL`;
  MARKETING_ROUTE_URL = 'toefl';
  POST_SECTIONS = 'sections';
  POST_ELEMENTS = 'elements';
  POST_QUESTIONS_META = '/api/question-templates';
  POST_INDEPENDENT_RESOURCE_META = '/api/resource-templates';
  POST_QUESTION_INTERACTIONS_URL = '/api/question-responses';
  QUESTION_INSTANCE_URL = '/api/question-instances';
  INSTRUCTOR_FEEDBACK = {
    PATCH_SCORE_URL: (questionInstanceUuid: string) =>
      `${this.QUESTION_INSTANCE_URL}/${questionInstanceUuid}/subjective-insights/score`,
    PATCH_FEEDBACK_URL: (questionInstanceUuid: string) =>
      `${this.QUESTION_INSTANCE_URL}/${questionInstanceUuid}/subjective-insights/feedback`,
  };
  LOGIN_URL = '/login';
  REFRESH_TOKEN_URL = '/token/refresh';
  DISCUSSIONS = {
    URLS: {
      // To fetch a thread for a question:
      GET_THREAD: (threadUuid: string) => `/api/discussions/${threadUuid}`,
      // To create comments:
      COMMENTS: '/api/discussion-comments',
      // To patch is_clarified:
      PATCH_THREAD: (threadUuid: string) => `/api/discussions/${threadUuid}`,
      // TODO: use this for file attachments
      // ATTACHMENTS: '/api/discussion-comments-attachments'
    },
    USER_TYPE: {
      LEARNER: 'Learner',
      INSTRUCTOR: 'Instructor',
    },
  };
  // TODO(praveena): Update the url
  RESOURCE_SET_URL = '/api/file-resource-set';
  VIDEO_RESOURCES = {
    VIDEO: 'VIDEO',
    THUMBNAIL: 'THUMBNAIL',
    TRANSCRIPT: 'TRANSCRIPT',
  };
  CONFIGURABLE_MESSAGE_VARIABLES = {
    FNAME: 'Fname',
    SIM_TITLE: 'SimTitle',
  };

  PREVIEW_MODE = 'previewMode';
  REVIEW_MODE = 'reviewMode';
  ATTEMPT_MODE = 'attemptMode';
  NAVIGATION_RANDOM = 'RANDOM';
  NAVIGATION_SEQUENTIAL = 'SEQUENTIAL';
  TABLE_KEY_AUDIO_UUID = 'AUDIO_UUID';
  TABLE_KEY_IMAGE_UUID = 'IMAGE_UUID';
  TABLE_KEY_PASSAGE_UUID = 'PASS_UUID';
  TABLE_KEY_QUESTION_UUID = 'Q_UUID';
  TOEFL_EXAM_NAMES = ['TOEFL', 'GRE', 'SAT', 'VERBAL_STRENGTHENING'];
  SCORING_METHOD_CHOICES = ['GRE_SCALED_SCORE', 'TOEFL_SCALED_SCORE'];
  SCORING_METHOD_SCALE = {
    TOEFL_SCALED_SCORE: 30,
    TOEFL_READING_SCALED_SCORE: 30,
    TOEFL_LISTENING_SCALED_SCORE: 30,
    TOEFL_SPEAKING_SCALED_SCORE: 30,
    TOEFL_WRITING_SCALED_SCORE: 30,
    // value for raw Score was not provided in config
    // because if json has SCORING_METHOD as 'RAW_SCORE' it will return undefined
    // and scaled score will not be computed.
  };
  UUID_LENGTH = 36;
  ASSESSMENT_MODES = {
    ATTEMPT: 'ATTEMPT',
    REVIEW: 'REVIEW',
  };
  START_TRIGGER_TYPES = {
    MANUAL: 'MANUAL',
    AUTOMATIC: 'AUTOMATIC',
  };
  INDEPENDENT_RESOURCE_TYPES = ['PASSAGE', 'AUDIO'];
  INTERACTION_TYPES = {
    USER_QUESTION_RESPONSE: 'user_question_response',
    ASSESSMENT: 'AI',
    SECTION: 'SI',
    ELEMENT: 'EI',
    INDEPENDENT_RESOURCE: 'IR',
  };
  INTERACTION_TRIGGER_TYPES = {
    ASSESSMENT_END: 'assessment_end',
    ASSESSMENT_START: 'assessment_start',
    ASSESSMENT_RESUME: 'assessment_resume',
    ASSESSMENT_INSTRUCTIONS_START: 'assessment_instructions_start',
    ASSESSMENT_INSTRUCTIONS_RESUME: 'assessment_instructions_resume',
    ASSESSMENT_INSTRUCTIONS_END: 'assessment_instructions_end',
    SECTION_INSTRUCTIONS_START: 'section_instructions_start',
    SECTION_INSTRUCTIONS_RESUME: 'section_instructions_resume',
    SECTION_INSTRUCTIONS_END: 'section_instructions_end',
    SECTION_START: 'section_start',
    SECTION_RESUME: 'section_resume',
    SECTION_END: 'section_end',
    SECTION_ELEMENT_START: 'section_element_start',
    SECTION_ELEMENT_RESUME: 'section_element_resume',
    SECTION_ELEMENT_END: 'section_element_end',
  };
  INDEPENDENT_RESOURCE_TRIGGER = {
    START: 'resource_start',
    END: 'resource_end',
  };
  AUDIO_RECORDER_STATUS = {
    COMPLETED: 'completed',
    SAVED: 'saved',
  };
  USER_QUESTION_INTERACTION = {
    PREPARATION_STARTED_AT: 'preparation_started_at',
    RECORDING_STARTED_AT: 'recording_started_at',
    RECORDING_ENDED_AT: 'recording_ended_at',
  };
  QUESTION_STATUS = {
    SEEN_ANSWERED: 'SeenAnswered',
    SEEN_NOT_ANSWERED: 'SeenNotAnswered',
    NOT_SEEN_NOT_ANSWERED: 'NotSeenNotAnswered',
    CORRECT: 'CORRECT',
    INCORRECT: 'INCORRECT',
    PARTIAL_CORRECT: 'PARTIAL_CORRECT',
  };

  INSTANCE_TYPES = {
    ASSESSMENT: 'assessmentinstance',
  };

  ENTITLEMENTS = {
    // Used to be SPEAKING and WRITING, changed to a general subjective insights entitlement
    SCORE_RETRIEVE: 'subjective-insights-score-retrieve',
    FEEDBACK_RETRIEVE: 'subjective-insights-feedback-retrieve',
    // TODO: there are no objective insights in allowed-actions, so the
    // entitlement check (in user-entitlement.service) is just returning true
    // for READING and LISTENING
    // READING: 'assessment-reading',
    // LISTENING: 'assessment-listening',

    // Other discussions entitlements: "discussion-comments-create", "discussions-get_thread_with_question_instance", "discussions-partial_update", "discussions-update"
    DISCUSSION_COMMENTS_CREATE: 'discussion-comments-create',
    SUBJECTIVE_INSIGHTS_SCORE: 'subjective-insights-score',
    SUBJECTIVE_INSIGHTS_FEEDBACK: 'subjective-insights-feedback',
    DISCUSSION_THREAD_RETRIEVE: 'discussions-retrieve',
    DISCUSSION_THREAD_UPDATE: 'discussions-update',
  };
  LC_ORDER_IN_SEQUENCE_ERROR_DISPLAY_TIME = 4;

  LEARNING_STAGES = {
    MOCK_TEST: 'MOCK_TEST',
    PRE_TEST: 'PRE_TEST',
    UNTIMED_TEST: 'UNTIMED_TEST',
    HANGMAN: 'HANGMAN',
    PRACTICE_TEST: 'PRACTICE_TEST',
    MARKETING_TEST: 'MARK',
    DIAGNOSTIC_TEST: 'DIAG',
  };

  QUESTION_SELECTION_MODE = [
    { value: 'STATIC', viewValue: 'Static' },
    { value: 'DYNAMIC', viewValue: 'Dynamic' },
    { value: '', viewValue: 'None' },
    { value: 'ADAPTIVE', viewValue: 'Adaptive' },
  ];
  BOOLEAN_VALUES = [true, false];
  TRIGGER_VALUES = [
    { value: 'MANUAL', viewValue: 'Manual' },
    { value: 'AUTOMATIC', viewValue: 'Automatic' },
    { value: '', viewValue: 'None' },
  ];
  TIMING_MODES = [
    { value: 'TIMED', viewValue: 'TIMED' },
    { value: 'UNTIMED', viewValue: 'UNTIMED' },
    { value: '', viewValue: 'None' },
  ];
  EVALUATION_MODES = [
    { value: 'SYNCHRONOUS', viewValue: 'Synchronous' },
    { value: 'ASYNCHRONOUS', viewValue: 'Asynchronous' },
    { value: '', viewValue: 'None' },
  ];
  REVIEW_MODES = ['REVIEW_ALLOWED', 'REVIEW_NOT_ALLOWED'];
  TRY_AGAIN_MODES = ['TRY_AGAIN_NOT_ALLOWED', 'TRY_AGAIN_ALLOWED'];
  DYNAMIC_QUESTION_SELECTION_MODES = [
    { value: 'RAND', viewValue: 'RANDOM' },
    { value: '', viewValue: 'None' },
  ];
  ATTEMPT_CHILDREN_MODES = ['SEQUENTIAL', 'RANDOM'];
  RC_TYPES = ['RCMCQ', 'RCMAQ', 'RC_SUMMARY', 'RC_INSERT_SENTENCE', 'RC_FILL_TABLE'];
  LC_TYPES = ['LCMCQ', 'LCMAQ', 'LCMCQ_WITH_PLAYBACK', 'LC_FILL_TABLE', 'LC_ORDER_IN_SEQUENCE'];
  WRITING_TYPES = ['WRITING_INDEPENDENT', 'WRITING_INTEGRATED'];
  SPEAKING_TYPES = [
    'SPEAKING_INDEPENDENT',
    'SPEAKING_INTEGRATED',
    'SPEAKING_INTEGRATED_AUDIO_ONLY',
  ];
  CONCEPT_UUID_DICT = {
    READING: 'ac711a86-de89-4c2b-a571-774f1e227574',
    LISTENING: 'd01dae1c-db75-446e-8227-2999b94379e5',
    WRITING: '2615b21f-f826-4fef-9fe7-b653d4b23600',
    SPEAKING: 'e3c0ea14-9a16-44e0-a16a-025c1cc52725',
    TOEFL: '77fbc378-9369-4160-a586-104b3b9f9410',
    EXPOSITION: '43fe43f0-0151-4d8d-9391-a4129d908019',
    DESCRIPTIVE: '3e156f63-a573-4f79-9028-8d31342baf58',
    ARGUMENTATION: 'c2ce2a72-1058-4c73-84c9-3b3e9b52b69a',
    CONVERSATION: '3380a03c-062d-4573-8c5a-86c7cafd4957',
    LECTURE: 'ce430613-cfca-4582-9e52-11d37b2e3d9f',
    WRITING_INDEPENDENT: 'a1dd3447-a53d-4283-9e1a-701659384c89',
    WRITING_INTEGRATED: '7fe40a80-98be-461e-8ecb-308db7eba39f',
    SPEAKING_INDEPENDENT: '0c72c23a-61e3-4553-bd16-1f9d6ef16619',
    INTEGRATED_CONVERSATION: 'dfed6f4d-58a7-4eeb-8ed6-6df55c46550f',
    INTEGRATED_LECTURE: 'dd63f7b9-ab09-44b3-83d8-ea59ee387468',
    INTEGRATED_NOTICE_CONVERSATION: '8d189c33-b278-405c-9876-b2d6327ff120',
    INTEGRATED_PASSAGE_LECTURE: 'ff5823e9-07b5-4719-b582-4340e8e34e81',
  };
  ASSESSMENT_FLAVOURS = {
    TOEFL_DIAGNOSTIC_TEST: 'TOEFL Diagnostic Test',
  };

  ASSESSMENT_FLAVOUR_CHOICES = [
    'TOEFL Mock Test',
    'TOEFL Reading Section Test',
    'TOEFL Listening Section Test',
    'TOEFL Writing Section Test',
    'TOEFL Speaking Section Test',
    'TOEFL Reading Timed Test',
    'TOEFL Listening Timed Test',
    'TOEFL Writing Timed Test',
    'TOEFL Speaking Timed Test',
    'TOEFL Reading Untimed Test',
    'TOEFL Listening Untimed Test',
    'TOEFL Writing Untimed Test',
    'TOEFL Diagnostic Test',
    'TOEFL Marketing Test',
  ];

  GRADING_STATUS = {
    WILL_BE_GRADED: 'WILL_BE_GRADED',
  };

  GRADING_TYPE = {
    MANUAL_GRADING: 'MANUAL_GRADING',
    AUTOMATIC_GRADING: 'AUTOMATIC_GRADING',
  };

  defaultValueForQuestionMeta = {
    timing: {},
    navigation: {},
    evaluation: {},
    score: {},
    resource_type: null,
  };
  URL_CONFIG_TO_SWITCH_ENVIRONMENT = {
    production: 'http://api.entrayn.com',
    staging: 'http://thor.entrayn.com',
  };
  INTERNAL_USERS_LOGIN_IDENTIFIER = 'internaluser';
  PRESETS_S3URL =
    'https://s3-ap-southeast-1.amazonaws.com/entrayn-public/UniversityAppListing/dev/assessmentPresetData.json';
  MARKETING_DATA_S3URL = environment.MARKETING_DATA_S3URL;
  URL_TO_CHECK_NETWORK_AVAILABILITY =
    'https://s3-ap-southeast-1.amazonaws.com/entrayn-public/connection_test';
  TIMING_MODE = 'timed';
  COPY_TEXT = {
    MICROPHONE: {
      text: "Why doesn't my microphone work?",
      information: [
        {
          problem: 'Microphone is not connected or not available',
          reason: `It’s possible that (1) your mic is just not connected to your computer, (2) plug is not fully inserted into the connector, or (3) your headphone model doesn\'t have a microphone attached to it.`,
        },
        {
          problem: 'Browser’s access is disabled',
          reason:
            'You need to provide your browser with access to the microphone. Please check how to do this online for your browser.',
        },
        {
          problem: 'Microphone is damaged or there’s a hardware problem',
          reason:
            'The microphone may not work if there are defects in the connecting wire or if the audio port/hardware is damaged.',
        },
        {
          problem: 'Audio Driver not installed',
          reason: 'You may not have the proper audio driver installed in your computer.',
        },
        {
          problem: 'Microphone is disabled in Device Manager',
          reason: 'The device may be working, but it may have been disabled by system software. ',
        },
        {
          problem: 'Microphone is being used by another application',
          reason:
            'If an application is using your microphone, the device may not be available for other applications.',
        },
      ],
    },
  };

  CATEGORIES = {
    READING: [
      'Factual_information',
      'Negative_factual_information',
      'Reference',
      'Sentence_simplification',
      'Vocabulary',
      'Insert_a_sentence',
      'Prose_summary',
      'Fill_in_the_Table',
      'Inference',
      'Rhetorical_purpose',
    ],
    LISTENING: [
      'Main_idea',
      'Primary_purpose',
      'Detail',
      'Function',
      'Attitude',
      'Organization',
      'Connecting_content',
      'Inference',
    ],
    SPEAKING: [
      'Personal_choice',
      'Paired_choice',
      'Fit_and_explain',
      'General_specific',
      'Problem_solution',
      'Summary',
    ],
    WRITING: ['Personal', 'Academic'],
  };

  HEADER_CHARACTER_LIMITS = {
    HEADER_MOBILE_OR_TABLET_DEVICE: 18,
    HEADER_DESKTOP_DEVICE: 100,
  };

  REPORT_AN_ERROR_MODAL = {
    TITLE: 'How can we improve?',
  };

  WRITING__MEAN_SCORE = {
    '5.00': { scale_score: 30 },
    '4.75': { scale_score: 29 },
    '4.50': { scale_score: 28 },
    '4.25': { scale_score: 27 },
    '4.00': { scale_score: 25 },
    '3.75': { scale_score: 24 },
    '3.50': { scale_score: 22 },
    '3.25': { scale_score: 21 },
    '3.00': { scale_score: 20 },
    '2.75': { scale_score: 18 },
    '2.50': { scale_score: 17 },
    '2.25': { scale_score: 15 },
    '2.00': { scale_score: 14 },
    '1.75': { scale_score: 12 },
    '1.50': { scale_score: 11 },
    '1.25': { scale_score: 10 },
    '1.00': { scale_score: 8 },
    '0.75': { scale_score: 7 },
    '0.50': { scale_score: 5 },
    '0.25': { scale_score: 4 },
    '0.00': { scale_score: 0 },
  };
  SPEAKING_MEAN_SCORE = {
    '4.00': { scale_score: 30 },
    '3.83': { scale_score: 29 },
    '3.66': { scale_score: 28 },
    '3.50': { scale_score: 27 },
    '3.33': { scale_score: 26 },
    '3.16': { scale_score: 24 },
    '3.00': { scale_score: 23 },
    '2.83': { scale_score: 22 },
    '2.66': { scale_score: 20 },
    '2.50': { scale_score: 19 },
    '2.33': { scale_score: 18 },
    '2.16': { scale_score: 17 },
    '2.00': { scale_score: 15 },
    '1.83': { scale_score: 14 },
    '1.66': { scale_score: 13 },
    '1.50': { scale_score: 11 },
    '1.33': { scale_score: 10 },
    '1.16': { scale_score: 9 },
    '1.00': { scale_score: 8 },
    '0.83': { scale_score: 6 },
    '0.66': { scale_score: 5 },
    '0.50': { scale_score: 4 },
    '0.33': { scale_score: 3 },
    '0.16': { scale_score: 1 },
    '0.00': { scale_score: 0 },
  };
}
