/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-component.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../../directives/modal-host.directive";
import * as i5 from "./modal-component.component";
var styles_ModalComponentComponent = [i0.styles];
var RenderType_ModalComponentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponentComponent, data: {} });
export { RenderType_ModalComponentComponent as RenderType_ModalComponentComponent };
function View_ModalComponentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 1, 0, currVal_0); }); }
function View_ModalComponentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ModalComponentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Audio Url"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.resourceInstance.file_url; _ck(_v, 1, 0, currVal_0); }); }
function View_ModalComponentComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Image Url "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.resourceInstance.file_url; _ck(_v, 1, 0, currVal_0); }); }
function View_ModalComponentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponentComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponentComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data.resourceInstance.resource_type == "AUDIO"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.data.resourceInstance.resource_type == "IMAGE"); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ModalComponentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { modalHost: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponentComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-red-filled pull-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i3.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponentComponent_2)), i1.ɵdid(14, 16384, [[1, 4]], 0, i4.ModalHostDirective, [i1.ViewContainerRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponentComponent_3)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.data.resourceInstance; _ck(_v, 16, 0, currVal_1); }, null); }
export function View_ModalComponentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-component", [], null, null, null, View_ModalComponentComponent_0, RenderType_ModalComponentComponent)), i1.ɵdid(1, 114688, null, 0, i5.ModalComponentComponent, [i3.MatDialogRef, i1.ComponentFactoryResolver, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalComponentComponentNgFactory = i1.ɵccf("app-modal-component", i5.ModalComponentComponent, View_ModalComponentComponent_Host_0, {}, {}, []);
export { ModalComponentComponentNgFactory as ModalComponentComponentNgFactory };
