import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

/**
 *
 *
 * @export
 * @class RefreshTokenInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  /**
   *
   *
   * @private
   * @memberof RefreshTokenInterceptor
   */
  private refreshTokenInProgress = false;
  /**
   *
   *
   * @private
   * @type {BehaviorSubject<any>}
   * @memberof RefreshTokenInterceptor
   */
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   *Creates an instance of RefreshTokenInterceptor.
   * @param {AuthService} auth
   * @memberof RefreshTokenInterceptor
   */
  constructor(public auth: AuthService) {}

  /**
   *
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof RefreshTokenInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (request.url.includes('refreshtoken') || request.url.includes('login')) {
          if (request.url.includes('refreshtoken')) {
            this.auth.logout();
          }
          return observableThrowError(error);
        }

        if (error.status !== 401) {
          return observableThrowError(error);
        }

        if (this.refreshTokenInProgress) {
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthenticationToken(request)))
          );
        } else {
          this.refreshTokenInProgress = true;
          this.refreshTokenSubject.next(null);
          return this.auth.refreshAccessToken().pipe(
            switchMap((token: any) => {
              this.auth.updateAccessTokenDetails(token);
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(token);
              return next.handle(this.addAuthenticationToken(request));
            }),
            catchError((err: any) => {
              this.refreshTokenInProgress = false;
              this.auth.logout();
              return observableThrowError(error);
            })
          );
        }
      })
    );
  }

  /**
   *
   *
   * @param {*} request
   * @returns
   * @memberof RefreshTokenInterceptor
   */
  addAuthenticationToken(request) {
    const accessToken = this.auth.getAccessToken();

    if (!accessToken) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.auth.getAccessToken(),
      },
    });
  }
}
