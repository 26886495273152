import * as i0 from "@angular/core";
/**
 *
 *
 * @export
 * @class JQueryUtil
 */
export class JQueryUtil {
    /**
     *
     *
     * @memberof JQueryUtil
     */
    changeFooterPosition() {
        if ($('.footer').length) {
            if ($(window).height() > $('html').height()) {
                $('.footer').css('position', 'fixed');
            }
            else {
                $('.footer').css('position', 'relative');
            }
        }
    }
    /**
     *
     *
     * @memberof JQueryUtil
     */
    showOrHideGotoTopButton() {
        try {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                document.getElementById('myBtn').style.display = 'block';
            }
            else {
                document.getElementById('myBtn').style.display = 'none';
            }
        }
        catch (e) { }
    }
    /**
     *
     *
     * @memberof JQueryUtil
     */
    runRoutineMethods() {
        this.changeFooterPosition();
    }
    bindEvents() {
        $(document).resize(() => {
            this.changeFooterPosition();
        });
        $(document).scroll(() => {
            this.showOrHideGotoTopButton();
        });
    }
}
JQueryUtil.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JQueryUtil_Factory() { return new JQueryUtil(); }, token: JQueryUtil, providedIn: "root" });
