import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { GlobalDataService } from '../../../../../../libs/features/assessment/global-data.service';

/**
 * @author "Dilipen. D <dilipen@tivonaglobal.com>"
 * @description Base modal for all modals
 * @export
 * @class MyModalComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-my-modal',
  templateUrl: './my-modal.component.html',
  styleUrls: ['./my-modal.component.scss'],
})
export class MyModalComponent implements OnInit {
  /**
   * @description Controls whether a click event outside the modal should close the modal dialog.
   * @var closeOnOutSideClick
   * @type {Boolean}
   * @memberof MyModalComponent
   */
  @Input() closeOnOutSideClick: Boolean = true;

  /**
   * @description Controls whether the modal dialog is shown/hidden
   * @var showModal
   * @type {Boolean}
   * @memberof MyModalComponent
   */

  private _showModal = false;
  public get showModal() {
    return this._showModal;
  }

  @Input()
  public set showModal(value) {
    this._showModal = value;
    this.showModalChange.emit(this._showModal);
  }
  @Output() showModalChange = new EventEmitter<boolean>();

  /**
   * @description Extra CSS classes to be attached to the modal element. Optional.
   * @var extraClasses
   * @type {String}
   * @memberof MyModalComponent
   */
  @Input() extraClasses: String = null;

  /**
   * @description Publish action data to subscriber on close of the modal dialog.
   * @var closeEmitter
   * @type {EventEmitter < ModalResult >}
   * @memberof MyModalComponent
   */
  @Output() closeEmitter: EventEmitter<ModalResult> = new EventEmitter<ModalResult>();

  /**
   *Creates an instance of MyModalComponent.
   * @memberof MyModalComponent
   */
  constructor(private _global: GlobalDataService) {}

  /**
   * @description Initialize component variables/methods
   * @method ngOnInit
   * @memberof MyModalComponent
   */
  ngOnInit() {
    // this.loadedEmitter.next(this);
  }

  /**
   * @description Force modal to show
   * @method showAction
   * @memberof MyModalComponent
   */
  showAction() {
    this.showModal = true;
  }

  /**
   * @description Force modal to hide
   * @method closeAction
   * @memberof MyModalComponent
   */
  closeAction() {
    this.showModal = false;
    this.closeEmitter.next({
      action: ModalAction.CLOSE,
    });
  }

  /**
   * @description Force modal to close and publish `ModalAction.POSITIVE` data to subscribers.
   * Useful to handle buttons such as 'Yes'.
   * @method positiveAction
   * @memberof MyModalComponent
   */
  positiveAction() {
    this.showModal = false;
    this.closeEmitter.next({
      action: ModalAction.POSITIVE,
    });
  }

  /**
   * @description Force modal to close and publish `ModalAction.NEGATIVE` data to subscribers.
   * Useful to handle buttons such as 'No'.
   * @method negativeAction
   * @memberof MyModalComponent
   */
  negativeAction() {
    this.showModal = false;
    this.closeEmitter.next({
      action: ModalAction.NEGATIVE,
    });
  }

  /**
   * @description Force modal to close on outside click if the variable `closeOnOutSideClick` is true
   * @method closeClickOnOutSide
   * @param {*} event
   * @memberof MyModalComponent
   */
  closeClickOnOutSide(event) {
    const parentNode = this._global.getParentNode(event);
    if (parentNode && parentNode.tagName === 'APP-MY-MODAL' && this.closeOnOutSideClick === true) {
      this.closeAction();
    }
  }

  /**
   * @description Force modal to close by clicking escape button if the variable `closeOnOutSideClick` is true
   * @method escapeKeyboardEvent
   * @param {KeyboardEvent} event
   * @memberof MyModalComponent
  /**
   *
   *
   * @param event
   * @memberof MyModalComponent
   */
  @HostListener('document:keydown.escape', ['$event'])
  escapeKeyboardEvent(event: KeyboardEvent) {
    if (this.closeOnOutSideClick === true) {
      this.closeAction();
    }
  }
}

/**
 * @description Enum values
 * @enum ModalAction
 * @export
 * @enum {number}
 */
export enum ModalAction {
  CLOSE,
  POSITIVE,
  NEGATIVE,
}

/**
 * @description Modal result
 * @interface ModalResult
 * @export
 * @interface ModalResult
 */
export interface ModalResult {
  action: ModalAction;
}
