import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule, MatSnackBarModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimeAgoPipe } from 'time-ago-pipe';
import { MaterialModule } from '@entrayn/core/material.module';

import { SHARED_COMPONENTS } from './components';
import { DialogBodyComponent } from './components/dialog-body/dialog-body.component';
import { ExpandablePassageComponent } from './components/expandable-passage/expandable-passage.component';
import { FooterComponent } from './components/footer/footer.component';
import { ModalComponentComponent } from './components/modal-component/modal-component.component';
import { MyModalComponent } from './components/my-modal/my-modal.component';
import { NavQuestPrevNextComponent } from './components/nav-quest-prev-next/nav-quest-prev-next.component';
import { ToeflPassageComponent } from './components/toefl-passage/toefl-passage.component';
import { ModalHostDirective } from './directives/modal-host.directive';
import { FilterInRcFillTablePipe } from './pipes/filter-in-rc-fill-table.pipe';
import { FilterSectionElementsPipe } from './pipes/filter-section-elements.pipe';
import { FilterSectionQuestionPipe } from './pipes/filter-section-question.pipe';
import { GetInstructionPipe } from './pipes/get-instruction.pipe';
import { GetUserResponsePipe } from './pipes/get-user-response.pipe';
import { HighlightTextPipe } from './pipes/highlight-text.pipe';
import { IndexToCharPipe } from './pipes/index-to-char.pipe';
import { InsertSentenceTextPipe } from './pipes/insert-sentence-text.pipe';
import { InsertSentencePipe } from './pipes/insert-sentence.pipe';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { LengthPipe } from './pipes/length.pipe';
import { MinuteSecondsFormatPipe } from './pipes/minute-seconds-format.pipe';
import { ObjectLengthPipe } from './pipes/object-length.pipe';
import { ParagraphIndexPipe } from './pipes/paragraph-index.pipe';
import { ParagraphMarkerPipe } from './pipes/paragraph-marker.pipe';
import { PrettyPrintPipe } from './pipes/pretty-print.pipe';
import { RefreshUrlPipe } from './pipes/refresh-url.pipe';
import { SecondsToFormatTimePipe } from './pipes/seconds-to-format-time.pipe';
import { SecondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { UuidExistingPipe } from './pipes/uuid-existing.pipe';
import { VsprintfPipe } from './pipes/vsprintf.pipe';
import { TooltipComponent } from './tooltip/tooltip.component';

const DECLARE_EXPORTS = [
  FooterComponent,
  TimeAgoPipe,
  // TODO move all these pipes to libs
  SecondsToFormatTimePipe,
  PrettyPrintPipe,
  RefreshUrlPipe,
  UuidExistingPipe,
  VsprintfPipe,
  HighlightTextPipe,
  ParagraphIndexPipe,
  ParagraphMarkerPipe,
  ParagraphIndexPipe,
  GetInstructionPipe,
  GetUserResponsePipe,
  FilterSectionElementsPipe,

  ObjectLengthPipe,

  MinuteSecondsFormatPipe,
  SecondsToTimePipe,

  IndexToCharPipe,
  InsertSentencePipe,
  InsertSentenceTextPipe,
  LengthPipe,
  EscapeHtmlPipe,
  FilterInRcFillTablePipe,
  FilterSectionQuestionPipe,

  TooltipComponent,
  ...SHARED_COMPONENTS,
  // The following are used in both attempt and review
  NavQuestPrevNextComponent,
  MyModalComponent,
  ExpandablePassageComponent,
  ToeflPassageComponent,

  // The resource render component has been moved to the review question types
  // module which is imported into both the review and authoring tools modules.
  // (This is so that plyr and the media modules load only when necessary)
  ModalComponentComponent,
  DialogBodyComponent,
  ModalHostDirective,
];

@NgModule({
  declarations: DECLARE_EXPORTS,
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    MatDialogModule,
    MatSnackBarModule,
    MaterialModule,
  ],
  exports: [...DECLARE_EXPORTS, NgbModule],
  providers: [RefreshUrlPipe],
  entryComponents: [ToeflPassageComponent, ModalComponentComponent, DialogBodyComponent],
})
export class SharedModule {}
