import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../../../../../../libs/core/services/config';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetDocService {
  googleScriptURL = this._constants.GOOGLE_SCRIPT_URL;
  docURL: string;
  fileID: string;
  getDocSubject: Subject<any> = new Subject();

  constructor(private _http: HttpClient, private _constants: Constants) {}

  saveURL(urlInput) {
    this.docURL = urlInput;
  }

  getData() {
    this.fileID = new RegExp('/document/d/([a-zA-Z0-9-_]+)').exec(this.docURL)[1];
    const getURL = this.googleScriptURL + '?fileID=' + this.fileID;
    this._http.get(getURL).subscribe(data => this.getDocSubject.next(data));
  }
}
