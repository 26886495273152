import { Pipe, PipeTransform } from '@angular/core';

declare var $;

@Pipe({
  name: 'highlightText',
})
export class HighlightTextPipe implements PipeTransform {
  transform(html_string: any, highlight_position_id: any): any {
    const $html = $('<span>' + html_string + '</span>');
    if (highlight_position_id) {
      if ($('#' + highlight_position_id, $html).length) {
        $('#' + highlight_position_id, $html).html(
          '<b class="bg-orange passage-highlight text-white">' +
            $('#' + highlight_position_id, $html)[0].innerHTML +
            '</b>'
        );
      }
    }
    return $html.html();
  }
}
