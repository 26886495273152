import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@entrayn/core/material.module';
import { UISharedModule } from '@entrayn/features';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';

import { SharedModule } from '../shared/shared.module';
import { UI_COMPONENTS } from './components';
import { UI_PROVIDERS } from './services';

// libs
const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  UISharedModule,
  MaterialModule,
  NgbModule,
  SharedModule,
];

@NgModule({
  imports: [...MODULES, AgGridModule.withComponents([])],
  declarations: [...UI_COMPONENTS],
  exports: [...MODULES, ...UI_COMPONENTS],
  providers: [...UI_PROVIDERS],
  entryComponents: [...UI_COMPONENTS],
})
export class UIModule {}
