import { Component, OnInit } from '@angular/core';

declare var $;
/**
 *
 *
 * @export
 * @class GoToTopComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-go-to-top',
  templateUrl: './go-to-top.component.html',
  styleUrls: ['./go-to-top.component.scss'],
})
export class GoToTopComponent implements OnInit {
  /**
   *Creates an instance of GoToTopComponent.
   * @memberof GoToTopComponent
   */
  constructor() {}

  /**
   *
   *
   * @memberof GoToTopComponent
   */
  ngOnInit() {}

  /**
   *
   *
   * @memberof GoToTopComponent
   */
  gotoTopPage() {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  }
}
