import { Component, Input, OnInit } from '@angular/core';

import { Constants } from '@entrayn/core/services/config';

@Component({
  selector: 'app-enroll-placeholder',
  templateUrl: './enroll-placeholder.component.html',
  styleUrls: ['./enroll-placeholder.component.scss'],
})
export class EnrollPlaceholderComponent implements OnInit {
  @Input() linkText = 'Enroll Today';
  @Input() linkHref = this._constants.PRODUCT_URL.TOEFL;
  @Input() message = '';
  constructor(private _constants: Constants) {}

  ngOnInit() {}
}
