/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/common";
import * as i10 from "./header.component";
import * as i11 from "../../../../../../libs/core/services/config";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "span", [["class", "title"], ["matTooltipClass", "sim-title-tooltip"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"], tooltipClass: [1, "tooltipClass"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.simTitle; var currVal_1 = "sim-title-tooltip"; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.simTitleTransform; _ck(_v, 2, 0, currVal_2); }); }
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.simTitleTransform; _ck(_v, 1, 0, currVal_0); }); }
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "col-12 header-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "float-left svg-entrayn-previous"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.simTitle.length > _co.characterLimitForMobileDevices); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.simTitle.length <= _co.characterLimitForMobileDevices); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.homeUrl; _ck(_v, 1, 0, currVal_0); }); }
function View_HeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "/assets/static/images/logo.png"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.homeUrl; _ck(_v, 1, 0, currVal_0); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i9.ɵNgClassImpl, i9.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i9.NgClass, [i9.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["logo", 2]], null, 0, null, View_HeaderComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.fullWidth ? "container-fluid" : "container"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.simTitle; var currVal_2 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i10.HeaderComponent, [i11.Constants], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i10.HeaderComponent, View_HeaderComponent_Host_0, { simTitle: "simTitle", fullWidth: "fullWidth", homeUrl: "homeUrl" }, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
