import { Component } from '@angular/core';
import { BaseComponent } from '@entrayn/core';
import { Constants } from '../../../../../../libs/core/services/config';

import { ApiService } from '../../../../../../libs/core/services/api.service';

@Component({
  selector: 'app-marketing-banner',
  templateUrl: './marketing-banner.component.html',
  styleUrls: ['./marketing-banner.component.scss'],
})
export class MarketingBannerComponent extends BaseComponent {
  marketingDataObject;
  constructor(private _constants: Constants, private _api: ApiService) {
    super();
  }

  ngOnInit() {
    this.getEtagForMarketingData();
  }

  getEtagForMarketingData() {
    this._api.getEtagOfDataFromS3(this._constants.MARKETING_DATA_S3URL).subscribe(resp => {
      // If content in the sheet has changed then get the new data
      if (JSON.parse(localStorage.getItem('marketingEtag')) !== resp.headers.get('etag')) {
        this.getMarketingTestData();
      }
      // If content in the sheet has not changed then get the data from local storage
      else {
        this.marketingDataObject = JSON.parse(localStorage.getItem('marketingTestScriptData'));
      }
    });
  }

  getMarketingTestData() {
    this._api.getDataFromSheets(this._constants.MARKETING_DATA_S3URL).subscribe(data => {
      this.marketingDataObject = data['body']['marketingTest']['toefl'][0];
      // Store etag and data in localstorage
      localStorage.setItem('marketingTestScriptData', JSON.stringify(this.marketingDataObject));
      localStorage.setItem('marketingEtag', JSON.stringify(data.headers.get('etag')));
    });
  }
}
