import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../../../libs/core/services/api.service';

@Pipe({
  name: 'getUserResponse',
})
export class GetUserResponsePipe implements PipeTransform {
  constructor(private apiService: ApiService) {}
  transform(questionResponseUuid: any, args?: any): any {
    if (questionResponseUuid) {
      return this.apiService.getUserAudioResponse(questionResponseUuid).pipe(
        map(res => {
          return res['user_question_response_file_url'];
        })
      );
    }
    return null;
  }
}
