import { Router } from '@angular/router';
import { GlobalDataService } from '@entrayn/features/assessment/global-data.service';
import { AuthService } from '@entrayn/web/features/auth/services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
import * as i3 from "../../../../../libs/features/assessment/global-data.service";
/**
 *
 *
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
export class AuthGuard {
    /**
     *Creates an instance of AuthGuard.
     * @param {AuthService} authService
     * @param {Router} _router
     * @memberof AuthGuard
     */
    constructor(authService, _router, _global) {
        this.authService = authService;
        this._router = _router;
        this._global = _global;
    }
    /**
     * If we're redirecting to the drupal login page, don't also redirect with the angular router.
     */
    redirectToLogin() {
        return this._global.isRedirectingToDrupalLogin() ? false : this._router.parseUrl('/login');
    }
    /**
     *
     *
     * @param {ActivatedRouteSnapshot} next
     * @param {RouterStateSnapshot} state
     * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
     * @memberof AuthGuard
     */
    canActivate(next, state) {
        if (!this.authService.loggedIn()) {
            return this.redirectToLogin();
        }
        return true;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.GlobalDataService)); }, token: AuthGuard, providedIn: "root" });
