import { environment } from '../../../apps/web-cux-entrayn/src/environments/environment';
import * as i0 from "@angular/core";
export class Constants {
    constructor() {
        this.SITE_URL = environment.SITE_URL;
        this.DRUPAL_BASE_URL = environment.DRUPAL_BASE_URL;
        this.DRUPAL_HOST = 'entrayn.com'; // For checking whether we're on a staging/live deployed front end
        // Temporarily allow anyone to manually go to the angular login page
        this.ALLOW_LOGIN = true; // !environment.production; // So that it's still possible to go to the login page manually on dev/staging
        this.DRUPAL_URL = {
            HOME: `${this.DRUPAL_BASE_URL}/home`,
            LOGIN: `${this.DRUPAL_BASE_URL}/galvanize/login`,
        };
        this.PRODUCT_URL = {
            TOEFL: 'https://galvanizetestprep.com',
        };
        this.ENTITLEMENTS_URL = '/api/user-subscriptions';
        this.COOKIE_EXPIRATION_PERIOD = 15; // in days
        this.REQUEST_TIMEOUT = 45000; // Originally 25 second timeout on the frontend
        this.ASSESSMENT_COMPLETED = 'assessment_completed';
        this.ENTITLEMENTS_FETCH_COMPLETED = 'entitlements_fetch_completed';
        this.GOOGLE_SCRIPT_URL = 'https://script.google.com/macros/s/AKfycbzxzhySK60I8mm5Hj-DOTXoLZKqFCSf7qlQUBXTTqp1dXTQzpi0/exec';
        this.ASSESSMENT_INSTANCE_URL = '/api/assessments';
        this.ASSESSMENT_INTERACTIONS = 'interactions';
        this.END_TEST_URL = 'end-test';
        this.ASSESSMENT_INTERACTION_TYPE = 'AI';
        this.ASSESSMENT_END_TRIGGER = 'assessment_end';
        this.ASSESSMENT_MODE = 'ATTEMPT';
        this.EVALUATE_URL = 'get';
        this.FILE_RESOURCE_URL = '/api/file-resources';
        this.GET_ROOTS_URL = '/api/concepts';
        this.POST_RESOURCE_URL = '/api/resources';
        this.POST_QUESTION_URL = '/api/questions';
        this.POST_QUESTION_SET_URL = '/api/qsets';
        this.POST_QUESTION_SET_ELEMENT_URL = '/api/qset-elements';
        this.POST_QUESTION_RESOURCE_URL = '/api/question-resource/';
        this.POST_ASSESSMENT_TEMPLATE_URL = '/api/assessment-templates';
        this.MARKETING_TEST_URL = `${this.ASSESSMENT_INSTANCE_URL}/marketing-test?concept=TOEFL`;
        this.MARKETING_ROUTE_URL = 'toefl';
        this.POST_SECTIONS = 'sections';
        this.POST_ELEMENTS = 'elements';
        this.POST_QUESTIONS_META = '/api/question-templates';
        this.POST_INDEPENDENT_RESOURCE_META = '/api/resource-templates';
        this.POST_QUESTION_INTERACTIONS_URL = '/api/question-responses';
        this.QUESTION_INSTANCE_URL = '/api/question-instances';
        this.INSTRUCTOR_FEEDBACK = {
            PATCH_SCORE_URL: (questionInstanceUuid) => `${this.QUESTION_INSTANCE_URL}/${questionInstanceUuid}/subjective-insights/score`,
            PATCH_FEEDBACK_URL: (questionInstanceUuid) => `${this.QUESTION_INSTANCE_URL}/${questionInstanceUuid}/subjective-insights/feedback`,
        };
        this.LOGIN_URL = '/login';
        this.REFRESH_TOKEN_URL = '/token/refresh';
        this.DISCUSSIONS = {
            URLS: {
                // To fetch a thread for a question:
                GET_THREAD: (threadUuid) => `/api/discussions/${threadUuid}`,
                // To create comments:
                COMMENTS: '/api/discussion-comments',
                // To patch is_clarified:
                PATCH_THREAD: (threadUuid) => `/api/discussions/${threadUuid}`,
            },
            USER_TYPE: {
                LEARNER: 'Learner',
                INSTRUCTOR: 'Instructor',
            },
        };
        // TODO(praveena): Update the url
        this.RESOURCE_SET_URL = '/api/file-resource-set';
        this.VIDEO_RESOURCES = {
            VIDEO: 'VIDEO',
            THUMBNAIL: 'THUMBNAIL',
            TRANSCRIPT: 'TRANSCRIPT',
        };
        this.CONFIGURABLE_MESSAGE_VARIABLES = {
            FNAME: 'Fname',
            SIM_TITLE: 'SimTitle',
        };
        this.PREVIEW_MODE = 'previewMode';
        this.REVIEW_MODE = 'reviewMode';
        this.ATTEMPT_MODE = 'attemptMode';
        this.NAVIGATION_RANDOM = 'RANDOM';
        this.NAVIGATION_SEQUENTIAL = 'SEQUENTIAL';
        this.TABLE_KEY_AUDIO_UUID = 'AUDIO_UUID';
        this.TABLE_KEY_IMAGE_UUID = 'IMAGE_UUID';
        this.TABLE_KEY_PASSAGE_UUID = 'PASS_UUID';
        this.TABLE_KEY_QUESTION_UUID = 'Q_UUID';
        this.TOEFL_EXAM_NAMES = ['TOEFL', 'GRE', 'SAT', 'VERBAL_STRENGTHENING'];
        this.SCORING_METHOD_CHOICES = ['GRE_SCALED_SCORE', 'TOEFL_SCALED_SCORE'];
        this.SCORING_METHOD_SCALE = {
            TOEFL_SCALED_SCORE: 30,
            TOEFL_READING_SCALED_SCORE: 30,
            TOEFL_LISTENING_SCALED_SCORE: 30,
            TOEFL_SPEAKING_SCALED_SCORE: 30,
            TOEFL_WRITING_SCALED_SCORE: 30,
        };
        this.UUID_LENGTH = 36;
        this.ASSESSMENT_MODES = {
            ATTEMPT: 'ATTEMPT',
            REVIEW: 'REVIEW',
        };
        this.START_TRIGGER_TYPES = {
            MANUAL: 'MANUAL',
            AUTOMATIC: 'AUTOMATIC',
        };
        this.INDEPENDENT_RESOURCE_TYPES = ['PASSAGE', 'AUDIO'];
        this.INTERACTION_TYPES = {
            USER_QUESTION_RESPONSE: 'user_question_response',
            ASSESSMENT: 'AI',
            SECTION: 'SI',
            ELEMENT: 'EI',
            INDEPENDENT_RESOURCE: 'IR',
        };
        this.INTERACTION_TRIGGER_TYPES = {
            ASSESSMENT_END: 'assessment_end',
            ASSESSMENT_START: 'assessment_start',
            ASSESSMENT_RESUME: 'assessment_resume',
            ASSESSMENT_INSTRUCTIONS_START: 'assessment_instructions_start',
            ASSESSMENT_INSTRUCTIONS_RESUME: 'assessment_instructions_resume',
            ASSESSMENT_INSTRUCTIONS_END: 'assessment_instructions_end',
            SECTION_INSTRUCTIONS_START: 'section_instructions_start',
            SECTION_INSTRUCTIONS_RESUME: 'section_instructions_resume',
            SECTION_INSTRUCTIONS_END: 'section_instructions_end',
            SECTION_START: 'section_start',
            SECTION_RESUME: 'section_resume',
            SECTION_END: 'section_end',
            SECTION_ELEMENT_START: 'section_element_start',
            SECTION_ELEMENT_RESUME: 'section_element_resume',
            SECTION_ELEMENT_END: 'section_element_end',
        };
        this.INDEPENDENT_RESOURCE_TRIGGER = {
            START: 'resource_start',
            END: 'resource_end',
        };
        this.AUDIO_RECORDER_STATUS = {
            COMPLETED: 'completed',
            SAVED: 'saved',
        };
        this.USER_QUESTION_INTERACTION = {
            PREPARATION_STARTED_AT: 'preparation_started_at',
            RECORDING_STARTED_AT: 'recording_started_at',
            RECORDING_ENDED_AT: 'recording_ended_at',
        };
        this.QUESTION_STATUS = {
            SEEN_ANSWERED: 'SeenAnswered',
            SEEN_NOT_ANSWERED: 'SeenNotAnswered',
            NOT_SEEN_NOT_ANSWERED: 'NotSeenNotAnswered',
            CORRECT: 'CORRECT',
            INCORRECT: 'INCORRECT',
            PARTIAL_CORRECT: 'PARTIAL_CORRECT',
        };
        this.INSTANCE_TYPES = {
            ASSESSMENT: 'assessmentinstance',
        };
        this.ENTITLEMENTS = {
            // Used to be SPEAKING and WRITING, changed to a general subjective insights entitlement
            SCORE_RETRIEVE: 'subjective-insights-score-retrieve',
            FEEDBACK_RETRIEVE: 'subjective-insights-feedback-retrieve',
            // TODO: there are no objective insights in allowed-actions, so the
            // entitlement check (in user-entitlement.service) is just returning true
            // for READING and LISTENING
            // READING: 'assessment-reading',
            // LISTENING: 'assessment-listening',
            // Other discussions entitlements: "discussion-comments-create", "discussions-get_thread_with_question_instance", "discussions-partial_update", "discussions-update"
            DISCUSSION_COMMENTS_CREATE: 'discussion-comments-create',
            SUBJECTIVE_INSIGHTS_SCORE: 'subjective-insights-score',
            SUBJECTIVE_INSIGHTS_FEEDBACK: 'subjective-insights-feedback',
            DISCUSSION_THREAD_RETRIEVE: 'discussions-retrieve',
            DISCUSSION_THREAD_UPDATE: 'discussions-update',
        };
        this.LC_ORDER_IN_SEQUENCE_ERROR_DISPLAY_TIME = 4;
        this.LEARNING_STAGES = {
            MOCK_TEST: 'MOCK_TEST',
            PRE_TEST: 'PRE_TEST',
            UNTIMED_TEST: 'UNTIMED_TEST',
            HANGMAN: 'HANGMAN',
            PRACTICE_TEST: 'PRACTICE_TEST',
            MARKETING_TEST: 'MARK',
            DIAGNOSTIC_TEST: 'DIAG',
        };
        this.QUESTION_SELECTION_MODE = [
            { value: 'STATIC', viewValue: 'Static' },
            { value: 'DYNAMIC', viewValue: 'Dynamic' },
            { value: '', viewValue: 'None' },
            { value: 'ADAPTIVE', viewValue: 'Adaptive' },
        ];
        this.BOOLEAN_VALUES = [true, false];
        this.TRIGGER_VALUES = [
            { value: 'MANUAL', viewValue: 'Manual' },
            { value: 'AUTOMATIC', viewValue: 'Automatic' },
            { value: '', viewValue: 'None' },
        ];
        this.TIMING_MODES = [
            { value: 'TIMED', viewValue: 'TIMED' },
            { value: 'UNTIMED', viewValue: 'UNTIMED' },
            { value: '', viewValue: 'None' },
        ];
        this.EVALUATION_MODES = [
            { value: 'SYNCHRONOUS', viewValue: 'Synchronous' },
            { value: 'ASYNCHRONOUS', viewValue: 'Asynchronous' },
            { value: '', viewValue: 'None' },
        ];
        this.REVIEW_MODES = ['REVIEW_ALLOWED', 'REVIEW_NOT_ALLOWED'];
        this.TRY_AGAIN_MODES = ['TRY_AGAIN_NOT_ALLOWED', 'TRY_AGAIN_ALLOWED'];
        this.DYNAMIC_QUESTION_SELECTION_MODES = [
            { value: 'RAND', viewValue: 'RANDOM' },
            { value: '', viewValue: 'None' },
        ];
        this.ATTEMPT_CHILDREN_MODES = ['SEQUENTIAL', 'RANDOM'];
        this.RC_TYPES = ['RCMCQ', 'RCMAQ', 'RC_SUMMARY', 'RC_INSERT_SENTENCE', 'RC_FILL_TABLE'];
        this.LC_TYPES = ['LCMCQ', 'LCMAQ', 'LCMCQ_WITH_PLAYBACK', 'LC_FILL_TABLE', 'LC_ORDER_IN_SEQUENCE'];
        this.WRITING_TYPES = ['WRITING_INDEPENDENT', 'WRITING_INTEGRATED'];
        this.SPEAKING_TYPES = [
            'SPEAKING_INDEPENDENT',
            'SPEAKING_INTEGRATED',
            'SPEAKING_INTEGRATED_AUDIO_ONLY',
        ];
        this.CONCEPT_UUID_DICT = {
            READING: 'ac711a86-de89-4c2b-a571-774f1e227574',
            LISTENING: 'd01dae1c-db75-446e-8227-2999b94379e5',
            WRITING: '2615b21f-f826-4fef-9fe7-b653d4b23600',
            SPEAKING: 'e3c0ea14-9a16-44e0-a16a-025c1cc52725',
            TOEFL: '77fbc378-9369-4160-a586-104b3b9f9410',
            EXPOSITION: '43fe43f0-0151-4d8d-9391-a4129d908019',
            DESCRIPTIVE: '3e156f63-a573-4f79-9028-8d31342baf58',
            ARGUMENTATION: 'c2ce2a72-1058-4c73-84c9-3b3e9b52b69a',
            CONVERSATION: '3380a03c-062d-4573-8c5a-86c7cafd4957',
            LECTURE: 'ce430613-cfca-4582-9e52-11d37b2e3d9f',
            WRITING_INDEPENDENT: 'a1dd3447-a53d-4283-9e1a-701659384c89',
            WRITING_INTEGRATED: '7fe40a80-98be-461e-8ecb-308db7eba39f',
            SPEAKING_INDEPENDENT: '0c72c23a-61e3-4553-bd16-1f9d6ef16619',
            INTEGRATED_CONVERSATION: 'dfed6f4d-58a7-4eeb-8ed6-6df55c46550f',
            INTEGRATED_LECTURE: 'dd63f7b9-ab09-44b3-83d8-ea59ee387468',
            INTEGRATED_NOTICE_CONVERSATION: '8d189c33-b278-405c-9876-b2d6327ff120',
            INTEGRATED_PASSAGE_LECTURE: 'ff5823e9-07b5-4719-b582-4340e8e34e81',
        };
        this.ASSESSMENT_FLAVOURS = {
            TOEFL_DIAGNOSTIC_TEST: 'TOEFL Diagnostic Test',
        };
        this.ASSESSMENT_FLAVOUR_CHOICES = [
            'TOEFL Mock Test',
            'TOEFL Reading Section Test',
            'TOEFL Listening Section Test',
            'TOEFL Writing Section Test',
            'TOEFL Speaking Section Test',
            'TOEFL Reading Timed Test',
            'TOEFL Listening Timed Test',
            'TOEFL Writing Timed Test',
            'TOEFL Speaking Timed Test',
            'TOEFL Reading Untimed Test',
            'TOEFL Listening Untimed Test',
            'TOEFL Writing Untimed Test',
            'TOEFL Diagnostic Test',
            'TOEFL Marketing Test',
        ];
        this.GRADING_STATUS = {
            WILL_BE_GRADED: 'WILL_BE_GRADED',
        };
        this.GRADING_TYPE = {
            MANUAL_GRADING: 'MANUAL_GRADING',
            AUTOMATIC_GRADING: 'AUTOMATIC_GRADING',
        };
        this.defaultValueForQuestionMeta = {
            timing: {},
            navigation: {},
            evaluation: {},
            score: {},
            resource_type: null,
        };
        this.URL_CONFIG_TO_SWITCH_ENVIRONMENT = {
            production: 'http://api.entrayn.com',
            staging: 'http://thor.entrayn.com',
        };
        this.INTERNAL_USERS_LOGIN_IDENTIFIER = 'internaluser';
        this.PRESETS_S3URL = 'https://s3-ap-southeast-1.amazonaws.com/entrayn-public/UniversityAppListing/dev/assessmentPresetData.json';
        this.MARKETING_DATA_S3URL = environment.MARKETING_DATA_S3URL;
        this.URL_TO_CHECK_NETWORK_AVAILABILITY = 'https://s3-ap-southeast-1.amazonaws.com/entrayn-public/connection_test';
        this.TIMING_MODE = 'timed';
        this.COPY_TEXT = {
            MICROPHONE: {
                text: "Why doesn't my microphone work?",
                information: [
                    {
                        problem: 'Microphone is not connected or not available',
                        reason: `It’s possible that (1) your mic is just not connected to your computer, (2) plug is not fully inserted into the connector, or (3) your headphone model doesn\'t have a microphone attached to it.`,
                    },
                    {
                        problem: 'Browser’s access is disabled',
                        reason: 'You need to provide your browser with access to the microphone. Please check how to do this online for your browser.',
                    },
                    {
                        problem: 'Microphone is damaged or there’s a hardware problem',
                        reason: 'The microphone may not work if there are defects in the connecting wire or if the audio port/hardware is damaged.',
                    },
                    {
                        problem: 'Audio Driver not installed',
                        reason: 'You may not have the proper audio driver installed in your computer.',
                    },
                    {
                        problem: 'Microphone is disabled in Device Manager',
                        reason: 'The device may be working, but it may have been disabled by system software. ',
                    },
                    {
                        problem: 'Microphone is being used by another application',
                        reason: 'If an application is using your microphone, the device may not be available for other applications.',
                    },
                ],
            },
        };
        this.CATEGORIES = {
            READING: [
                'Factual_information',
                'Negative_factual_information',
                'Reference',
                'Sentence_simplification',
                'Vocabulary',
                'Insert_a_sentence',
                'Prose_summary',
                'Fill_in_the_Table',
                'Inference',
                'Rhetorical_purpose',
            ],
            LISTENING: [
                'Main_idea',
                'Primary_purpose',
                'Detail',
                'Function',
                'Attitude',
                'Organization',
                'Connecting_content',
                'Inference',
            ],
            SPEAKING: [
                'Personal_choice',
                'Paired_choice',
                'Fit_and_explain',
                'General_specific',
                'Problem_solution',
                'Summary',
            ],
            WRITING: ['Personal', 'Academic'],
        };
        this.HEADER_CHARACTER_LIMITS = {
            HEADER_MOBILE_OR_TABLET_DEVICE: 18,
            HEADER_DESKTOP_DEVICE: 100,
        };
        this.REPORT_AN_ERROR_MODAL = {
            TITLE: 'How can we improve?',
        };
        this.WRITING__MEAN_SCORE = {
            '5.00': { scale_score: 30 },
            '4.75': { scale_score: 29 },
            '4.50': { scale_score: 28 },
            '4.25': { scale_score: 27 },
            '4.00': { scale_score: 25 },
            '3.75': { scale_score: 24 },
            '3.50': { scale_score: 22 },
            '3.25': { scale_score: 21 },
            '3.00': { scale_score: 20 },
            '2.75': { scale_score: 18 },
            '2.50': { scale_score: 17 },
            '2.25': { scale_score: 15 },
            '2.00': { scale_score: 14 },
            '1.75': { scale_score: 12 },
            '1.50': { scale_score: 11 },
            '1.25': { scale_score: 10 },
            '1.00': { scale_score: 8 },
            '0.75': { scale_score: 7 },
            '0.50': { scale_score: 5 },
            '0.25': { scale_score: 4 },
            '0.00': { scale_score: 0 },
        };
        this.SPEAKING_MEAN_SCORE = {
            '4.00': { scale_score: 30 },
            '3.83': { scale_score: 29 },
            '3.66': { scale_score: 28 },
            '3.50': { scale_score: 27 },
            '3.33': { scale_score: 26 },
            '3.16': { scale_score: 24 },
            '3.00': { scale_score: 23 },
            '2.83': { scale_score: 22 },
            '2.66': { scale_score: 20 },
            '2.50': { scale_score: 19 },
            '2.33': { scale_score: 18 },
            '2.16': { scale_score: 17 },
            '2.00': { scale_score: 15 },
            '1.83': { scale_score: 14 },
            '1.66': { scale_score: 13 },
            '1.50': { scale_score: 11 },
            '1.33': { scale_score: 10 },
            '1.16': { scale_score: 9 },
            '1.00': { scale_score: 8 },
            '0.83': { scale_score: 6 },
            '0.66': { scale_score: 5 },
            '0.50': { scale_score: 4 },
            '0.33': { scale_score: 3 },
            '0.16': { scale_score: 1 },
            '0.00': { scale_score: 0 },
        };
    }
}
Constants.SENTRY_DSN = environment.SENTRY_DSN;
Constants.ENV = environment.ENV;
Constants.VERSION = `${environment.version}` +
    (environment.branch ? `-${environment.branch}.${environment.revision}` : '');
Constants.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Constants_Factory() { return new Constants(); }, token: Constants, providedIn: "root" });
