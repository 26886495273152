import { format, isValid } from 'date-fns';
import * as i0 from "@angular/core";
export class AcGlobalService {
    constructor() {
        this.userDataTableCoumns = {};
    }
    formatDate(date, formatString) {
        if (date) {
            return isValid(new Date(date))
                ? formatString
                    ? format(new Date(date), formatString)
                    : format(new Date(date), 'MM/DD/YYYY')
                : '-';
        }
        return '-';
    }
    formatTimeStamp(date) {
        const dateObject = new Date(date);
        if (date) {
            return `${format(dateObject, 'DD MMM YYYY')} at ${format(dateObject, 'h:mm A')}`;
        }
        return null;
    }
    formatTime(time) {
        const value = time.split(' ')[1];
        if (value === 'am') {
            return time.split(' ')[0] + ' a.m.';
        }
        return time.split(' ')[0] + ' p.m.';
    }
    formatDateForShortListUniversities(date) {
        if (date && isValid(new Date(date))) {
            return format(new Date(date), 'MMM DD');
        }
        return '-';
    }
    // global function to replace the particular substring of string to given string.
    // multiple substrings can be replaced by sending the parameter as key - value pair
    // eg: { old subtring : new substring}
    replaceContent(content, replacableStrings) {
        const re = new RegExp(Object.keys(replacableStrings).join('|'), 'gi');
        return content.replace(re, function (matched) {
            return replacableStrings[matched];
        });
    }
}
AcGlobalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AcGlobalService_Factory() { return new AcGlobalService(); }, token: AcGlobalService, providedIn: "root" });
export function duration(n) {
    if (n !== 0)
        return Number(n) === 1 ? '1 month' : n + ' months';
    else
        return n;
}
// Added extra for model usage
// TODO:<skandharuban@entrayn.com> Replace all the global function to utils and replace the usage
export function formatDate(date, formatString) {
    if (date) {
        return isValid(new Date(date))
            ? formatString
                ? format(new Date(date), formatString)
                : format(new Date(date), 'DD/MM/YYYY')
            : '-';
    }
    return '-';
}
export function entraynDocumentLinkSplit(url) {
    const documentURLPrefix = 'https://docs.google.com/document/d/';
    const documentDriveURLPrefix = 'https://drive.google.com/a/entrayn.com/file/d/';
    const documentlinkWithoutEntrayn = 'document/d/';
    const documentlinkWithEntrayn = '/open?id=';
    if (url.includes(documentlinkWithEntrayn)) {
        const urlLastPart = url.split(documentlinkWithEntrayn)[1];
        return documentURLPrefix + urlLastPart;
    }
    else if (url.includes(documentDriveURLPrefix)) {
        const urlLastPart = url.split(documentDriveURLPrefix)[1];
        return documentURLPrefix + urlLastPart;
    }
    else if (url.includes(documentlinkWithoutEntrayn)) {
        return url;
    }
    return url;
}
