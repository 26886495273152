import { InternetConnectivityService } from '@entrayn/core/services/internet-connectivity.service';
import * as i0 from "@angular/core";
import * as i1 from "./internet-connectivity.service";
export class RequestErrorService {
    constructor(_internetConnectivity) {
        this._internetConnectivity = _internetConnectivity;
        this._failedRequestCounter = [];
    }
    addErrorCounter(request) {
        const requestExists = this._failedRequestCounter.find(element => {
            return element['request'] == request.request;
        });
        // To not push the same failed request into the counter more than once
        if (!requestExists) {
            this._failedRequestCounter.push(request);
        }
    }
    removeError(request) {
        if (this._failedRequestCounter.length > 0) {
            const errorIndex = this._failedRequestCounter.findIndex(requestObject => {
                return requestObject['request'] === request;
            });
            if (errorIndex !== -1) {
                this._failedRequestCounter.splice(errorIndex, 1);
                this._internetConnectivity.retrySubject.next(this._failedRequestCounter.length === 0);
            }
        }
    }
}
RequestErrorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RequestErrorService_Factory() { return new RequestErrorService(i0.ɵɵinject(i1.InternetConnectivityService)); }, token: RequestErrorService, providedIn: "root" });
