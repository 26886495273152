import { Component, Input, OnInit } from '@angular/core';
import { Constants } from '@entrayn/core/services/config';
import { Title } from '@angular/platform-browser';
import { Config } from '../../../../../../apps/web-cux-entrayn/src/app/ac-servicing/config/config';
import { ViewEditService } from '../../../../../../apps/web-cux-entrayn/src/app/ac-servicing/services/view-edit.service';

// TODO(Fajar K) - Move/ Remove Below Two Imports
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() currentPage: string;
  @Input() userType = 'learner';
  @Input() currentPageAdder: any;
  @Input() fullWidth = false;
  studentName: string;
  currentPageAdderTransform: string;
  public activeCrumbs = [];
  private _breadCrumbsLinks = [
    {
      field: 'Home',
      link: '/',
      parent: null,
    },
    {
      field: 'Admission Counselling',
      link: '/ac-servicing/studentslist',
      parent: 'Home',
    },
    {
      field: 'simTitle',
      parent: 'Home',
    },
    {
      field: 'University Shortlisting',
      link: '/ac-servicing/studentslist',
      parent: 'Admission Counselling',
    },
    {
      field: 'Documentation',
      link: '/ac-servicing/studentslist',
      parent: 'Admission Counselling',
    },

    {
      field: 'All Students',
      link: '/ac-servicing/studentslist',
      parent: 'Admission Counselling',
    },
    {
      field: 'Selected Student',
      link: '/',
      parent: 'All Students',
    },
    {
      field: 'Selected Student Documents',
      link: '/',
      parent: 'Documentation',
    },
  ];

  constructor(
    private _studentProfileService: ViewEditService,
    private _config: Config,
    private _constants: Constants,
    private _titleService: Title
  ) {}
  ngOnInit() {
    const homeIndex = this._breadCrumbsLinks.findIndex(link => {
      return link.field === 'Home';
    });
    if (this.userType === 'learner') {
      this._breadCrumbsLinks[homeIndex].link = this._config.drupalHome;
    } else {
      this._breadCrumbsLinks[homeIndex].link = this._config.internalUserHome;
    }
    this.activeCrumbs = this.getCrumbs(this.currentPage).reverse();
    if (this.currentPage.includes('Selected Student')) {
      this.activeCrumbs[this.activeCrumbs.length - 1].field = 'Selected Student';
      this._studentProfileService.selectedStudent.subscribe(data => {
        if (data) {
          const admissionInfo = data['admission_info'];
          this.studentName = `${data['first_name']}`;
          if (admissionInfo && admissionInfo['is_admission_info_form_filled']) {
            this.studentName = `${this.studentName}-${admissionInfo['target_degree']}-${admissionInfo['area_of_interest']}-${admissionInfo['preferred_countries'][0]}`;
          }
          this.activeCrumbs[this.activeCrumbs.length - 1].field = this.studentName;
          this._setTitle(this.studentName);
        }
      });
    }
    if (this.currentPage === 'simTitle') {
      const simTitle = {};
      const simExam = {
        field: this.currentPageAdder.exam,
        link: this._config.drupalHome,
        linkType: 'href',
      };

      if (
        this.currentPageAdder.title.length >
        this._constants.HEADER_CHARACTER_LIMITS.HEADER_DESKTOP_DEVICE
      ) {
        (simTitle['tooltip'] = this.currentPageAdder.title),
          (simTitle['field'] =
            this.currentPageAdder.title.slice(
              0,
              this._constants.HEADER_CHARACTER_LIMITS.HEADER_DESKTOP_DEVICE
            ) + '...');
      } else {
        simTitle['field'] = this.currentPageAdder.title;
      }

      this.activeCrumbs = [...this.activeCrumbs.slice(0, -1), simExam, simTitle];
    }
  }

  getCrumbs(child) {
    const childNode = this._breadCrumbsLinks.find(item => item.field === child);
    this.activeCrumbs.push(childNode);
    if (childNode.parent != null) {
      return this.getCrumbs(childNode.parent);
    }
    return this.activeCrumbs;
  }

  private _setTitle(newTitle: string) {
    // const title = this._titleService.getTitle() + '-' + newTitle;
    this._titleService.setTitle(newTitle);
  }
}
