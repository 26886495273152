import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Constants } from '@entrayn/core/services/config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @Input() simTitle = '';
  @Input() fullWidth = false;
  @Input() homeUrl = this._constants.DRUPAL_URL.HOME;
  simTitleTransform: string;
  characterLimitForMobileDevices = this._constants.HEADER_CHARACTER_LIMITS
    .HEADER_MOBILE_OR_TABLET_DEVICE;
  /**
   *Creates an instance of HeaderComponent.
   * @memberof HeaderComponent
   */
  constructor(private _constants: Constants) {}

  /**
   *
   *
   * @memberof HeaderComponent
   */
  ngOnInit() {
    this.simTitleTransform =
      this.simTitle.length > this.characterLimitForMobileDevices
        ? this.simTitle.slice(0, this.characterLimitForMobileDevices) + '...'
        : this.simTitle;
  }
}
