import { Validators } from '@angular/forms';
import { UniversityListServiceService } from '../services/university-list-service.service';
import { Config } from './config';
import { format } from 'date-fns';
import * as i0 from "@angular/core";
import * as i1 from "../services/university-list-service.service";
import * as i2 from "./config";
export class StudentProfileEditConfig {
    constructor(_universityService, _config) {
        this._universityService = _universityService;
        this._config = _config;
        this._university = [];
        this._maxDate = format(new Date(), 'YYYY-MM-DD');
        this._dropDownOptions = this._config.dropDownOptions;
    }
    _getParameterOptions(arr) {
        const options = [];
        arr.forEach((option, index) => {
            options.push({ optionItem: option, optionValue: String(index + 1) });
        });
        return options;
    }
    createProfileEditConfig(data) {
        const isPreviousApplicationFound = data['admission_info']['previous_applications_information']
            ? true
            : false;
        this._regConfig = {
            basic_information: [
                {
                    type: 'input',
                    label: 'First Name',
                    inputType: 'text',
                    name: 'first_name',
                    value: data['first_name'],
                    validations: [
                        {
                            name: 'maxlength',
                            validator: Validators.maxLength(30),
                            message: '* Maximum length is 30 characters',
                        },
                        {
                            name: 'pattern',
                            validator: Validators.pattern(this._config.regExPatterns.whitespace),
                            message: '* should not end or begin with whitespaces',
                        },
                        {
                            name: 'required',
                            validator: Validators.required,
                            message: '* First name Required',
                        },
                    ],
                },
                {
                    type: 'input',
                    label: 'Last Name',
                    inputType: 'text',
                    name: 'last_name',
                    value: data['last_name'],
                    validations: [
                        {
                            name: 'maxlength',
                            validator: Validators.maxLength(150),
                            message: '* Maximum length is 150 characters',
                        },
                        {
                            name: 'pattern',
                            validator: Validators.pattern(this._config.regExPatterns.whitespace),
                            message: '* should not end or begin with whitespaces',
                        },
                    ],
                },
                {
                    type: 'select',
                    label: 'Gender',
                    name: 'gender',
                    value: data['gender'] === 'Male' || data['gender'] === 'M'
                        ? 'M'
                        : data['gender'] === 'Female' || data['gender'] === 'F'
                            ? 'F'
                            : 'O',
                    options: this._dropDownOptions['gender'],
                },
                {
                    formGroupName: 'profile',
                    formGroupControls: [
                        {
                            type: 'date',
                            label: 'DOB',
                            max: this._maxDate,
                            name: 'date_of_birth',
                            value: data['profile']['date_of_birth'],
                        },
                        {
                            type: 'input',
                            label: 'Phone',
                            inputType: 'text',
                            name: 'phone',
                            value: data['profile']['phone'],
                            validations: [
                                {
                                    name: 'required',
                                    validator: Validators.required,
                                    message: '* Phone Number Required',
                                },
                                {
                                    name: 'maxlength',
                                    validator: Validators.maxLength(30),
                                    message: '* Maximum length is 30 characters',
                                },
                                {
                                    name: 'minlength',
                                    validator: Validators.minLength(5),
                                    message: '* Minumum length is 5 characters',
                                },
                                {
                                    name: 'pattern',
                                    validator: Validators.pattern(this._config.regExPatterns.phoneNumber),
                                    message: '* Valid phone number is required',
                                },
                            ],
                        },
                        {
                            type: 'textarea',
                            label: 'Address',
                            inputType: 'text',
                            name: 'address',
                            value: data['profile']['address'],
                            class: 'col-12 mb-1',
                            validations: [
                                {
                                    name: 'required',
                                    validator: Validators.required,
                                    message: '* Address is mandatory',
                                },
                            ],
                        },
                        {
                            type: 'select',
                            label: 'Country Of Origin',
                            name: 'country_of_origin',
                            value: data['profile']['country_of_origin'],
                            options: this._dropDownOptions['country_of_origin'],
                            class: 'col-6 mb-1',
                        },
                    ],
                },
            ],
            target: [
                {
                    formGroupName: 'admission_info',
                    formGroupControls: [
                        {
                            type: 'select',
                            label: 'Target Season',
                            name: 'target_season',
                            value: data['admission_info']['target_season'],
                            options: ['Fall', 'Spring'],
                            class: 'col-3 mb-1',
                        },
                        {
                            type: 'select',
                            label: 'Target Year',
                            name: 'target_year',
                            value: data['admission_info']['target_year'],
                            options: Array.from({ length: 5 }, (v, n) => n + new Date().getFullYear()),
                            class: 'col-3 mb-1',
                        },
                        {
                            type: 'select',
                            label: 'Degree',
                            name: 'target_degree',
                            value: data['admission_info']['target_degree'],
                            options: this._dropDownOptions['target_degree'],
                        },
                        {
                            type: 'select',
                            label: 'Target Country 1',
                            name: 'preferred_countries_1',
                            value: data['admission_info']['preferred_countries'][0],
                            options: this._dropDownOptions['target_countries'],
                            validations: [
                                {
                                    name: 'required',
                                    validator: Validators.required,
                                    message: '* should not be empty',
                                },
                            ],
                        },
                        {
                            type: 'select',
                            label: 'Target Country 2',
                            name: 'preferred_countries_2',
                            value: data['admission_info']['preferred_countries'][1],
                            options: this._dropDownOptions['target_countries'],
                            validations: [
                                {
                                    name: 'required',
                                    validator: Validators.required,
                                    message: '* should not be empty',
                                },
                            ],
                        },
                        {
                            type: 'select',
                            label: 'Department',
                            name: 'target_department',
                            value: data['admission_info']['target_department'],
                            options: this._dropDownOptions['target_department'],
                        },
                        {
                            type: 'select',
                            label: 'Area of Interest',
                            name: 'area_of_interest',
                            value: data['admission_info']['area_of_interest'],
                            options: this._dropDownOptions['area_of_interest'],
                        },
                        {
                            formGroupName: 'additional_info',
                            formGroupControls: [
                                {
                                    type: 'textarea',
                                    label: 'Reasons for interest in the above area',
                                    inputType: 'text',
                                    name: 'reasons_for_interest_in_major',
                                    class: 'col-12 mb-1',
                                    value: data['admission_info']['additional_info']['reasons_for_interest_in_major'],
                                },
                            ],
                        },
                        {
                            type: 'subheading',
                            content: 'Switching Majors',
                            class: 'col-12 mb-1',
                        },
                        {
                            type: 'select',
                            label: 'Switching Majors / Subjects / Departments',
                            name: 'has_switched_majors',
                            value: data['admission_info']['has_switched_majors'] ? true : false,
                            options: [
                                {
                                    optionItem: 'Yes',
                                    optionValue: true,
                                },
                                {
                                    optionItem: 'No',
                                    optionValue: false,
                                },
                            ],
                            class: 'col-6 mb-1',
                        },
                        {
                            type: 'textarea',
                            label: 'Explain activities related to the new field',
                            inputType: 'text',
                            name: 'related_activities_for_new_field',
                            value: data['admission_info']['related_activities_for_new_field'],
                            class: 'col-12 mb-1',
                        },
                    ],
                },
            ],
            previous_applications: [
                {
                    formGroupName: 'admission_info',
                    formGroupControls: [
                        {
                            formGroupName: 'previous_applications_information',
                            formGroupControls: [
                                {
                                    type: 'select',
                                    label: 'Country',
                                    name: 'country',
                                    value: isPreviousApplicationFound
                                        ? data['admission_info']['previous_applications_information']['country']
                                        : null,
                                    options: this._dropDownOptions['country_of_origin'],
                                },
                                {
                                    type: 'select',
                                    label: 'Semester',
                                    inputType: 'text',
                                    name: 'enrollment_season',
                                    value: isPreviousApplicationFound
                                        ? data['admission_info']['previous_applications_information']['enrollment_season']
                                        : null,
                                    options: this.seasonOptions(),
                                },
                                {
                                    type: 'select',
                                    label: 'Department',
                                    name: 'applied_department',
                                    value: isPreviousApplicationFound
                                        ? data['admission_info']['previous_applications_information']['applied_department']
                                        : null,
                                    options: this._dropDownOptions['target_department'],
                                },
                                {
                                    type: 'select',
                                    label: 'Area of Interest',
                                    name: 'area_of_interest',
                                    value: isPreviousApplicationFound
                                        ? data['admission_info']['previous_applications_information']['area_of_interest']
                                        : null,
                                    options: this._dropDownOptions['area_of_interest'],
                                },
                                {
                                    type: 'textarea',
                                    label: 'Admits from any previous applications',
                                    inputType: 'text',
                                    name: 'admits',
                                    value: isPreviousApplicationFound
                                        ? data['admission_info']['previous_applications_information']['admits']
                                        : null,
                                    class: 'col-12 mb-1',
                                },
                                {
                                    type: 'textarea',
                                    label: 'Rejects from any previous applications',
                                    inputType: 'text',
                                    name: 'rejects',
                                    value: isPreviousApplicationFound
                                        ? data['admission_info']['previous_applications_information']['rejects']
                                        : null,
                                    class: 'col-12 mb-1',
                                },
                                {
                                    type: 'select',
                                    label: 'Have you deferred from your previous admits',
                                    name: 'deferred_admits',
                                    value: isPreviousApplicationFound
                                        ? data['admission_info']['previous_applications_information']['deferred_admits']
                                        : null,
                                    options: ['Yes', 'No'],
                                    class: 'col-12 mb-1',
                                },
                                {
                                    type: 'select',
                                    label: 'Do you intend to re-apply to the same universities this intake?',
                                    name: 'do_you_want_to_apply_to_same_universities',
                                    value: isPreviousApplicationFound
                                        ? data['admission_info']['previous_applications_information']['do_you_want_to_apply_to_same_universities']
                                        : null,
                                    options: ['Yes', 'No'],
                                    class: 'col-12 mb-1',
                                },
                                {
                                    type: 'textarea',
                                    label: 'How has your profile changed since the last attempt for the reapplication process',
                                    inputType: 'text',
                                    name: 'did_profile_change',
                                    value: isPreviousApplicationFound
                                        ? data['admission_info']['previous_applications_information']['did_profile_change']
                                        : '-',
                                    class: 'col-12 mb-1',
                                },
                            ],
                        },
                    ],
                },
            ],
            test_scores: [
                {
                    formGroupName: 'admission_info',
                    formGroupControls: [
                        {
                            type: 'subheading',
                            content: 'Choice Of Test',
                            class: 'col-12 mb-1',
                        },
                        {
                            type: 'select',
                            name: 'choice_of_standardized_test',
                            label: 'Standardized Test',
                            value: data['admission_info']['choice_of_standardized_test'],
                            options: [
                                {
                                    optionItem: 'GRE',
                                    optionValue: 'GRE',
                                },
                                {
                                    optionItem: 'GMAT',
                                    optionValue: 'GMAT',
                                },
                                {
                                    optionItem: 'Not Required',
                                    optionValue: null,
                                },
                            ],
                            class: 'col-4 mb-1',
                        },
                        {
                            type: 'select',
                            name: 'choice_of_english_language_proficiency_test',
                            label: 'English Proficiency Test',
                            value: data['admission_info']['choice_of_english_language_proficiency_test'],
                            options: [
                                {
                                    optionItem: 'TOEFL',
                                    optionValue: 'TOEFL',
                                },
                                {
                                    optionItem: 'IELTS',
                                    optionValue: 'IELTS',
                                },
                                {
                                    optionItem: 'PTE',
                                    optionValue: 'PTE',
                                },
                                {
                                    optionItem: 'Not Required',
                                    optionValue: null,
                                },
                            ],
                            class: 'col-4 mb-1',
                        },
                    ],
                },
                {
                    formGroupName: 'exams',
                    formGroupControls: [
                        // General test details
                        {
                            type: 'subheading',
                            content: 'General Test',
                            class: 'col-12 mb-1',
                        },
                        {
                            type: 'content',
                            content: 'GRE',
                            class: 'col-3 mb-1',
                        },
                        {
                            type: 'select',
                            name: 'gre_exam_booking_status',
                            label: 'Booking status',
                            value: data['exams']['gre_exam_booking_status'],
                            options: [
                                {
                                    optionItem: 'Tentative',
                                    optionValue: 'TNT',
                                },
                                {
                                    optionItem: 'Booked',
                                    optionValue: 'BKD',
                                },
                            ],
                            class: 'col-4 mb-1',
                        },
                        {
                            type: 'date',
                            label: 'GRE Exam Date',
                            name: 'gre_exam_date',
                            value: data['exams']['gre_exam_date'],
                            class: 'col-4 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'GRE Verbal',
                            inputType: 'number',
                            min: 130,
                            max: 170,
                            name: 'gre_verbal',
                            value: data['exams']['gre_verbal'],
                            class: 'col-4 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'GRE Quant',
                            inputType: 'number',
                            min: 130,
                            max: 170,
                            name: 'gre_quant',
                            value: data['exams']['gre_quant'],
                            class: 'col-4 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'GRE AWA',
                            inputType: 'number',
                            min: 0,
                            max: 6,
                            name: 'gre_awa',
                            value: data['exams']['gre_awa'],
                            class: 'col-3 mb-1',
                        },
                        // GMAT SCORES
                        {
                            type: 'content',
                            content: 'GMAT',
                            class: 'col-3 mb-1',
                        },
                        {
                            type: 'select',
                            name: 'gmat_exam_booking_status',
                            label: 'Booking status',
                            value: data['exams']['gmat_exam_booking_status'],
                            options: [
                                {
                                    optionItem: 'Tentative',
                                    optionValue: 'TNT',
                                },
                                {
                                    optionItem: 'Booked',
                                    optionValue: 'BKD',
                                },
                            ],
                            class: 'col-4 mb-1',
                        },
                        {
                            type: 'date',
                            label: 'GMAT Exam Date',
                            name: 'gmat_exam_date',
                            value: data['exams']['gmat_exam_date'],
                            class: 'col-4 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'GMAT Total',
                            inputType: 'number',
                            name: 'gmat_total_score',
                            min: 200,
                            max: 800,
                            value: data['exams']['gmat_total_score'],
                            class: 'col-3 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'GMAT Verbal',
                            inputType: 'number',
                            name: 'gmat_verbal',
                            min: 0,
                            max: 60,
                            value: data['exams']['gmat_verbal'],
                            class: 'col-2 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'GMAT Quant',
                            inputType: 'number',
                            min: 0,
                            max: 60,
                            name: 'gmat_quant',
                            value: data['exams']['gmat_quant'],
                            class: 'col-2 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'GMAT AR',
                            inputType: 'number',
                            name: 'gmat_awa',
                            min: 0,
                            max: 6,
                            value: data['exams']['gmat_awa'],
                            class: 'col-2 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'GMAT IR',
                            inputType: 'number',
                            name: 'gmat_ir_score',
                            min: 1,
                            max: 8,
                            value: data['exams']['gmat_ir_score'],
                            class: 'col-2 mb-1',
                        },
                        // English Proficiency Test details
                        {
                            type: 'subheading',
                            content: 'English Proficiency Test',
                            class: 'col-12 mb-1',
                        },
                        // TOEFL
                        {
                            type: 'content',
                            content: 'TOEFL',
                            class: 'col-3 mb-1',
                        },
                        {
                            type: 'select',
                            name: 'toefl_exam_booking_status',
                            label: 'Booking Status',
                            value: data['exams']['toefl_exam_booking_status'],
                            options: [
                                {
                                    optionItem: 'Tentative',
                                    optionValue: 'TNT',
                                },
                                {
                                    optionItem: 'Booked',
                                    optionValue: 'BKD',
                                },
                            ],
                            class: 'col-4 mb-1',
                        },
                        {
                            type: 'date',
                            label: 'TOEFL Exam Date',
                            name: 'toefl_exam_date',
                            value: data['exams']['toefl_exam_date'],
                            class: 'col-4 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'Reading',
                            inputType: 'number',
                            name: 'toefl_reading',
                            min: 0,
                            max: 30,
                            value: data['exams']['toefl_reading'],
                            class: 'col-3 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'Listening',
                            inputType: 'number',
                            name: 'toefl_listening',
                            min: 0,
                            max: 30,
                            value: data['exams']['toefl_listening'],
                            class: 'col-3 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'Writing',
                            inputType: 'number',
                            name: 'toefl_writing',
                            min: 0,
                            max: 30,
                            value: data['exams']['toefl_writing'],
                            class: 'col-3 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'Speaking',
                            inputType: 'number',
                            name: 'toefl_speaking',
                            min: 0,
                            max: 30,
                            value: data['exams']['toefl_speaking'],
                            class: 'col-2 mb-1',
                        },
                        {
                            type: 'content',
                            content: 'IELTS',
                            class: 'col-3 mb-1',
                        },
                        {
                            type: 'select',
                            label: 'Booking Status',
                            name: 'ielts_exam_booking_status',
                            value: data['exams']['ielts_exam_booking_status'],
                            options: [
                                {
                                    optionItem: 'Tentative',
                                    optionValue: 'TNT',
                                },
                                {
                                    optionItem: 'Booked',
                                    optionValue: 'BKD',
                                },
                            ],
                            class: 'col-4 mb-1',
                        },
                        {
                            type: 'date',
                            label: 'IELTS Exam Date',
                            name: 'ielts_exam_date',
                            value: data['exams']['ielts_exam_date'],
                            class: 'col-4 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'Reading',
                            inputType: 'number',
                            min: 1,
                            max: 9,
                            name: 'ielts_reading',
                            value: data['exams']['ielts_reading'],
                            class: 'col-3 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'Listening',
                            inputType: 'number',
                            min: 1,
                            max: 9,
                            name: 'ielts_listening',
                            value: data['exams']['ielts_listening'],
                            class: 'col-3 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'Writing',
                            inputType: 'number',
                            name: 'ielts_writing',
                            min: 1,
                            max: 9,
                            value: data['exams']['ielts_writing'],
                            class: 'col-3 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'Speaking',
                            inputType: 'number',
                            name: 'ielts_speaking',
                            min: 1,
                            max: 9,
                            value: data['exams']['ielts_speaking'],
                            class: 'col-2 mb-1',
                        },
                        {
                            type: 'content',
                            content: 'PTE',
                            class: 'col-3 mb-1',
                        },
                        {
                            type: 'select',
                            label: 'Booking Status',
                            name: 'pte_exam_booking_status',
                            value: data['exams']['pte_exam_booking_status'],
                            options: [
                                {
                                    optionItem: 'Tentative',
                                    optionValue: 'TNT',
                                },
                                {
                                    optionItem: 'Booked',
                                    optionValue: 'BKD',
                                },
                            ],
                            class: 'col-4 mb-1',
                        },
                        {
                            type: 'date',
                            label: 'PTE Exam Date',
                            name: 'pte_exam_date',
                            value: data['exams']['pte_exam_date'],
                            class: 'col-4 mb-1',
                        },
                        {
                            type: 'input',
                            label: 'PTE Total',
                            inputType: 'number',
                            min: 0,
                            max: 90,
                            name: 'pte_total_score',
                            value: data['exams']['pte_total_score'],
                            class: 'col-3 mb-1',
                        },
                    ],
                },
            ],
            academics: [
                {
                    formGroupName: 'professional_details',
                    formGroupControls: [
                        {
                            formGroupName: 'degree_details',
                            formGroupControls: [
                                {
                                    formGroupName: 'highest_degree_details',
                                    formGroupControls: [
                                        {
                                            type: 'subheading',
                                            content: 'Current/ Highest',
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'select',
                                            label: 'Degree',
                                            name: 'highest_degree',
                                            value: data['professional_details']['degree_details']['highest_degree_details']['highest_degree'],
                                            options: this._dropDownOptions['current_degree'],
                                        },
                                        {
                                            type: 'select',
                                            label: 'Year of graduation',
                                            name: 'year_of_graduation',
                                            value: data['professional_details']['degree_details']['highest_degree_details']['year_of_graduation'],
                                            options: this._dropDownOptions['year_of_graduation'],
                                        },
                                        {
                                            type: 'input',
                                            label: 'Name of Institute',
                                            inputType: 'text',
                                            name: 'institute_name',
                                            value: data['professional_details']['degree_details']['highest_degree_details']['institute_name'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'input',
                                            label: 'Affiliated University',
                                            inputType: 'text',
                                            name: 'affiliated_university',
                                            value: data['professional_details']['degree_details']['highest_degree_details']['affiliated_university'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'select',
                                            label: 'Branch/Major',
                                            name: 'branch',
                                            value: data['professional_details']['degree_details']['highest_degree_details']['branch'],
                                            options: this._dropDownOptions['branch'],
                                        },
                                        {
                                            type: 'input',
                                            label: 'CGPA',
                                            inputType: 'number',
                                            name: 'cgpa_numerator',
                                            value: data['professional_details']['degree_details']['highest_degree_details']['cgpa_numerator'],
                                            min: 0,
                                            max: data['professional_details']['degree_details']['highest_degree_details']['cgpa_denominator'],
                                            class: 'col-3 mb-1',
                                        },
                                        {
                                            type: 'select',
                                            label: 'Total',
                                            name: 'cgpa_denominator',
                                            value: data['professional_details']['degree_details']['highest_degree_details']['cgpa_denominator'],
                                            options: [10, 100, 5],
                                            class: 'col-3 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'Reason for picking this major',
                                            inputType: 'text',
                                            name: 'reason_for_picking_major',
                                            value: data['professional_details']['degree_details']['highest_degree_details']['reason_for_picking_major'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'Subjects you liked',
                                            inputType: 'text',
                                            name: 'subjects_you_liked',
                                            value: data['professional_details']['degree_details']['highest_degree_details']['subjects_you_liked'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'select',
                                            label: 'Do you have any backlogs/arrears',
                                            name: 'is_having_backlogs',
                                            value: data['professional_details']['degree_details']['highest_degree_details']['is_having_backlogs'] ||
                                                data['professional_details']['degree_details']['highest_degree_details']['Details of any backlogs/arrears'] !== null
                                                ? true
                                                : false,
                                            options: [
                                                {
                                                    optionItem: 'Yes',
                                                    optionValue: true,
                                                },
                                                {
                                                    optionItem: 'No',
                                                    optionValue: false,
                                                },
                                            ],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'Details of any backlogs/arrears',
                                            inputType: 'text',
                                            name: 'details_of_backlogs',
                                            value: data['professional_details']['degree_details']['highest_degree_details']['details_of_backlogs'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'select',
                                            label: 'Do you have any gap/break in your education career',
                                            name: 'any_gap_in_education',
                                            value: data['professional_details']['degree_details']['highest_degree_details']['any_gap_in_education'],
                                            options: [
                                                {
                                                    optionItem: 'Yes',
                                                    optionValue: true,
                                                },
                                                {
                                                    optionItem: 'No',
                                                    optionValue: false,
                                                },
                                            ],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'Details of break',
                                            inputType: 'text',
                                            name: 'gap_reason',
                                            value: data['professional_details']['degree_details']['highest_degree_details']['gap_reason'],
                                            class: 'col-12 mb-1',
                                        },
                                    ],
                                },
                                {
                                    formGroupName: 'additional_degree_details',
                                    formGroupControls: [
                                        {
                                            type: 'select',
                                            label: 'Any additional university Degrees',
                                            name: 'any_additional_university_degrees',
                                            value: data['professional_details']['degree_details']['additional_degree_details']['any_additional_university_degrees'],
                                            options: [
                                                {
                                                    optionItem: 'Yes',
                                                    optionValue: true,
                                                },
                                                {
                                                    optionItem: 'No',
                                                    optionValue: false,
                                                },
                                            ],
                                        },
                                        {
                                            type: 'select',
                                            label: 'Branch',
                                            name: 'other_branch',
                                            value: data['professional_details']['degree_details']['additional_degree_details']['other_branch'],
                                            options: this._dropDownOptions['branch'],
                                        },
                                    ],
                                },
                                {
                                    formGroupName: 'ug_degree_details',
                                    formGroupControls: [
                                        {
                                            type: 'subheading',
                                            content: 'Undergraduate',
                                            class: 'col-12',
                                        },
                                        {
                                            type: 'select',
                                            label: 'Degree',
                                            name: 'ug_degree',
                                            value: data['professional_details']['degree_details']['ug_degree_details']['ug_degree'],
                                            options: this._dropDownOptions['current_degree'],
                                        },
                                        {
                                            type: 'select',
                                            label: 'Year of graduation',
                                            name: 'year_of_graduation',
                                            value: data['professional_details']['degree_details']['ug_degree_details']['year_of_graduation'],
                                            options: this._dropDownOptions['year_of_graduation'],
                                        },
                                        {
                                            type: 'input',
                                            label: 'Name of Institute',
                                            inputType: 'text',
                                            name: 'institute_name',
                                            value: data['professional_details']['degree_details']['ug_degree_details']['institute_name'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'input',
                                            label: 'Affiliated University',
                                            inputType: 'text',
                                            name: 'affiliated_university',
                                            value: data['professional_details']['degree_details']['ug_degree_details']['affiliated_university'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'select',
                                            label: 'Branch/Major',
                                            name: 'ug_major',
                                            value: data['professional_details']['degree_details']['ug_degree_details']['ug_major'],
                                            options: this._dropDownOptions['branch'],
                                        },
                                        {
                                            type: 'input',
                                            label: 'CGPA',
                                            inputType: 'text',
                                            name: 'cgpa_numerator',
                                            max: data['professional_details']['degree_details']['ug_degree_details']['cgpa_denominator'],
                                            value: data['professional_details']['degree_details']['ug_degree_details']['cgpa_numerator'],
                                            class: 'col-3 mb-1',
                                        },
                                        {
                                            type: 'select',
                                            name: 'cgpa_denominator',
                                            label: 'Total',
                                            value: data['professional_details']['degree_details']['ug_degree_details']['cgpa_denominator'],
                                            options: [10, 100, 5],
                                            class: 'col-3 mb-1',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
            publications: [
                {
                    formGroupName: 'professional_details',
                    formGroupControls: [
                        {
                            formGroupName: 'experiences',
                            formGroupControls: [
                                {
                                    formGroupName: 'international_publications',
                                    formGroupControls: [
                                        {
                                            type: 'input',
                                            label: 'International Publications',
                                            inputType: 'number',
                                            labelposition: 'inline',
                                            min: 0,
                                            name: 'international_publications_count',
                                            value: data['professional_details']['experiences']['international_publications']['international_publications_count'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'Details',
                                            inputType: 'text',
                                            name: 'international_publications_summary',
                                            value: data['professional_details']['experiences']['international_publications']['international_publications_summary'],
                                            class: 'col-12 mb-1',
                                        },
                                    ],
                                },
                                {
                                    formGroupName: 'international_conferences',
                                    formGroupControls: [
                                        {
                                            type: 'input',
                                            label: 'Articles/Presentations in international conferences',
                                            inputType: 'number',
                                            labelposition: 'inline',
                                            min: 0,
                                            name: 'number_of_international_articles_or_conferences',
                                            value: data['professional_details']['experiences']['international_conferences']['number_of_international_articles_or_conferences'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'List of conferences and years',
                                            inputType: 'text',
                                            name: 'international_conferences_summary',
                                            value: data['professional_details']['experiences']['international_conferences']['international_conferences_summary'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'Details',
                                            inputType: 'text',
                                            name: 'international_conferences_details',
                                            value: data['professional_details']['experiences']['international_conferences']['international_conferences_details'],
                                            class: 'col-12 mb-1',
                                        },
                                    ],
                                },
                                {
                                    formGroupName: 'national_publications',
                                    formGroupControls: [
                                        {
                                            type: 'input',
                                            label: 'National Publications',
                                            inputType: 'number',
                                            labelposition: 'inline',
                                            min: 0,
                                            name: 'national_publications_count',
                                            value: data['professional_details']['experiences']['national_publications']['national_publications_count'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'Details',
                                            inputType: 'text',
                                            name: 'national_publications_summary',
                                            value: data['professional_details']['experiences']['national_publications']['national_publications_summary'],
                                            class: 'col-12 mb-1',
                                        },
                                    ],
                                },
                                {
                                    formGroupName: 'national_conferences',
                                    formGroupControls: [
                                        {
                                            type: 'input',
                                            label: 'Articles/Presentations in national conferences',
                                            inputType: 'number',
                                            labelposition: 'inline',
                                            min: 0,
                                            name: 'number_of_national_articles_or_conferences',
                                            value: data['professional_details']['experiences']['national_conferences']['number_of_national_articles_or_conferences'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'List of conferences and years',
                                            inputType: 'text',
                                            name: 'national_conferences_summary',
                                            value: data['professional_details']['experiences']['national_conferences']['national_conferences_summary'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'Details',
                                            inputType: 'text',
                                            name: 'national_conferences_details',
                                            value: data['professional_details']['experiences']['national_conferences']['national_conferences_details'],
                                            class: 'col-12 mb-1',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
            research: [
                {
                    formGroupName: 'professional_details',
                    formGroupControls: [
                        {
                            formGroupName: 'experiences',
                            formGroupControls: [
                                {
                                    formGroupName: 'academic_internships',
                                    formGroupControls: [
                                        {
                                            type: 'input',
                                            label: 'month / months Academic Internship',
                                            inputType: 'number',
                                            labelposition: 'inline',
                                            min: 0,
                                            name: 'academic_internships_duration',
                                            value: data['professional_details']['experiences']['academic_internships']['academic_internships_duration'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'Details',
                                            inputType: 'text',
                                            name: 'academic_internships_description',
                                            value: data['professional_details']['experiences']['academic_internships']['academic_internships_description'],
                                            class: 'col-12 mb-1',
                                        },
                                    ],
                                },
                                {
                                    formGroupName: 'industrial_internships',
                                    formGroupControls: [
                                        {
                                            type: 'input',
                                            label: 'month / months Industrial Internship',
                                            inputType: 'number',
                                            min: 0,
                                            name: 'industrial_internships_duration',
                                            labelposition: 'inline',
                                            value: data['professional_details']['experiences']['industrial_internships']['industrial_internships_duration'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'Details',
                                            inputType: 'text',
                                            name: 'industrial_internships_description',
                                            value: data['professional_details']['experiences']['industrial_internships']['industrial_internships_description'],
                                            class: 'col-12 mb-1',
                                        },
                                    ],
                                },
                                {
                                    formGroupName: 'ongoing_projects',
                                    formGroupControls: [
                                        {
                                            type: 'input',
                                            label: 'Ongoing Projects/Certifications/online courses',
                                            inputType: 'number',
                                            min: 0,
                                            labelposition: 'inline',
                                            name: 'ongoing_projects',
                                            value: data['professional_details']['experiences']['ongoing_projects']['ongoing_projects'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'Details',
                                            inputType: 'text',
                                            name: 'ongoing_projects_decription',
                                            value: data['professional_details']['experiences']['ongoing_projects']['ongoing_projects_decription'],
                                            class: 'col-12 mb-1',
                                        },
                                    ],
                                },
                                {
                                    formGroupName: 'hackathons_and_contests',
                                    formGroupControls: [
                                        {
                                            type: 'input',
                                            label: 'Hackathons/Contests Participated',
                                            inputType: 'number',
                                            min: 0,
                                            labelposition: 'inline',
                                            name: 'number_of_hackathons_or_contests_participated',
                                            value: data['professional_details']['experiences']['hackathons_and_contests']['number_of_hackathons_or_contests_participated'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'Details',
                                            inputType: 'text',
                                            name: 'hackathons_or_contests_description',
                                            value: data['professional_details']['experiences']['hackathons_and_contests']['hackathons_or_contests_description'],
                                            class: 'col-12 mb-1',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
            work_experience: [
                {
                    formGroupName: 'professional_details',
                    formGroupControls: [
                        {
                            type: 'select',
                            label: 'Currently Working',
                            name: 'is_working_professional',
                            value: data['professional_details']['is_working_professional'] ? true : false,
                            options: [
                                {
                                    optionItem: 'Yes',
                                    optionValue: true,
                                },
                                {
                                    optionItem: 'No',
                                    optionValue: false,
                                },
                            ],
                        },
                        {
                            type: 'input',
                            inputType: 'number',
                            label: 'Total months of work experience till date',
                            name: 'years_of_experience',
                            value: data['professional_details']['years_of_experience'],
                            min: 0,
                            max: 100,
                        },
                        {
                            formGroupName: 'experiences',
                            formGroupControls: [
                                {
                                    formGroupName: 'work',
                                    formGroupControls: [
                                        {
                                            type: 'input',
                                            label: 'Current/Most Recent Organization',
                                            inputType: 'text',
                                            name: 'current_name_of_organization',
                                            value: data['professional_details']['experiences']['work']['current_name_of_organization'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'input',
                                            label: 'Current Designation',
                                            inputType: 'text',
                                            name: 'current_designation',
                                            value: data['professional_details']['experiences']['work']['current_designation'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'Current Job Description',
                                            inputType: 'text',
                                            name: 'current_job_description',
                                            value: data['professional_details']['experiences']['work']['current_job_description'],
                                            class: 'col-12 mb-1',
                                        },
                                        {
                                            type: 'textarea',
                                            label: 'Breif Summary of work experience',
                                            inputType: 'text',
                                            name: 'brief_summary_of_work_experience',
                                            value: data['professional_details']['experiences']['work']['brief_summary_of_work_experience'],
                                            class: 'col-12 mb-1',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
            others: [
                {
                    formGroupName: 'professional_details',
                    formGroupControls: [
                        {
                            type: 'input',
                            label: 'Linkedin Profile',
                            inputType: 'text',
                            name: 'linkedin_profile',
                            value: data['professional_details']['linkedin_profile'] || null,
                            class: 'col-12 mb-1',
                            validations: [
                                {
                                    name: 'pattern',
                                    validator: Validators.pattern(this._config.regExPatterns.linkedinUrl),
                                    message: '* should be a linkedin url',
                                },
                            ],
                        },
                    ],
                },
                {
                    formGroupName: 'admission_info',
                    formGroupControls: [
                        {
                            formGroupName: 'additional_info',
                            formGroupControls: [
                                {
                                    type: 'textarea',
                                    label: 'Short Term Goals',
                                    inputType: 'text',
                                    name: 'short_term_goals',
                                    value: data['admission_info']['additional_info']['short_term_goals'],
                                    class: 'col-12 mb-1',
                                },
                                {
                                    type: 'textarea',
                                    label: 'Long Term Goals',
                                    inputType: 'text',
                                    name: 'long_term_goals',
                                    value: data['admission_info']['additional_info']['long_term_goals'],
                                    class: 'col-12 mb-1',
                                },
                                {
                                    type: 'select',
                                    label: 'Budget(in US Dollars)',
                                    name: 'annual_budget',
                                    value: data['admission_info']['additional_info']['annual_budget'],
                                    options: this._dropDownOptions['annual_budget'],
                                    class: 'col-6 mb-1',
                                },
                                {
                                    type: 'textarea',
                                    label: 'Any other specific aspects',
                                    inputType: 'text',
                                    name: 'any_other_specific_aspect',
                                    value: data['admission_info']['additional_info']['any_other_specific_aspect'],
                                    class: 'col-12 mb-1',
                                },
                            ],
                        },
                        {
                            type: 'select',
                            label: 'Source of Funds',
                            name: 'source_of_funds',
                            value: data['admission_info']['source_of_funds'],
                            options: this._dropDownOptions['funds'],
                        },
                        {
                            type: 'subheading',
                            content: 'Criteria',
                            class: 'col-12 mb-1',
                        },
                        {
                            type: 'select',
                            label: 'Criteria 1',
                            name: 'parameters_1',
                            value: data['admission_info']['parameters']
                                ? data['admission_info']['parameters'][0]
                                : null,
                            options: this._getParameterOptions(this._dropDownOptions.parameters),
                            validations: [
                                {
                                    name: 'required',
                                    validator: Validators.required,
                                    message: '* Criteria should not be empty',
                                },
                            ],
                        },
                        {
                            type: 'select',
                            label: 'Criteria 2',
                            name: 'parameters_2',
                            value: data['admission_info']['parameters']
                                ? data['admission_info']['parameters'][1]
                                : null,
                            options: this._getParameterOptions(this._dropDownOptions.parameters),
                            validations: [
                                {
                                    name: 'required',
                                    validator: Validators.required,
                                    message: '* Criteria should not be empty',
                                },
                            ],
                        },
                        {
                            type: 'select',
                            label: 'Criteria 3',
                            name: 'parameters_3',
                            value: data['admission_info']['parameters']
                                ? data['admission_info']['parameters'][2]
                                : null,
                            options: this._getParameterOptions(this._dropDownOptions.parameters),
                            validations: [
                                {
                                    name: 'required',
                                    validator: Validators.required,
                                    message: '* Criteria should not be empty',
                                },
                            ],
                        },
                        {
                            type: 'select',
                            label: 'Criteria 4',
                            name: 'parameters_4',
                            value: data['admission_info']['parameters']
                                ? data['admission_info']['parameters'][3]
                                : null,
                            options: this._getParameterOptions(this._dropDownOptions.parameters),
                            validations: [
                                {
                                    name: 'required',
                                    validator: Validators.required,
                                    message: '* Criteria should not be empty',
                                },
                            ],
                        },
                        {
                            type: 'select',
                            label: 'Criteria 5',
                            name: 'parameters_5',
                            value: data['admission_info']['parameters']
                                ? data['admission_info']['parameters'][4]
                                : null,
                            options: this._getParameterOptions(this._dropDownOptions.parameters),
                            validations: [
                                {
                                    name: 'required',
                                    validator: Validators.required,
                                    message: '* Criteria should not be empty',
                                },
                            ],
                        },
                    ],
                },
            ],
            university_preferences: [
                {
                    formGroupName: 'admission_info',
                    formGroupControls: Array.from({ length: 4 }, (v, n) => {
                        return {
                            type: 'autoComplete',
                            label: 'Preference ' + String(n + 1),
                            name: 'preferred_universities_' + String(n + 1),
                            id: n === 4 ? 'last' : 'Not_last',
                            value: data['admission_info']['preferred_universities']
                                ? data['admission_info']['preferred_universities'][n] || null
                                : null,
                            class: 'col-12 mb-1',
                            options: this._dropDownOptions['target_universities'],
                            searchBar: true,
                        };
                    }),
                },
            ],
        };
    }
    getProfileEditConfig(index) {
        return this._regConfig[index];
    }
    getExperienceObject() {
        return {
            work: {
                current_designation: null,
                years_of_experience: null,
                current_job_description: null,
                work_experience_summary: null,
                current_name_of_organization: null,
                brief_summary_of_work_experience: null,
            },
            ongoing_projects: {
                ongoing_projects: null,
                ongoing_projects_description: null,
            },
            academic_internships: {
                academic_internships_duration: null,
                academic_internships_description: null,
            },
            national_conferences: {
                national_conferences_details: null,
                national_conferences_summary: null,
                number_of_national_articles_or_conferences: null,
            },
            national_publications: {
                national_publications_count: null,
                national_publications_summary: null,
            },
            industrial_internships: {
                industrial_internships_duration: null,
                industrial_internships_description: null,
            },
            hackathons_and_contests: {
                hackathons_or_contests_description: null,
                number_of_hackathons_or_contests_participated: null,
            },
            international_conferences: {
                international_conferences_details: null,
                international_conferences_summary: null,
                number_of_international_articles_or_conferences: null,
            },
            international_publications: {
                international_publications_count: '1',
                international_publications_summary: null,
            },
        };
    }
    seasonOptions() {
        // march to june - spring
        //  sept to dec - fall
        const seasons = [];
        const date = new Date();
        let year = date.getFullYear();
        let periodOfYears = 5;
        const presentMonth = date.getMonth();
        if (presentMonth <= 8) {
            if (presentMonth >= 2) {
                seasons.push('Fall ' + year);
            }
            else {
                seasons.push('Spring ' + year, 'Fall ' + year);
            }
            year++;
            periodOfYears--;
        }
        for (let i = 1; i <= periodOfYears; i++) {
            seasons.push('Spring ' + Number(year + i), 'Fall ' + Number(year + i));
        }
        return seasons;
    }
}
StudentProfileEditConfig.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StudentProfileEditConfig_Factory() { return new StudentProfileEditConfig(i0.ɵɵinject(i1.UniversityListServiceService), i0.ɵɵinject(i2.Config)); }, token: StudentProfileEditConfig, providedIn: "root" });
