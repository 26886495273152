import { Pipe, PipeTransform } from '@angular/core';

/**
 *
 *
 * @export
 * @class UuidExistingPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'uuidExisting',
  pure: false,
})
export class UuidExistingPipe implements PipeTransform {
  /**
   *
   *
   * @param {*} array
   * @param {*} [uuid]
   * @returns {*}
   * @memberof UuidExistingPipe
   */
  transform(array: any, uuid?: any): any {
    for (const element of array) {
      if (element.uuid === uuid) {
        return true;
      }
    }
    return null;
  }
}
