import { ErrorHandler } from '@angular/core';
import { Routes } from '@angular/router';
import { Constants } from '@entrayn/core/services/config';
import * as Sentry from '@sentry/browser';
import { AuthGuard } from '@entrayn/web/features/auth/guards/auth.guard';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
Sentry.init({
    dsn: Constants.SENTRY_DSN,
    environment: Constants.ENV,
    release: Constants.VERSION,
    enabled: !!Constants.SENTRY_DSN,
});
export class SentryErrorHandler {
    constructor() { }
    handleError(error) {
        Sentry.captureException(error.originalError || error);
        console.error(error);
        // throw error;
    }
}
const ɵ0 = () => import("./ac-servicing/ac-servicing.module.ngfactory").then(m => m.AcServicingModuleNgFactory), ɵ1 = () => import("./../../../../xplat/web/features/learning/learning.module.ngfactory").then(m => m.LearningModuleNgFactory);
const appRoutes = [
    {
        path: '',
        redirectTo: 'internal',
        pathMatch: 'full',
    },
    {
        path: 'login',
        loadChildren: '@entrayn/web/features/auth/auth.module#AuthModule',
    },
    // Assessment Attempt routes
    {
        path: 'a',
        loadChildren: '@entrayn/web/features/assessment-attempt/assessment-attempt.module#AssessmentAttemptModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'sample-test',
        loadChildren: '@entrayn/web/features/assessment-attempt/assessment-attempt.module#AssessmentAttemptModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'summary',
        loadChildren: '@entrayn/web/features/assessment-review/assessment-review.module#AssessmentReviewModule',
        canActivate: [AuthGuard],
    },
    // AC servicing routes
    {
        path: 'ac-servicing',
        loadChildren: ɵ0,
        canActivate: [AuthGuard],
    },
    // Notification route
    {
        path: 'notifications',
        loadChildren: '@entrayn/web/features/notifications/notifications.module#NotificationsModule',
        canActivate: [AuthGuard],
    },
    // Question Import and others
    {
        path: 'internal',
        loadChildren: '@entrayn/web/features/authoring-tools/authoring-tools.module#AuthoringToolsModule',
        canActivate: [AuthGuard],
    },
    // SIM's, LO's
    {
        path: 'learning',
        loadChildren: ɵ1,
    },
    // No page route
    { path: '**', component: PageNotFoundComponent },
];
export class AppModule {
}
export { ɵ0, ɵ1 };
