import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  faqArray: any = [];
  index: number;
  private _previousState = false;
  @Input() faqList;

  constructor() {}
  ngOnInit() {
    this.faqArray = this.faqList;
  }
  onPanelOpen(event, index) {
    this.index = index;
    this._previousState = event;
  }
  onPanelClose(event, index) {
    if (!this._previousState || this.index === index) {
      this.index = null;
    }
    this._previousState = event;
  }
}
