import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { GlobalDataService } from '@entrayn/features/assessment/global-data.service';
import { AuthService } from '@entrayn/web/features/auth/services/auth.service';

/**
 *
 *
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  /**
   *Creates an instance of AuthGuard.
   * @param {AuthService} authService
   * @param {Router} _router
   * @memberof AuthGuard
   */
  constructor(
    private authService: AuthService,
    private _router: Router,
    private _global: GlobalDataService
  ) {}
  /**
   * If we're redirecting to the drupal login page, don't also redirect with the angular router.
   */
  redirectToLogin() {
    return this._global.isRedirectingToDrupalLogin() ? false : this._router.parseUrl('/login');
  }
  /**
   *
   *
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof AuthGuard
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.loggedIn()) {
      return this.redirectToLogin();
    }
    return true;
  }
}
