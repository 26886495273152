import { Injectable } from '@angular/core';

declare var $;
declare var window;

/**
 *
 *
 * @export
 * @class JQueryUtil
 */
@Injectable({
  providedIn: 'root',
})
export class JQueryUtil {
  /**
   *
   *
   * @memberof JQueryUtil
   */
  changeFooterPosition() {
    if ($('.footer').length) {
      if ($(window).height() > $('html').height()) {
        $('.footer').css('position', 'fixed');
      } else {
        $('.footer').css('position', 'relative');
      }
    }
  }

  /**
   *
   *
   * @memberof JQueryUtil
   */
  showOrHideGotoTopButton() {
    try {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById('myBtn').style.display = 'block';
      } else {
        document.getElementById('myBtn').style.display = 'none';
      }
    } catch (e) {}
  }

  /**
   *
   *
   * @memberof JQueryUtil
   */
  runRoutineMethods() {
    this.changeFooterPosition();
  }
  bindEvents() {
    $(document).resize(() => {
      this.changeFooterPosition();
    });
    $(document).scroll(() => {
      this.showOrHideGotoTopButton();
    });
  }
}
