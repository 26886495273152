import { Pipe, PipeTransform } from '@angular/core';

/**
 *
 *
 * @export
 * @class LengthPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'length',
  pure: false,
})
export class LengthPipe implements PipeTransform {
  /**
   *
   *
   * @param {*} value
   * @param {*} [args]
   * @returns {*}
   * @memberof LengthPipe
   */
  transform(value: any, args?: any): any {
    if (value === undefined) {
      return 0;
    }
    if (value === null) {
      return 0;
    }
    try {
      return value.length;
    } catch (e) {}
    return 0;
  }
}
