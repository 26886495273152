import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

declare var $;

/**
 *
 *
 * @export
 * @class InsertSentenceTextPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'insertSentenceText',
  pure: false,
})
export class InsertSentenceTextPipe implements PipeTransform {
  /**
   *Creates an instance of InsertSentenceTextPipe.
   * @param {DomSanitizer} sanitizer
   * @memberof InsertSentenceTextPipe
   */
  constructor(private sanitizer: DomSanitizer) {}

  /**
   *
   *
   * @param {*} html_string
   * @param {*} target_position_ids
   * @param {*} target_position_id
   * @param {*} sentence
   * @returns {*}
   * @memberof InsertSentenceTextPipe
   */
  transform(html_string: any, target_position_ids: any, target_position_id, sentence): any {
    const length = target_position_ids.length;
    let $html = '';
    $html = $('<span>' + html_string + '</span>');
    for (let i = 0; i < length; i++) {
      const _target_position_id = target_position_ids[i];
      if ($('#' + _target_position_id, $html).length) {
        if (_target_position_id === target_position_id) {
          $('#' + _target_position_id, $html).html(
            '<i class="fa fa-square red-square"></i>' + '<b>' + sentence + '</b>'
          );
          continue;
        }
        $('#' + _target_position_id, $html).html('<i class="fa fa-square red-square"></i>');
      }
    }
    return $($html).html();
  }
}
