<mat-accordion>
  <div *ngFor="let item of faqArray; let i = index">
    <mat-expansion-panel
      class="mat-elevation-z0 non-active"
      (closed)="onPanelClose(false, i)"
      (opened)="onPanelOpen(true, i)"
    >
      <mat-expansion-panel-header
        [collapsedHeight]="'fit-content'"
        [expandedHeight]="'fit-content'"
      >
        <mat-panel-title
          [ngClass]="{
            active: index === i,
            'non-active': index !== i
          }"
        >
          <span>{{ i + 1 }}.&nbsp;</span> <span>{{ item.question }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>{{ item.answer }}</p>
    </mat-expansion-panel>
  </div>
</mat-accordion>
