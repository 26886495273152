/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../xplat/web/features/ui/components/go-to-top/go-to-top.component.ngfactory";
import * as i3 from "../../../../xplat/web/features/ui/components/go-to-top/go-to-top.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "../../../../xplat/web/features/shared/components/footer/footer.component.ngfactory";
import * as i7 from "../../../../xplat/web/features/shared/components/footer/footer.component";
import * as i8 from "./app.component";
import * as i9 from "../../../../libs/core/services/jquery-util";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../../../../libs/features/assessment/api/assessment-instance.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-go-to-top", [], null, null, null, i2.View_GoToTopComponent_0, i2.RenderType_GoToTopComponent)), i1.ɵdid(1, 114688, null, 0, i3.GoToTopComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "parent-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "content-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "footer-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-footer", [], null, null, null, i6.View_FooterComponent_0, i6.RenderType_FooterComponent)), i1.ɵdid(8, 114688, null, 0, i7.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isScrolled; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); _ck(_v, 8, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i8.AppComponent, [i1.NgZone, i5.Router, i5.ActivatedRoute, i9.JQueryUtil, i10.Title, i11.AssessmentInstanceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
