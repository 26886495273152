/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-bar";
import * as i3 from "@angular/platform-browser/animations";
import * as i4 from "@angular/material/tooltip";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "@angular/cdk/scrolling";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "@angular/platform-browser";
import * as i11 from "./progressbar.component";
var styles_ProgressbarComponent = [];
var RenderType_ProgressbarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProgressbarComponent, data: {} });
export { RenderType_ProgressbarComponent as RenderType_ProgressbarComponent };
export function View_ProgressbarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 2, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2).toggle() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_MatProgressBar_0, i1.RenderType_MatProgressBar)), i0.ɵdid(1, 4374528, null, 0, i2.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i3.ANIMATION_MODULE_TYPE], [2, i2.MAT_PROGRESS_BAR_LOCATION]], { color: [0, "color"], value: [1, "value"] }, null), i0.ɵdid(2, 212992, [["tooltip", 4]], 0, i4.MatTooltip, [i5.Overlay, i0.ElementRef, i6.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i7.Platform, i8.AriaDescriber, i8.FocusMonitor, i4.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i4.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i10.HAMMER_LOADER]], { message: [0, "message"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.color; var currVal_4 = _co.progressValue; _ck(_v, 1, 0, currVal_3, currVal_4); var currVal_5 = _co.tooltipValue; _ck(_v, 2, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (((i0.ɵnov(_v, 1).mode === "indeterminate") || (i0.ɵnov(_v, 1).mode === "query")) ? null : i0.ɵnov(_v, 1).value); var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = i0.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ProgressbarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-progressbar", [], null, null, null, View_ProgressbarComponent_0, RenderType_ProgressbarComponent)), i0.ɵdid(1, 180224, null, 0, i11.ProgressbarComponent, [], null, null)], null, null); }
var ProgressbarComponentNgFactory = i0.ɵccf("app-progressbar", i11.ProgressbarComponent, View_ProgressbarComponent_Host_0, { progressValue: "progressValue", tooltipValue: "tooltipValue" }, {}, []);
export { ProgressbarComponentNgFactory as ProgressbarComponentNgFactory };
