<div class="header">
  <div [ngClass]="fullWidth ? 'container-fluid' : 'container'">
    <div class="row">
      <div class="col-12 header-title" *ngIf="simTitle; else logo">
        <a [href]="homeUrl"><i class="float-left svg-entrayn-previous"></i></a>
        <span
          class="title"
          *ngIf="simTitle.length > characterLimitForMobileDevices"
          [matTooltip]="simTitle"
          matTooltipClass="sim-title-tooltip"
        >
          {{ simTitleTransform }}
        </span>
        <span class="title" *ngIf="simTitle.length <= characterLimitForMobileDevices">
          {{ simTitleTransform }}
        </span>
      </div>
      <ng-template #logo class="col-md-6 col-sm-6 col-6">
        <div class="logo">
          <a [href]="homeUrl"><img src="/assets/static/images/logo.png"/></a>
        </div>
      </ng-template>
      <!-- TODO: use this html to find corresponding css rules -->
      <!-- <div class="col-md-6 col-sm-6 col-6">
          <div class="pull-right">
            <div class="profile-detail-wrapper">
              <div class="profile-detail">
                <div class="profile-pic"></div>
                //<div class="profile-name">Rehot Forht</div>
                <div class="profile-dropdown-menu"><i class="fa fa-caret-down"></i></div>
              </div>
            </div>
          </div>
        </div> -->
    </div>
  </div>
</div>
