import { HttpClient } from '@angular/common/http';
import { throwError as _throw } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Config } from '../config/config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../config/config";
export class UniversityListServiceService {
    constructor(_http, _config) {
        this._http = _http;
        this._config = _config;
    }
    getUniversitiesForStudent(studentId) {
        if (studentId || studentId !== '') {
            const url = `${this._config.apiEndpoints.userProfile}${studentId}` + '/university-scores';
            // const url = ENDPOINT_URL;
            return this._http.get(url).pipe(map(response => {
                _throw(new Error('Oops!! Error In Loading Universities'));
                return response;
            }), catchError((err) => {
                let error;
                if (err instanceof Error) {
                    error = err;
                }
                else {
                    error = new Error('No data found');
                }
                return error;
            }));
        }
    }
    fetchAllUniversities(url) {
        const apiUrl = url ? url : `${this._config.apiEndpoints.allUniversities}`;
        return this._http.get(apiUrl).pipe(map(data => {
            if (data['next']) {
                return data['results'].concat(this.fetchAllUniversities(data['next']));
            }
            return data['results'];
        }));
    }
}
UniversityListServiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UniversityListServiceService_Factory() { return new UniversityListServiceService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Config)); }, token: UniversityListServiceService, providedIn: "root" });
