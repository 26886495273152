import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../../../../libs/core/services/config';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../../../../libs/core/services/config";
export class GetDocService {
    constructor(_http, _constants) {
        this._http = _http;
        this._constants = _constants;
        this.googleScriptURL = this._constants.GOOGLE_SCRIPT_URL;
        this.getDocSubject = new Subject();
    }
    saveURL(urlInput) {
        this.docURL = urlInput;
    }
    getData() {
        this.fileID = new RegExp('/document/d/([a-zA-Z0-9-_]+)').exec(this.docURL)[1];
        const getURL = this.googleScriptURL + '?fileID=' + this.fileID;
        this._http.get(getURL).subscribe(data => this.getDocSubject.next(data));
    }
}
GetDocService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetDocService_Factory() { return new GetDocService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Constants)); }, token: GetDocService, providedIn: "root" });
