import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@entrayn/core';

@Component({
  selector: 'app-configurable-pop-up',
  templateUrl: 'configurable-pop-up.component.html',
  styleUrls: ['./configurable-pop-up.component.scss'],
})
export class ConfigurablePopUpComponent extends BaseComponent implements OnInit, OnDestroy {
  header: string;
  content: string;
  primaryText: string;
  secondaryText: string;
  headerClass: string;
  titleClass: string;

  constructor(
    public _dialogRef: MatDialogRef<ConfigurablePopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    this.header = data['header'];
    this.content = data['content'];
    this.headerClass = data['headerClass'];
    this.titleClass = data['titleClass'];
    this.primaryText = data['primaryText'];
    this.secondaryText = data['secondaryText'];
    _dialogRef.disableClose = data['disablePopupClose'];
  }

  ngOnInit() {}
  close() {
    this._dialogRef.close();
  }
  doAction(actionType) {
    this._dialogRef.close(actionType);
  }
}
