import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
interface CheckBoxItem {
  item: string;
  isChecked: boolean;
}

@Component({
  selector: 'app-checklist',
  templateUrl: 'checklist.component.html',
})
export class ChecklistComponent implements OnInit {
  isMaxCheckboxCountReached = false;
  checkedList: CheckBoxItem[] = [];
  checkBoxItems: CheckBoxItem[] = [];
  @Input() maxCount;
  @Input() minCount = 1;
  @Input() list: any = [];
  @Input() height = '100';
  @Output() checkedItems = new EventEmitter();
  constructor() {}

  ngOnInit() {
    this.maxCount = this.maxCount || this.list.length;
    this.list.forEach(item => {
      this.checkBoxItems.push({ item: item, isChecked: false });
    });
  }
  onCheckboxChange(checkBoxItems, checkBoxItem, $event) {
    checkBoxItem['isChecked'] = $event.checked;
    if (checkBoxItem.isChecked) {
      this.checkedList.push(checkBoxItem);
    } else {
      const index = this.checkedList.findIndex(
        checkedItem => checkedItem.item === checkBoxItem.item
      );
      if (index >= 0) {
        this.checkedList.splice(index, 1);
      }
    }
    this.checkedList = this.checkedList.filter(checkedItem => checkedItem.isChecked);
    // this.isMaxCheckboxCountReached = this.checkedList.length === this.maxCount;
    this.checkedItems.emit(this.checkedList);
  }
}
