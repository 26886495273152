import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as _throw } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Config } from '../config/config';

@Injectable({
  providedIn: 'root',
})
export class UniversityListServiceService {
  constructor(private _http: HttpClient, private _config: Config) {}

  getUniversitiesForStudent(studentId: string) {
    if (studentId || studentId !== '') {
      const url = `${this._config.apiEndpoints.userProfile}${studentId}` + '/university-scores';
      // const url = ENDPOINT_URL;
      return this._http.get<any[]>(url).pipe(
        map(response => {
          _throw(new Error('Oops!! Error In Loading Universities'));
          return response;
        }),
        catchError((err: HttpErrorResponse) => {
          let error;
          if (err instanceof Error) {
            error = err;
          } else {
            error = new Error('No data found');
          }
          return error;
        })
      );
    }
  }

  fetchAllUniversities(url?: string) {
    const apiUrl = url ? url : `${this._config.apiEndpoints.allUniversities}`;
    return this._http.get<any[]>(apiUrl).pipe(
      map(data => {
        if (data['next']) {
          return data['results'].concat(this.fetchAllUniversities(data['next']));
        }
        return data['results'];
      })
    );
  }
}
