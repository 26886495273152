<div class="table-data" *ngIf="rowData.length > 0">
  <ng-container *ngIf="pagination">
    <div class="pagination-wrapper">
      <div class="row m-0 pagination-box">
        <div class="col-md-5 col-12 pr-1 pl-1 mt-3">
          <nav aria-label="Page navigation" *ngIf="pageNumbers">
            <ul class="pagination">
              <li
                [ngClass]="{
                  'page-item': !noPrev,
                  'page-item-disabled': noPrev
                }"
              >
                <a class="page-link prev-link" aria-label="Previous" (click)="getPrevPage()">
                  <i class="svg-entrayn-previous"></i> Prev
                </a>
              </li>
              <li class="page-item" *ngFor="let number of paginationNumbers">
                <a
                  class="page-link"
                  [ngClass]="currentPage == number ? 'active-page' : ''"
                  (click)="goToPage(number)"
                  >{{ number }}</a
                >
              </li>
              <li
                [ngClass]="{
                  'page-item': !noNext,
                  'page-item-disabled': noNext
                }"
              >
                <a class="page-link next-link" aria-label="Next" (click)="getNextPage()">
                  Next <i class="svg-entrayn-next"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-3 offset-md-2 col-6 pr-1 pl-1 page-size mt-3" align="left">
          Show
          <input
            type="number"
            class="form-control"
            min="0"
            [max]="totalRowDisplayed"
            [(ngModel)]="paginationSize"
            (input)="onPaginationSizeChange()"
          />
          / {{ totalRowDisplayed }} Entries
        </div>
        <!-- Need to optimize the function for  column group -->
        <!-- <div class="col-md-2 col-6 pr-1 pl-1 mt-3" align="center">
        <mat-select
          [multiple]="true"
          placeholder="Columns"
          [compareWith]="compare"
          [(ngModel)]="selectedColumnDefs"
          (selectionChange)="onColumnSelect(selectedColumnDefs)"
        >
          <mat-select-trigger>Columns</mat-select-trigger>
          <mat-option disabled="disabled" class="deselect-option">
            <button class="btn btn-sm btn-block btn-red-button" (click)="toggleAllSelection()">
              {{ isDeSelectAllColumns ? 'Select All' : 'Deselect All' }}
            </button>
          </mat-option>
          <ng-container>
            <mat-option *ngFor="let column of sortColumnDefs" [value]="column"
              >{{ column.headerName }}
            </mat-option>
          </ng-container>
        </mat-select>
      </div> -->
        <div class="col-md-2 col-12 pr-1 pl-1 mt-3" align="center">
          <input
            class="form-control search-text"
            placeholder="Search"
            [(ngModel)]="searchText"
            (input)="quickFilter()"
          />
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showlastmodifiedTimeStamp || enableExport">
    <div class="col 12 pr-1 pl-1 mt-1" class="timestamp text-orange">
      <span class="float-left" *ngIf="showlastmodifiedTimeStamp && lastmodifiedTimeStamp">
        <i
          class="svg-entrayn-loader"
          (click)="refreshTable()"
          [ngClass]="isSpinLoading ? loadingSpinClass : ''"
        ></i>
        last updated on {{ lastmodifiedTimeStamp }}
      </span>
      <span class="float-right" *ngIf="enableExport">
        <button class="btn btn-outline p-0 m-0" (click)="exportAsCSV()">
          <i class="svg-entrayn-export"> </i>
          Export as CSV
        </button>
      </span>
    </div>
  </ng-container>
  <div
    *ngIf="selectedColumnDefs.length > 0"
    class="table-container"
    [ngStyle]="tableContainerStyle"
  >
    <ag-grid-angular
      *ngIf="!isSpinLoading"
      class="ag-theme-material ag-custom-theme"
      #agGrid
      id="data-grid"
      [style.height.vh]="tableHeight"
      [ngStyle]="gridStyle"
      [ngClass]="customClass"
      [enableSorting]="enableSorting"
      [postSort]="postSort"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [pagination]="pagination"
      [rowClassRules]="rowClassRules"
      [quickFilterText]="searchText"
      [animateRows]="animateRows"
      (gridReady)="onGridReady($event)"
      (modelUpdated)="modelUpdated($event)"
      (paginationChanged)="onPaginationChanged($event)"
      (rowClicked)="onRowClicked($event)"
      (rowDoubleClicked)="onRowDoubleClicked($event)"
      [frameworkComponents]="frameworkComponents"
      [context]="context"
      [defaultColDef]="defaultColDef"
      [suppressDragLeaveHidesColumns]="true"
      [suppressColumnVirtualisation]="true"
      [deltaRowDataMode]="true"
      [getRowNodeId]="getRowNodeId"
      [enableCellChangeFlash]="true"
    >
      <!---->
    </ag-grid-angular>
    <app-loading-spinner *ngIf="isSpinLoading"> </app-loading-spinner>
  </div>
  <div *ngIf="selectedColumnDefs.length === 0">
    <p align="center" class="text-secondary mt-5">Select atleast one column to view the content.</p>
  </div>
  <div *ngIf="!tableHeight">
    <p align="center" class="text-secondary mt-5">
      Please provide table-height for the data-table component
    </p>
  </div>
</div>
<div class="errorStatus" *ngIf="rowData.length == 0 && !isSpinLoading">
  <p align="center" class="text-secondary mt-5">{{ errorMessage }}</p>
</div>
