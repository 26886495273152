import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as uuid from 'uuid';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { RoleGuard } from '@entrayn/web/features/auth/guards/role.guard';
@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, AfterViewChecked {
  private _gridApi;
  private isDeSelectAllColumns = false;
  pageNumbers: number[] = null;
  paginationNumbers: number[] = null;
  selectedColumnDefs: object[] | string[] = [];
  sortColumnDefs: object[] = [];
  prevSelectedColumnDefs: object[] | string[] = null;
  searchText: string;
  totalPages = 1;
  loadingSpinClass = ['animate-spin', 'text-blue'];
  isSpinLoading = false;
  totalRowDisplayed = 0;
  tempPaginationSize = 0;
  currentPage = 1;
  currentPageFirstRow = 0;
  noPrev = true;
  noNext = false;
  isStoredColumnsAvailable = false;
  lastmodifiedTimeStamp: string;
  defaultColDef = {
    icons: {
      sortAscending: '<i class="svg-entrayn-sort-asc"></i>',
      sortDescending: '<i class="svg-entrayn-sort-desc"></i>',
      sortUnSort: '<i class="svg-entrayn-sort"></i>',
    },
    unsorticon: true,
    sortable: true,
    resizable: true,
    filter: true,
  };
  private _rowData;
  @Input() columnDefs = null;
  @Input() set rowData(data) {
    this._rowData = data;
    if (this._gridApi) {
      this._gridApi.redrawRows();
    }
  }
  get rowData() {
    return this._rowData;
  }
  @Input() enableSorting = false;
  @Input() refreshTimeStamp: Observable<any>;
  @Input() pagination = false;
  @Input() paginationSize = 50;
  @Input() animateRows = true;
  @Input() storeColumnKey = null;
  @Input() rowSelection = 'single';
  @Input() errorMessage = 'No data found.';
  @Input() enableExport = false;
  @Input() frameworkComponents;
  @Input() rowClassRules;
  @Input() context;
  @Input() customClass = [];
  @Input() postSort;
  @Input() showlastmodifiedTimeStamp = false;
  @Input() tableHeight = 100;
  @Input() setAutoHeight: boolean = false;
  @Output() gridReady = new EventEmitter();
  @Output() paginationChanged = new EventEmitter();
  @Output() rowSelected = new EventEmitter();
  @Output() rowDoubleClicked = new EventEmitter();
  @Output() onRefreshTable = new EventEmitter();
  tableContainerStyle = {};
  gridStyle = {};
  private refreshTimeStampSubscription: any;
  getRowNodeId = function(data) {
    // checks if row has id if not providing temp uuid for AG-grid api to work efficiently
    return data.id ? data.id : uuid.v4();
  };
  constructor(private _roleGuard: RoleGuard) {}

  ngOnInit() {
    // TODO: Need redux code change to manage state changes.
    // if (this.storeColumnKey) {
    //   this.selectedColumnDefs = this.acGlobalService.userDataTableCoumns[this.storeColumnKey];
    //   if (this.selectedColumnDefs && this.selectedColumnDefs.length > 0) {
    //     this.sortColumnDefs = this.columnDefs;
    //     this.isStoredColumnsAvailable = true;
    //   } else {
    //     this.sortColumnDefs = this.selectedColumnDefs = this.columnDefs;
    //   }
    // } else
    // {
    this.sortColumnDefs = this.selectedColumnDefs = this.columnDefs;
    this.tempPaginationSize = this.paginationSize;
    // if (this.setAutoHeight) {
    //   this.gridStyle = {
    //     width: '100%',
    //     height: `${this.tableHeight} vh`,
    //   };
    // } else {
    //   this.tableContainerStyle = {
    //     width: '100%',
    //     height: `${this.tableHeight} vh`,
    //   };
    // }
    if (this.refreshTimeStamp) {
      this.refreshTimeStampSubscription = this.refreshTimeStamp.subscribe(res => {
        if (res && this._gridApi) {
          this.changeLastUpdatedTimeStamp();
          this._gridApi.refreshCells();
        }
      });
    }
  }
  ngAfterViewChecked() {}
  onGridReady(params) {
    this.changeLastUpdatedTimeStamp();
    this._gridApi = params.api;
    this.gridReady.emit(params);
    if (this._gridApi) {
      this.onPaginationSizeChange();
      this.onColumnSelect(this.selectedColumnDefs);
      if (this.setAutoHeight) {
        this._gridApi.setDomLayout('autoHeight');
      }
    }
  }

  refreshTable() {
    if (this.showlastmodifiedTimeStamp) {
      this.onRefreshTable.emit(true);
      this.isSpinLoading = true;
      setTimeout(() => {
        this.isSpinLoading = false;
        this.changeLastUpdatedTimeStamp();
        this._gridApi.refreshCells();
      }, 4000);
    }
  }

  changeLastUpdatedTimeStamp() {
    const date = new Date();
    this.lastmodifiedTimeStamp = `${date.toDateString()}, ${date.toLocaleTimeString()}`;
  }
  onPaginationSizeChange() {
    this.totalRowDisplayed = this.rowData.length;
    if (this.paginationSize && this.totalRowDisplayed) {
      this.paginationSize = Math.floor(this.paginationSize);
      if (this.paginationSize < 0) {
        this.paginationSize = 0;
      } else if (this.paginationSize > this.totalRowDisplayed) {
        this.paginationSize = this.totalRowDisplayed;
      }
      this.totalPages = Math.ceil(this.totalRowDisplayed / this.paginationSize);
      this.pageNumbers = Array.from(new Array(this.totalPages), (val, index) => index + 1);
      this.currentPage = Math.floor(this.currentPageFirstRow / this.paginationSize) + 1;
      this._gridApi.paginationSetPageSize(this.paginationSize);
      this.goToPage(this.currentPage);
    }
  }
  onPaginationChanged(event) {
    if (event) {
      this.paginationChanged.emit(event);
    }
  }
  onRowClicked(event: any) {
    this.rowSelected.emit(event);
  }
  onRowDoubleClicked(event: any) {
    this.rowDoubleClicked.emit(event);
  }
  getPaginationNumber() {
    //  pageNumbers = array of all pageNumbers
    // currentPage = currentPage number;
    // paginationNumbers = final pagination number to be displayed

    if (this.pageNumbers.length > 2) {
      if (this.currentPage - 2 > 0) {
        if (this.currentPage + 1 > this.pageNumbers.length) {
          this.paginationNumbers = this.pageNumbers.slice(
            this.currentPage - 5,
            this.pageNumbers.length
          );
        } else if (this.currentPage + 2 > this.pageNumbers.length) {
          this.paginationNumbers = this.pageNumbers.slice(
            this.currentPage - 4,
            this.pageNumbers.length
          );
        } else {
          this.paginationNumbers = this.pageNumbers.slice(
            this.currentPage - 3,
            this.currentPage + 2
          );
        }
      } else if (this.currentPage - 1 > 0) {
        this.paginationNumbers = this.pageNumbers.slice(this.currentPage - 2, this.currentPage + 3);
      } else {
        this.paginationNumbers = this.pageNumbers.slice(this.currentPage - 1, this.currentPage + 4);
      }
    } else {
      this.paginationNumbers = this.pageNumbers;
    }
    this.enablePrevNext();
  }

  getNextPage() {
    if (this.currentPage < this.totalPages) {
      this.goToPage(this.currentPage + 1);
    }
  }
  getPrevPage() {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  }
  goToPage(value) {
    this._gridApi.paginationGoToPage(value - 1);
    this.currentPage = value;
    this.currentPageFirstRow = (this.currentPage - 1) * this.paginationSize;
    this.currentPageFirstRow = this.currentPageFirstRow + 1;
    this.getPaginationNumber();
  }
  // getPageData(event) {
  //   this.goToPage(event.pageNo);
  // }
  onColumnSelect(selectedColumnDefs: any[]) {
    this.columnDefs = [];
    // will be replaced with redux state management
    // if (this.storeColumnKey) {
    //   this.acGlobalService.userDataTableCoumns[this.storeColumnKey] = selectedColumnDefs;
    // }
    setTimeout(() => {
      this._gridApi.setColumnDefs(selectedColumnDefs);
    }, 1000);
  }
  quickFilter() {
    if (this.searchText !== ' ') {
    }
  }
  enablePrevNext() {
    if (this.pageNumbers) {
      if (this.pageNumbers.length === 1) {
        this.noNext = true;
        this.noPrev = true;
      } else {
        if (this.currentPage === this.pageNumbers[0]) {
          this.noPrev = true;
          this.noNext = false;
        } else if (this.currentPage === this.pageNumbers[this.pageNumbers.length - 1]) {
          this.noNext = true;
          this.noPrev = false;
        } else {
          this.noNext = this.noPrev = false;
        }
      }
    }
  }

  modelUpdated(event) {
    if (this._gridApi) {
      if (event.newData) {
        this.totalRowDisplayed = this._gridApi.getDisplayedRowCount();
        if (this.totalRowDisplayed <= this.paginationSize) {
          this.paginationSize = this.totalRowDisplayed;
        } else {
          this.paginationSize = this.tempPaginationSize;
        }
        this.onPaginationSizeChange();
      }
    }
  }

  compare(a, b) {
    return a.field === b.field;
  }

  toggleAllSelection() {
    this.isDeSelectAllColumns = !this.isDeSelectAllColumns;
    this.selectedColumnDefs = this.isDeSelectAllColumns ? [] : this.sortColumnDefs;
    // if (this.isDeSelectAllColumns) {
    //   // for storing previously selected values
    //   // this.prevSelectedColumnDefs = this.selectedColumnDefs;
    //   this.selectedColumnDefs = [];
    // } else {
    //   // retriving previously selected values if needed
    //   // this.selectedColumnDefs = this.prevSelectedColumnDefs;
    //   this.selectedColumnDefs = this.sortColumnDefs;
    // }
    this.onColumnSelect(this.selectedColumnDefs);
  }

  exportAsCSV() {
    if (this._gridApi) {
      const userInfo = this._roleGuard.getCurrentUserInfo(['first_name']);
      const studentListFileName = `AC-student-list_created_by_${userInfo['first_name']}_on${this.lastmodifiedTimeStamp}`;
      this._gridApi.exportDataAsCsv({
        fileName: studentListFileName,
      });
    }
  }
}
