import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ApiService } from './api.service';

@Injectable()
export class InternetConnectivityService {
  constructor(private _coreApi: ApiService) {}
  public retrySubject = new BehaviorSubject<boolean>(null);

  checkNetworkStatus() {
    // To display offline pop up when the resource file is not found
    this.retrySubject.next(false);
    // making a request to s3 file to check the availability of n/w
    return this._coreApi.isNetworkAvailable().subscribe(res => {
      // As soon as request succeeds we show online pop up
      // To move to resume when audio fails but not the network check
      this.retrySubject.next(true);
    });
  }
}
