import { HttpClient } from '@angular/common/http';
import { map, concatMap } from 'rxjs/operators';
import { BehaviorSubject, of as observableOf } from 'rxjs';
import { Config } from '../config/config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../config/config";
export class StudentList {
    constructor(_http, _config) {
        this._http = _http;
        this._config = _config;
        this.dataProgressChanged = new BehaviorSubject(null);
    }
    getStudentData(url) {
        if (this.studentList) {
            return observableOf(this.studentList);
        }
        else {
            return this._http.get(url ? url : this._config.apiEndpoints.users).pipe(concatMap(response => {
                if (response.next) {
                    this.dataProgressChanged.next(Math.floor((response.results.length / response.count) * 100));
                    return this.getStudentData(response.next).pipe(map((resultsToJoin) => {
                        this.studentList = [...response.results, ...resultsToJoin];
                        return this.studentList;
                    }));
                }
                else {
                    this.dataProgressChanged.next(Math.floor((response.results.length / response.count) * 100));
                    this.studentList = [response.results];
                    return this.studentList;
                }
            }));
        }
    }
    createUserOnBoardingInformation(payload) {
        const uuid = payload['uuid'];
        const onBoardingPayload = payload['on_boarding'];
        return this._http.post(this._config.apiEndpoints.onBoardingInformation, onBoardingPayload).pipe(map(res => {
            const index = this.studentList.findIndex(user => user['uuid'] === uuid);
            this.studentList[index]['on_boarding'] = res;
            return this.studentList[index];
        }));
    }
    createUserVisaInformation(payload) {
        const uuid = payload['uuid'];
        const visaPayload = payload['visa_information'];
        return this._http.post(this._config.apiEndpoints.visaInformation, visaPayload).pipe(map(res => {
            const index = this.studentList.findIndex(user => user['uuid'] === uuid);
            this.studentList[index]['visa_information'] = res;
            return this.studentList[index];
        }));
    }
    getStudentResponseFromList(uuid) {
        const selectedStudent = this.studentList.find(student => {
            return student.uuid === uuid;
        });
        return selectedStudent;
    }
    updateStudentList(userData) {
        if (this.studentList.length > 0) {
            const index = this.studentList.findIndex(user => user['uuid'] === userData['uuid']);
            this.studentList[index] = userData;
        }
    }
}
StudentList.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StudentList_Factory() { return new StudentList(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Config)); }, token: StudentList, providedIn: "root" });
