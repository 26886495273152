import * as Sentry from '@sentry/browser';
import * as i0 from "@angular/core";
export class AnalyticsService {
    constructor() {
        this.hasBeenIdentified = false;
        if (!this.isEnabled()) {
            this.checkAndIdentifyUser = function () { };
            this.resetUser = function () { };
        }
    }
    checkAndIdentifyUser(force = false) {
        if (force || !this.hasBeenIdentified) {
            return this.identifyUser.bind(this); // So that this function can be called directly from subscribe()
        }
    }
    isEnabled() {
        return window.location.hostname.indexOf('entrayn.com') !== -1;
    }
    // Destructures the user object
    identifyUser({ uuid, first_name, last_name, email }) {
        const name = first_name + (last_name ? ` ${last_name}` : '');
        // Fullstory
        FS.identify(uuid, {
            displayName: name,
            email,
        });
        // Sentry
        Sentry.configureScope(scope => {
            scope.setUser({
                id: uuid,
                email: email,
                username: name,
            });
        });
        this.hasBeenIdentified = true;
    }
    // on logout, to switch back to anonymous user
    resetUser() {
        FS.identify(false);
        // TODO: how to reset in sentry?
        this.hasBeenIdentified = false;
    }
}
AnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(); }, token: AnalyticsService, providedIn: "root" });
