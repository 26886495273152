import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { InternetConnectivityService } from '@entrayn/core/services';
import { Constants } from '@entrayn/core/services/config';
import { UIModule } from '@entrayn/web';
import * as Sentry from '@sentry/browser';
import { ClickOutsideModule } from 'ng-click-outside';
import { DragulaService } from 'ng2-dragula';
import { CookieService } from 'ngx-cookie-service';
import { RetryRequestInterceptor } from '@entrayn/core/interceptors/retry-request.interceptor';
import { AuthGuard } from '@entrayn/web/features/auth/guards/auth.guard';
import { AccessTokenInterceptor } from '@entrayn/web/features/auth/interceptors/access-token.interceptor';
import { RefreshTokenInterceptor } from '@entrayn/web/features/auth/interceptors/refresh-token.interceptor';
import { SharedModule } from '@entrayn/web/features/shared/shared.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
Sentry.init({
  dsn: Constants.SENTRY_DSN,
  environment: Constants.ENV,
  release: Constants.VERSION,
  enabled: !!Constants.SENTRY_DSN, // If no DSN, don't enable it (for local builds)
});
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    console.error(error);
    // throw error;
  }
}

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'internal',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: '@entrayn/web/features/auth/auth.module#AuthModule',
  },
  // Assessment Attempt routes
  {
    path: 'a',
    loadChildren:
      '@entrayn/web/features/assessment-attempt/assessment-attempt.module#AssessmentAttemptModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'sample-test',
    loadChildren:
      '@entrayn/web/features/assessment-attempt/assessment-attempt.module#AssessmentAttemptModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'summary',
    loadChildren:
      '@entrayn/web/features/assessment-review/assessment-review.module#AssessmentReviewModule',
    canActivate: [AuthGuard],
  },
  // AC servicing routes
  {
    path: 'ac-servicing',
    loadChildren: () => import('./ac-servicing/ac-servicing.module').then(m => m.AcServicingModule),
    canActivate: [AuthGuard],
  },
  // Notification route
  {
    path: 'notifications',
    loadChildren: '@entrayn/web/features/notifications/notifications.module#NotificationsModule',
    canActivate: [AuthGuard],
  },
  // Question Import and others
  {
    path: 'internal',
    loadChildren:
      '@entrayn/web/features/authoring-tools/authoring-tools.module#AuthoringToolsModule',
    canActivate: [AuthGuard],
  },
  // SIM's, LO's
  {
    path: 'learning',
    loadChildren: () =>
      import('@entrayn/web/features/learning/learning.module').then(m => m.LearningModule),
  },
  // No page route
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    UIModule,
    MatSnackBarModule,
    MatDialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, {
      // scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      // enableTracing: true,
    }),
    ClickOutsideModule,
    SharedModule,
  ],
  exports: [RouterModule],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    CookieService,
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    DragulaService,
    InternetConnectivityService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {}
