import { Pipe, PipeTransform } from '@angular/core';

/**
 *
 *
 * @export
 * @class SecondsToFormatTimePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'secondsToFormatTime',
})
export class SecondsToFormatTimePipe implements PipeTransform {
  /**
   *
   *
   * @param {*} value
   * @param {*} [args]
   * @returns {*}
   * @memberof SecondsToFormatTimePipe
   */
  transform(value: any, args?: any): any {
    if (value) {
      let _return = '';
      const h = Math.floor(value / 3600);
      const m = Math.floor((value % 3600) / 60);
      const s = Math.floor((value % 3600) % 60);
      if (h > 0) {
        _return += ('0' + h).slice(-2) + 'h ';
      }
      if (m > 0) {
        _return += ('0' + m).slice(-2) + 'm ';
      }
      if (s > 0) {
        _return += ('0' + s).slice(-2) + 's';
      } else if (!s) {
        _return += '00' + 's ';
      }
      return _return;
    } else {
      return '0s';
    }
  }
}
