import { PhoneNumberUtil } from 'google-libphonenumber';

import { duration } from '../services/ac-global.service';

export class StudentProfile {
  studentBasicInfo: any = null;
  studentTargetInfo: any = null;
  previousApplicationsInfo: any = null;
  testScoresInfo: any = null;
  academicInfo: any = null;
  publicationsInfo: any = null;
  researchInfo: any = null;
  workExperiencesInfo: any = null;
  otherStudentDetails: any = null;
  universityPreferences: any = null;

  constructor(studentProfile: object) {
    this.studentBasicInfo = new StudentBasicInfo(studentProfile);
    let linkedInProfile = null;
    const professionalDetailsObject = studentProfile['professional_details'];
    const admissionInfo = studentProfile['admission_info'];
    if (professionalDetailsObject) {
      const degreeDetails = professionalDetailsObject['degree_details'];
      const experienceObject = professionalDetailsObject['experiences'];
      linkedInProfile = studentProfile['professional_details']
        ? studentProfile['professional_details']['linkedin_profile']
        : null;
      this.academicInfo = degreeDetails ? new Academics(degreeDetails) : null;
      if (experienceObject) {
        const workObject = experienceObject['work'] || null;
        this.workExperiencesInfo = new WorkExperiences(workObject, professionalDetailsObject);
        this.publicationsInfo = new Publications(experienceObject);
        this.researchInfo = new Research(experienceObject);
      }
    }
    if (admissionInfo) {
      const previousApplication = admissionInfo['previous_applications_information'];
      this.studentTargetInfo = new StudentTarget(admissionInfo);
      this.universityPreferences = new UniversityPreferences(admissionInfo);
      this.otherStudentDetails = new OtherDetails(admissionInfo, linkedInProfile);
      this.previousApplicationsInfo = new PreviousApplications(previousApplication);
      const examsObject = studentProfile['exams'];
      const standardizedTest = String(admissionInfo['choice_of_standardized_test']).toLowerCase();
      const englishTest = String(
        admissionInfo['choice_of_english_language_proficiency_test']
      ).toLowerCase();
      this.testScoresInfo = examsObject
        ? new TestScores(examsObject, [standardizedTest, englishTest])
        : null;
    } else {
      this.otherStudentDetails = new OtherDetails(null, linkedInProfile);
    }

    // for loop to call compress function when each section details if it were null : may be needed later
    // const classKeys = Object.keys(this);
    // for (const key of classKeys) {
    //   this[key] = this.checkObject(this[key]);
    // }
  }

  // recursive function to return null if all the keys and subkeys were null or undefined or hyphenated
  private checkObject(object) {
    if (object != null) {
      const keys = Object.keys(object);
      const keyLength = keys.length;
      let count = 0;
      if (keyLength > 0) {
        for (const key of keys) {
          if (typeof object['key'] === 'object') {
            this.checkObject(object['key']);
          }
          if (!object[key] || object[key] === '-') {
            count++;
          }
        }
      }
      if (keyLength === count) {
        return null;
      }
      return object;
    }
    return object;
  }
}

class StudentBasicInfo {
  firstName: string;
  lastName: string;
  gender: string;
  DOB: string;
  email: string;
  phoneNumber: string;
  address: string;
  countryOrigin: string;
  isValidPhoneNumber: boolean;
  countryCode: string;
  countryFlagUrl: string;
  constructor(basicProfile: object) {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();

    const profile = basicProfile['profile'];
    this.firstName = basicProfile['first_name'] || '-';
    this.lastName = basicProfile['last_name'] || '-';
    this.gender = basicProfile['gender']
      ? basicProfile['gender'] === 'M' || basicProfile['gender'] === 'Male'
        ? 'Male'
        : basicProfile['gender'] === 'F' || basicProfile['gender'] === 'Female'
        ? 'Female'
        : 'Other'
      : '-';
    this.email = basicProfile['email'] || '-';
    this.DOB = profile['date_of_birth'] || '-';
    this.address = profile['address'] || '-';
    this.countryOrigin = profile['country_of_origin'] || '-';
    this.phoneNumber = profile['phone'] || '-';
    try {
      const phoneNumber = phoneNumberUtil.parse(this.phoneNumber, '');
      this.isValidPhoneNumber = phoneNumberUtil.isPossibleNumber(phoneNumber);
      if (this.isValidPhoneNumber)
        this.countryCode = phoneNumberUtil.getRegionCodeForNumber(phoneNumber);
      this.countryFlagUrl =
        'https://countryflags.io/' + this.countryCode.toLowerCase() + '/shiny/32.png';
      // country flag url will give flag image for the respective code.
      // for ref "https://www.countryflags.io/"
      this.phoneNumber = phoneNumberUtil.formatOutOfCountryCallingNumber(phoneNumber);
    } catch (e) {
      console.log('NumberParseException was thrown: ' + e.toString());
      // will log the error if phoneNumber is not able to parse by the util
      this.isValidPhoneNumber = false;
    }
  }
}

class StudentTarget {
  semester: string;
  degree: string;
  targetCountry1 = '-';
  targetCountry2 = '-';
  department: string;
  areaOfInterest: string;
  reasonForAOI: string;
  switchingMajor: any;
  relatedActivities: string;
  constructor(admissionInfo: object) {
    this.semester = admissionInfo['target_season']
      ? admissionInfo['target_season'] + ' ' + admissionInfo['target_year']
      : '-';
    this.degree = admissionInfo['target_degree'] || '-';
    this.department = admissionInfo['target_department'] || '-';
    this.areaOfInterest = admissionInfo['area_of_interest'] || '-';
    const targetCountries = admissionInfo['preferred_countries'];
    if (targetCountries && targetCountries.length !== 0) {
      this.targetCountry1 = targetCountries[0] || '-';
      this.targetCountry2 = targetCountries[1] || '-';
    }
    this.switchingMajor =
      admissionInfo['has_switched_majors'] != null
        ? admissionInfo['has_switched_majors']
          ? 'Yes'
          : 'No'
        : '-';
    this.relatedActivities = admissionInfo['related_activities_for_new_field'] || '-';
    const additionalInfo = admissionInfo['additional_info'];
    this.reasonForAOI = additionalInfo
      ? additionalInfo['reasons_for_interest_in_major'] || '-'
      : '-';
  }
}

class PreviousApplications {
  country1 = '-';
  country2 = '-';
  semester = '-';
  department = '-';
  areaOfInterest = '-';
  additionAreaOfInterest = '-';
  admittedDetails = '-';
  rejectedDetails = '-';
  isDeferredPrevAdmit = '-';
  isApplyingPrevUniv = '-';
  profileChangeDetails = '-';
  constructor(prevAppObject: object) {
    if (prevAppObject) {
      this.isApplyingPrevUniv = prevAppObject['do_you_want_to_apply_to_same_universities'] || '-';
      this.semester = prevAppObject['enrollment_season'] || '-';
      this.department = prevAppObject['applied_department'] || '-';
      this.areaOfInterest = prevAppObject['area_of_interest'] || '-';
      this.country1 = prevAppObject['country'];
      // this.country2 = prevAppObject['country_two'];
      // data was not available in the backend
      this.admittedDetails = prevAppObject['admits'] || '-';
      this.rejectedDetails = prevAppObject['rejects'] || '-';
      this.isDeferredPrevAdmit = prevAppObject['deferred_admits'] || '-';
      this.profileChangeDetails = prevAppObject['did_profile_change'] || '-';
    }
  }
}

class Exam {
  exam: string;
  date: string;
  status: string;
  type: string;
  scores: object = {};
}

class TestScores {
  examTest: any = null;
  private _examStatusChoices = {
    TNT: 'Tentative',
    BKD: 'Booked',
    '-': 'Tentative',
  };

  private _defaultZero(score) {
    return score === '-' ? 0 : score;
  }

  constructor(exam: object, examTypes: string[]) {
    const exams: Exam[] = [];
    const generalExams = ['gre', 'gmat'];
    const englishExams = ['toefl', 'ielts', 'pte'];
    const keys = Object.keys(exam);
    for (const examType of examTypes) {
      const specificKey = keys.filter(key => {
        return key.includes(examType);
      });
      if (specificKey.length > 0) {
        const examObject = new Exam();
        for (const key of specificKey) {
          if (key.includes('exam_date')) {
            examObject.date = exam[key] || '-';
          } else if (key.includes('exam_booking_status')) {
            examObject.status = this._examStatusChoices[exam[key] || '-'];
          }
        }
        if (examObject.date !== '-') {
          const examDate = new Date(examObject.date);
          const now = new Date();
          now.setHours(0, 0, 0, 0);
          if (examDate < now) {
            examObject.status = 'Completed';
          }
        }
        examObject.exam = examType;
        examObject.type = generalExams.includes(examType) ? 'general' : 'english';
        // TODO <skandharuban@entrayn.com> Move strings into common config.
        if (examObject.type === 'general') {
          examObject.scores['quant'] =
            exam[
              specificKey.find(key => {
                return key.includes(examType) && key.includes('quant');
              })
            ] || '-';
          examObject.scores['verbal'] =
            exam[
              specificKey.find(key => {
                return key.includes(examType) && key.includes('verbal');
              })
            ] || '-';
          if (examObject.exam === 'gre') {
            examObject.scores['awa'] =
              exam[
                specificKey.find(key => {
                  return key.includes(examType) && key.includes('awa');
                })
              ] || '-';
          } else if (examObject.exam === 'gmat') {
            examObject.scores['ar'] =
              exam[
                specificKey.find(key => {
                  return key.includes(examType) && key.includes('awa');
                })
              ] || '-';
            examObject.scores['ir'] =
              exam[
                specificKey.find(key => {
                  return key.includes(examType) && key.includes('ir');
                })
              ] || '-';
          }
        } else if (examObject.type === 'english') {
          const totalScore = 0;
          if (examObject.exam === 'toefl' || examObject.exam === 'ielts') {
            examObject.scores['reading'] =
              exam[
                specificKey.find(key => {
                  return key.includes(examType) && key.includes('reading');
                })
              ] || '-';
            examObject.scores['listening'] =
              exam[
                specificKey.find(key => {
                  return key.includes(examType) && key.includes('listening');
                })
              ] || '-';
            examObject.scores['speaking'] =
              exam[
                specificKey.find(key => {
                  return key.includes(examType) && key.includes('speaking');
                })
              ] || '-';
            examObject.scores['writing'] =
              exam[
                specificKey.find(key => {
                  return key.includes(examType) && key.includes('writing');
                })
              ] || '-';
          }
        }

        if (examObject.exam === 'pte' || examObject.exam === 'gmat') {
          examObject.scores['total'] =
            exam[
              specificKey.find(key => {
                return key.includes(examType) && key.includes('total_score');
              })
            ] || '-';
        } else {
          if (examObject.exam === 'toefl' || examObject.exam === 'ielts') {
            examObject.scores['total'] =
              this._defaultZero(examObject.scores['reading']) +
              this._defaultZero(examObject.scores['writing']) +
              this._defaultZero(examObject.scores['listening']) +
              this._defaultZero(examObject.scores['speaking']);
            if (examObject.exam === 'ielts') {
              examObject.scores['total'] = Math.round((examObject.scores['total'] / 4) * 2) / 2;
            }
          } else if (examObject.exam === 'gre') {
            examObject.scores['total'] =
              this._defaultZero(examObject.scores['quant']) +
              this._defaultZero(examObject.scores['verbal']);
          }
        }
        exams.push(examObject);
      }
    }

    if (exams.length > 0) {
      this.examTest = exams;
    }
  }
}

class Academics {
  highestDegree: any = {};
  additionalDegree: any = {};
  underGraduate: any = {};
  constructor(academicDetails: object) {
    const highestDegreeObject = academicDetails['highest_degree_details'];
    const addDegreeObject = academicDetails['additional_degree_details'];
    const underGraduateObject = academicDetails['ug_degree_details'];
    if (highestDegreeObject) {
      this.highestDegree['degree'] = highestDegreeObject['highest_degree'] || '-';
      this.highestDegree['year'] = highestDegreeObject['year_of_graduation'] || '-';
      this.highestDegree['branch'] = highestDegreeObject['branch'] || '-';
      this.highestDegree['CGPA'] = highestDegreeObject['cgpa_numerator'] || '-';
      this.highestDegree['CGPA_Max'] = highestDegreeObject['cgpa_denominator'] || '-';
      this.highestDegree['instituteName'] = highestDegreeObject['institute_name'] || '-';
      this.highestDegree['University'] = highestDegreeObject['affiliated_university'] || '-';
      this.highestDegree['reason'] = highestDegreeObject['reason_for_picking_major'] || '-';
      this.highestDegree['likedSubjects'] = highestDegreeObject['subjects_you_liked'] || '-';
      // (TODO REMOVE - WHEN THIS FIELD IS INTRODUCED)
      this.highestDegree['isHavingBacklogs'] =
        highestDegreeObject['is_having_backlogs'] === true
          ? 'Yes, I do have backlogs'
          : "No, I don't have backlogs";
      this.highestDegree['backlogDetails'] = highestDegreeObject['details_of_backlogs'] || '-';
      this.highestDegree['isHadBreak'] = highestDegreeObject['any_gap_in_education'] || '-';
      this.highestDegree['breakDetails'] = highestDegreeObject['gap_reason'] || '-';
    } else {
      this.highestDegree = highestDegreeObject;
    }
    if (addDegreeObject) {
      this.additionalDegree['degree'] = addDegreeObject['any_additional_university_degrees'] || '-';
      this.additionalDegree['branch'] = addDegreeObject['other_branch'] || '-';
    } else {
      this.additionalDegree = addDegreeObject;
    }
    if (underGraduateObject && Object.keys(underGraduateObject).length > 0) {
      this.underGraduate['degree'] = underGraduateObject['ug_degree'] || '-';
      this.underGraduate['branch'] = underGraduateObject['ug_major'] || '-';
      this.underGraduate['year'] = underGraduateObject['year_of_graduation'] || '-';
      this.underGraduate['instituteName'] = underGraduateObject['institute_name'] || '-';
      this.underGraduate['University'] = underGraduateObject['affiliated_university'] || '-';
      this.underGraduate['CGPA'] = underGraduateObject['CGPA'] || '-';
      this.underGraduate['CGPA_Max'] = underGraduateObject['CGPA_Max'] || '-';
    } else {
      this.underGraduate = this.highestDegree;
    }
  }
}

class Publications {
  nationalPublication: any;
  internationalPublication: any;
  nationalConferences: any;
  internationalConferences: any;
  constructor(publication: object) {
    // professional_details.experience
    const publicationDefaultValue = { count: 0, summary: '-' };
    const conferenceDefaultValue = { count: 0, details: '-', summary: '-' };
    this.nationalPublication = publication['national_publications']
      ? Object.keys(publication['national_publications']).length > 0
        ? {
            count: publication['national_publications']['national_publications_count'] || 0,
            summary: publication['national_publications']['national_publications_summary'] || '-',
          }
        : publicationDefaultValue
      : publicationDefaultValue;
    this.internationalPublication = publication['international_publications']
      ? Object.keys(publication['international_publications']).length > 0
        ? {
            count:
              publication['international_publications']['international_publications_count'] || 0,
            summary:
              publication['international_publications']['international_publications_summary'] ||
              '-',
          }
        : publicationDefaultValue
      : publicationDefaultValue;
    this.nationalConferences = publication['national_conferences']
      ? Object.keys(publication['national_conferences']).length > 0
        ? {
            count:
              publication['national_conferences']['number_of_national_articles_or_conferences'] ||
              0,
            details: publication['national_conferences']['national_conferences_details'] || '-',
            summary: publication['national_conferences']['national_conferences_summary'] || '-',
          }
        : conferenceDefaultValue
      : conferenceDefaultValue;
    this.internationalConferences = publication['international_conferences']
      ? Object.keys(publication['international_conferences']).length > 0
        ? {
            count:
              publication['international_conferences'][
                'number_of_international_articles_or_conferences'
              ] || 0,
            details:
              publication['international_conferences']['international_conferences_details'] || '-',
            summary:
              publication['international_conferences']['international_conferences_summary'] || '-',
          }
        : conferenceDefaultValue
      : conferenceDefaultValue;
  }
}

class Research {
  academicInternship: any = {};
  industrialInternship: any = {};
  onGoingProjects: any = {};
  hackathons: any = {};

  constructor(research: object) {
    const defaultValue = { count_duration: '0', details: '-' };
    const academicInternshipObject = research['academic_internships'];
    const industrialInternshipObject = research['industrial_internships'];
    const ongoingProjectsObjects = research['ongoing_projects'];
    const hackathonObjects = research['hackathons_and_contests'];
    this.academicInternship = academicInternshipObject
      ? {
          count_duration: duration(academicInternshipObject['academic_internships_duration'] || 0),
          details: academicInternshipObject['academic_internships_description'] || '-',
        }
      : defaultValue;

    this.industrialInternship = industrialInternshipObject
      ? {
          count_duration: duration(
            industrialInternshipObject['industrial_internships_duration'] || 0
          ),
          details: industrialInternshipObject['industrial_internships_description'] || '-',
        }
      : defaultValue;
    this.onGoingProjects = ongoingProjectsObjects
      ? {
          count_duration: ongoingProjectsObjects['ongoing_projects'] || 0,
          details: ongoingProjectsObjects['ongoing_projects_description'] || '-',
        }
      : defaultValue;

    this.hackathons = hackathonObjects
      ? {
          count_duration: hackathonObjects['number_of_hackathons_or_contests_participated'] || 0,
          details: hackathonObjects['hackathons_or_contests_description'] || '-',
        }
      : defaultValue;
  }
}

class WorkExperiences {
  yearsOfExperience = '-';
  workExperienceSummary = '-';
  isCurrentlyWorking = '-';
  currentNameOfOrganization = '-';
  currentDesignation = '-';
  currentJobDescription = '-';
  briefSummaryofWorkExperience = '-';
  constructor(work: object, professionalDetailsObject: object) {
    this.yearsOfExperience =
      this._getYearAndMonth(professionalDetailsObject['years_of_experience']) || '-';
    this.isCurrentlyWorking = professionalDetailsObject['is_working_professional'] ? 'Yes' : 'No';
    if (work && Object.keys(work).length > 0) {
      this.workExperienceSummary = work['work_experience_summary'] || '-';
      this.currentNameOfOrganization = work['current_name_of_organization'] || '-';
      this.currentDesignation = work['current_designation'];
      this.currentJobDescription = work['current_job_description'] || '-';
      this.briefSummaryofWorkExperience = work['brief_summary_of_work_experience'] || '-';
    }
  }
  private _getYearAndMonth(monthCount) {
    const month = monthCount % 12;
    const year = Math.floor(monthCount / 12);
    const result = [];
    if (year) {
      result.push(year + ' ' + this._calculateSingularOrPlural(year, 'year'));
    }
    if (month) {
      result.push(month + ' ' + this._calculateSingularOrPlural(month, 'month'));
    }
    return result.join(', ');
  }
  private _calculateSingularOrPlural(number, word: string) {
    return number === 1 ? word : word + 's';
  }
}

class OtherDetails {
  linkedInProfile: string;
  shortTermGoals = '-';
  LongTermGoals = '-';
  budget = '-';
  anyOtherAspect = '-';
  sourceofFunds: string;
  criteria: string[];
  constructor(admissionInfo: object, linkedInprofile: string) {
    const additionalInfo = admissionInfo ? admissionInfo['additional_info'] : null;
    if (additionalInfo) {
      this.shortTermGoals = additionalInfo['short_term_goals'] || '-';
      this.LongTermGoals = additionalInfo['long_term_goals'] || '-';
      this.budget = additionalInfo['annual_budget']
        ? 'USD ' + additionalInfo['annual_budget']
        : '-';
      this.anyOtherAspect = additionalInfo['any_other_specific_aspect'] || '-';
    }
    this.linkedInProfile = linkedInprofile || '-';
    this.sourceofFunds = admissionInfo ? admissionInfo['source_of_funds'] || '-' : '-';
    this.criteria = admissionInfo
      ? admissionInfo['parameters']
        ? admissionInfo['parameters'].map(Number)
        : []
      : null;
  }
}

class UniversityPreferences {
  preferredUniversities: any;
  constructor(admissionInfo: object) {
    this.preferredUniversities = admissionInfo['preferred_universities'] || '-';
  }
}
