import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { concatAll, filter, map } from 'rxjs/operators';

import { Constants } from '../../../../libs/core/services/config';
import { ApiService } from '../../../../libs/core/services/api.service';

/**
 *
 *
 * @export
 * @class AssessmentInstanceService
 */
@Injectable({
  providedIn: 'root',
})
export class AssessmentInstanceService {
  /**
   *Creates an instance of AssessmentInstanceService.
   * @param {HttpClient} _httpClient
   * @memberof AssessmentInstanceService
   */
  constructor(
    private _httpClient: HttpClient,
    private _constants: Constants,
    private _router: Router,
    private _api: ApiService
  ) {}
  public retrySubject = new BehaviorSubject<boolean>(null);
  public disableExitOption = new BehaviorSubject<boolean>(null);
  public requestQueue$ = new Subject();
  private requestQueueSubscription: Subscription;

  /**
   *
   *
   * @param {*} assessment_template_uuid
   * @returns {Observable<any>}
   * @memberof AssessmentInstanceService
   */

  requestQueueSubscribe() {
    this.requestQueueSubscription = this.requestQueue$
      .pipe(
        filter(obs => obs != null),
        concatAll()
      )
      .subscribe(res => {
        this._api.requestsData.shift();
      });
  }

  requestQueueUnsubscribe() {
    if (this.requestQueueSubscription) this.requestQueueSubscription.unsubscribe();
  }

  enqueueRequest(request) {
    this.requestQueue$.next(request);
  }

  getAssessmentInstanceList(assessment_template_uuid: any): Observable<any> {
    /* Get list of all assessment-instances */
    return this._httpClient.get(
      `${this._constants.SITE_URL}${this._constants.ASSESSMENT_INSTANCE_URL}?assessment_template_id=${assessment_template_uuid}`
    );
  }

  formatAssets(assessment) {
    // TODO(praveena): remove this when refactored BE code is ready
    Object.keys(assessment['assets']['questions']).forEach(questionUuid => {
      const value = assessment['assets']['questions'][questionUuid];
      const questionPayload = value[Object.keys(value)[0]];
      assessment['assets']['questions'][questionUuid] = questionPayload;
    });
    Object.keys(assessment['assets']['resources']).forEach(resourceUuid => {
      const value = assessment['assets']['resources'][resourceUuid];
      const resourcePayload = value[Object.keys(value)[0]];
      assessment['assets']['resources'][resourceUuid] = resourcePayload;
    });
    return assessment;
  }
  retrieveAssessmentInstance(assessmentInstanceUuid) {
    return this._httpClient
      .get(
        `${this._constants.SITE_URL}${this._constants.ASSESSMENT_INSTANCE_URL}/${assessmentInstanceUuid}`
      )
      .pipe(
        map(assessment => {
          // TODO(praveena): remove this
          return this.formatAssets(assessment);
        })
      );
  }
  getCurrentAssessmentInstance(instanceData, assessment_template_uuid) {
    const payload = {
      assessment_template_id: assessment_template_uuid,
    };
    /* Do a post to get assessment instance if it does not exist */
    if (instanceData.length == 0) {
      return this._httpClient
        .post(`${this._constants.SITE_URL}${this._constants.ASSESSMENT_INSTANCE_URL}`, payload)
        .pipe(
          map(assessment => {
            // TODO(praveena): remove this
            return this.formatAssets(assessment);
          })
        );
    } else {
      /* The return response will have just one instance each time*/
      const instance = instanceData[0];
      let assessmentInstanceObservable;
      if (!instance['completed']) {
        /* Do a get request to get that assessment instance, which is not yet completed.*/
        assessmentInstanceObservable = this.retrieveAssessmentInstance(instance['uuid']);
      } else {
        assessmentInstanceObservable = observableOf(this._constants.ASSESSMENT_COMPLETED);
        this._router.navigate([`/summary/${instance['uuid']}`], { replaceUrl: true });
      }
      return assessmentInstanceObservable;
    }
  }
  /**
   *
   *
   * @param {*} formData
   * @returns {Observable<any>}
   * @memberof AssessmentInstanceService
   */
  postAssessmentInteraction(
    formData: any,
    assessment_instance_uuid: string = null
  ): Observable<any> {
    // delete formData.user_question_interaction.marked_for_review;
    // console.log(formData);
    // console.log(assessment_instance_uuid);
    return this._httpClient.post(
      this._constants.SITE_URL +
        `${this._constants.ASSESSMENT_INSTANCE_URL}/${assessment_instance_uuid}/${this._constants.ASSESSMENT_INTERACTIONS}`,
      formData
    );
  }
}
