import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of as observableOf } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Config } from '../config/config';
import { StudentProfileEditConfig } from '../config/student-profile-config';
import { StudentProfile } from '../models/student-profile';
import { StudentList } from './students-list.service';

@Injectable({
  providedIn: 'root',
})
export class ViewEditService {
  private currentUserData: object;
  public selectedStudent = new BehaviorSubject(null);
  constructor(
    private _studentProfileService: StudentList,
    private _studentEditConfig: StudentProfileEditConfig,
    private _config: Config,
    private _http: HttpClient
  ) {}

  public getStudentDetail(id?: string): Observable<any> {
    if (id) {
      // this.currentUserData = this._studentProfileService.getStudentResponseFromList(id);
      // Since loading user details in new tab, data from the list is always undefined. May be used for future update.
      return forkJoin(this._getStudentData(id), this.getConfig()).pipe(
        map(res => {
          return this._getProfile(res[0]);
        })
      );
    } else {
      return observableOf(this.currentUserData).pipe(
        map(res => {
          return this._getProfile(res);
        })
      );
    }
  }

  private _getStudentData(id: string) {
    const url = this._config.apiEndpoints.userProfile + id;
    return this._http.get<any[]>(url);
  }

  public updateStudentProfile(value) {
    const url = this._config.apiEndpoints.userProfile + value['uuid'];
    return this._http.patch(url, value);
  }

  saveStudentProfile(value) {
    value['uuid'] = this.currentUserData['uuid'];
    return this.updateStudentProfile(value).pipe(
      map(res => {
        return this._getProfile(res);
      })
    );
  }

  private _getProfile(userData: any) {
    this.currentUserData = userData;
    this.selectedStudent.next(userData);
    const isProfileFormFilled =
      userData['admission_info'] && userData['admission_info']['is_admission_info_form_filled'];
    if (isProfileFormFilled) this._studentEditConfig.createProfileEditConfig(userData);
    return {
      profileData: isProfileFormFilled ? new StudentProfile(userData) : null,
      userData: userData,
    };
  }

  checkUserEnrollmentStatus(enrollment_status: any) {
    return this._config.enrollmentStatusCriteria.includes(enrollment_status);
  }

  getConfig() {
    const url = this._config.apiEndpoints.editOptions;
    return this._http.get(url).pipe(
      tap(res => {
        const results = res['results'];
        const config = results[results.length - 1]['config'];
        this._config.dropDownOptions = Object.assign(this._config.dropDownOptions, config);
        this._config.servicingUniversityCombinations = this.constructUniversitiesCombination(
          config.recommended_pattern_3,
          config.recommended_pattern_6
        );
        this._config.allUniversityCombinations = this.constructUniversitiesCombination(
          config.pattern_3,
          config.pattern_6
        );
      }),
      catchError(err => {
        return observableOf(true);
        // returning true if error occured to use default config from config file
      })
    );
  }

  constructUniversitiesCombination(pattern_3, pattern_6) {
    const classification = { A: 'Ambitious', P: 'Practical', S: 'Safe' };
    const servicingUniversityCombinations = {
      3: this.splitAndSetFullNameForCombinations(pattern_3, classification),
      6: this.splitAndSetFullNameForCombinations(pattern_6, classification),
    };
    return servicingUniversityCombinations;
  }
  splitAndSetFullNameForCombinations(pattern, classification) {
    const combinations = [];
    pattern.forEach(element => {
      const singleCombination: any = [];
      element.split('').forEach(elem => {
        singleCombination.push(classification[elem]);
      });
      combinations.push(singleCombination);
    });
    return combinations;
  }
  // might needed later

  // public getWithErrorHandlingThird() {
  //   return this.http.get<any[]>(ENDPOINT_URL).pipe(
  //     retryWhen(errors =>
  //       errors.pipe(
  //         delay(3000),
  //         catchError((err: HttpErrorResponse) => {
  //           let error;

  //           // Check Client Side Error
  //           if (err instanceof Error) {
  //             error = err;
  //           } else {
  //             error = new Error(`Oops!! Error Loading Users`);
  //           }

  //           //Handling Error Locally
  //           return _throw(error);
  //         })
  //       )
  //     ),
  //     map(response => {
  //       return response;
  //     })
  //   );
  // }
}
